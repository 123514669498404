var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<header class=\"header\">\n	<a href=\"#\" class=\"btn btn-x parseForm pull-right\">SUBMIT</a>\n</header>\n<div class=\"wrapper\">\n	<div class=\"wrapper bg-white-only quoteForm\">\n	<!--<form id=\"quoteCreateForm\">-->\n		<!--<div class=\"form-group\">-->\n			<!--<select name=\"client_id\" class=\"form-control client\" id=\"client\"></select>-->\n			<!--<label>Client</label>-->\n		<!--</div>-->\n		<!--<div class=\"form-group\">-->\n			<!--<input class=\"form-control created_at\" type=\"date\" name=\"created_at\" value=\"";
  if (helper = helpers.created_at) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.created_at); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" />-->\n			<!--<label>Quote Date</label>-->\n		<!--</div>-->\n\n	<!--</form>-->\n	</div>\n</div>";
  return buffer;
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}