var View = require('views/base/view');

module.exports = View.extend({
  container: 'body',
  id: 'app',
  className: 'app',
  regions: {
    asideLeft: '#aside',
    header: '#app-bar',
    content: '#app-content'
  },
  template: require('./templates/site')
});
