var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<table class=\"table items items-table\" id=\"items-table\">\n		<thead>\n		<tr>\n			<th class=\"x-grid-cell x-field-drag\"></th>\n			<th class=\"x-grid-cell x-field-index\"><span>#</span></th>\n			<th class=\"x-grid-cell x-field-name\">\n				<span>Product</span>\n			</th>\n			<!--<th class=\"x-grid-cell x-field-desc br-l-n\">-->\n				<!--<span></span>-->\n			<!--</th>-->\n			<th class=\"x-grid-cell x-field-size\" colspan=\"2\">\n				<span>Size (in.)</span>\n			</th>\n			<!--<th class=\"x-grid-cell x-field-sqft\">-->\n				<!--<span>ft<sup>2</sup></span>-->\n			<!--</th>-->\n			<th class=\"x-grid-cell x-field-rate amount num\">\n				<span>List Price</span>\n			</th>\n			<th class=\"x-grid-cell x-field-labor amount num\">\n				<span>Prod/Inst</span>\n			</th>\n			<th class=\"x-grid-cell x-field-total amount num\">\n				<span>Total</span>\n			</th>\n			<th class=\"x-grid-cell x-field-action\"></th>\n		</tr>\n		</thead>\n		<tbody>\n\n		</tbody>\n		<!--<tfoot>-->\n			<!--<tr>-->\n				<!--<th colspan=\"3\">Product</th>-->\n				<!--<th colspan=\"2\">Size (W x H)</th>-->\n				<!--<th>ft<sup>2</sup></th>-->\n				<!--<th>Qty</th>-->\n				<!--<th>Price</th>-->\n				<!--<th>Labor</th>-->\n				<!--<th style=\"text-align: right; padding-right: 25px;\">Total</th>-->\n				<!--<th></th>-->\n			<!--</tr>-->\n		<!--</tfoot>-->\n</table>\n\n";
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}