/**
 * Created by soundview on 5/11/15.
 */


var Model = require('./model');

var AttachmentCollection = Chaplin.Collection.extend({
	// Mixin a synchronization state machine.
	// initialize: function() {
	//   _.extend(this, Chaplin.SyncMachine);
	//   Chaplin.Model.prototype.apply(this, arguments);
	//   this.on('request', this.beginSync);
	//   this.on('sync', this.finishSync);
	//   this.on('error', this.unsync);
	// }
	url: '/index.php/api/v1/attachments',
	model: Model,
	initialize: function(){
		this.on('change:active', this.onActiveChange, this);
		this.on('reset', this.setRowCount, this);
		this.setRowCount();
		this.rowCount = this.length;
	},
	setRowCount: function() {
		this.rowCount = this.length;
	},
	onActiveChange: function (item) {
		// Only 1 item allowed to be active
		if (item.get('active')) {
			var activeItems = this.where({active: true});
			_.each(activeItems, function (activeItem) {
				if (activeItem.get('id') !== item.get('id')) {
					activeItem.set('active', false);
				}
			});
			this.trigger('activeChange', item);
		}
	},
	byGroup: function (group) {
		var filtered = this.filter(function (attachment) {
			return attachment.get("attachment_group") === group;
		});
		this.trigger('filter', this);
		console.log(filtered);
		return new AttachmentCollection(filtered);
	}
});

module.exports = AttachmentCollection;