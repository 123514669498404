var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

function program1(depth0,data) {
  
  
  return "\n		<span>\n			<a class=\"md-btn bg-primary md-btn-icon btn-save-item\"><i class=\"md md-check\"></i></a>\n		</span>\n\n	";
  }

function program3(depth0,data) {
  
  
  return "\n\n			<a class=\"md-btn bg-danger md-btn-icon btn-delete\" href=\"#\" title=\"Delete\">\n				<i class=\"mdi mdi-action-delete i-20\"></i>\n			</a>\n			<a class=\"md-btn blue-500 md-btn-icon btn-duplicate\" title=\"duplicate\">\n				<i class=\"mdi-navigation-refresh i-20\"></i>\n			</a>\n\n\n	";
  }

  buffer += "<td class=\"x-grid-cell x-field-drag\">\n	<span>\n\n	</span>\n</td>\n<td class=\"x-grid-cell x-field-index\">\n	<span class=\"num\">";
  if (helper = helpers.rowIndex) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.rowIndex); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n</td>\n<td class=\"x-grid-cell x-field-name two-lines\">\n	<span class=\"item-edit\">\n		<div class=\"form-group pt10 m-n\">\n			<select name=\"name\" id=\"name\" class=\"form-control md-input input-name\">\n			</select>\n			<label></label>\n		</div>\n		<div class=\"form-group m-n ptn mb10\">\n			<textarea class=\"form-control md-input input-description\"  placeholder=\"Description...\" name=\"description\" id=\"";
  if (helper = helpers.cid) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.cid); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "_description\">"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.model)),stack1 == null || stack1 === false ? stack1 : stack1.description)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</textarea>\n			<label></label>\n		</div>\n	</span>\n\n</td>\n<td class=\"x-grid-cell x-field-sqft\">\n	<span>\n		<div class=\"form-group floating-label field-square_feet\">\n\n				<input type=\"text\" class=\"form-control md-input static dirty input-square_feet\" value=\""
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.model)),stack1 == null || stack1 === false ? stack1 : stack1.square_feet)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\"/>\n				<label class=\"control-label\">\n				</label>\n\n		</div>\n\n	</span>\n</td>\n<td class=\"x-grid-cell x-field-rate amount num\">\n	<span>\n		<div>\n			<div data-editor class=\"form-group floating-label append-icon field-price\">\n				<input type=\"text\" class=\"form-control md-input static dirty input-price\" value=\""
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.model)),stack1 == null || stack1 === false ? stack1 : stack1.price)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\"/>\n				<label class=\"control-label\">\n				</label>\n				<i class=\"fa fa-usd fa-lg\"></i>\n			</div>\n\n		</div>\n		</span>\n</td>\n<td class=\"x-grid-cell x-field-total amount num\"><span class=\"total\">$"
    + escapeExpression(((stack1 = ((stack1 = ((stack1 = (depth0 && depth0.model)),stack1 == null || stack1 === false ? stack1 : stack1.amount)),stack1 == null || stack1 === false ? stack1 : stack1.total)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</span></td>\n<td class=\"x-grid-cell x-field-action\" style=\"vertical-align: middle;width: 72px\">\n	";
  stack1 = helpers.unless.call(depth0, ((stack1 = (depth0 && depth0.model)),stack1 == null || stack1 === false ? stack1 : stack1.id), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n	";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.model)),stack1 == null || stack1 === false ? stack1 : stack1.id), {hash:{},inverse:self.noop,fn:self.program(3, program3, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n		<!--<a class=\"btn-link mr10\" href=\""
    + escapeExpression((helper = helpers.url || (depth0 && depth0.url),options={hash:{},data:data},helper ? helper.call(depth0, "quote#edit", (depth0 && depth0.id), options) : helperMissing.call(depth0, "url", "quote#edit", (depth0 && depth0.id), options)))
    + "\" title=\"Edit\"><i class=\"md md-edit\"></i></a>-->\n\n\n\n</td>\n";
  return buffer;
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}