var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"bbm-modal__section pn\">\n	<iframe src=\"\"  width=\"880\" height=\"600\" frameborder=\"0\"></iframe>\n</div>\n<div class=\"bbm-modal__bottombar\">\n	<a href=\"#\" class=\"bbm-button btn-cancel\">close</a>\n	<a href=\"#\" class=\"bbm-button btn-submit\">submit</a>\n</div>";
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}