var Application = require('application');
var routes = require('routes');
var utils = require('lib/utils');

var accessToken = utils.getCookie('accessToken');

var mediator = Chaplin.mediator;
//$.ajaxSetup({
//	error: function (jqXHR, textStatus, errorThrown) {
//		console.log('error');
//		if (jqXHR.status === 302) {
//			alert("Element not found.");
//		} else {
//			alert("Error: " + textStatus + ": " + errorThrown);
//		}
//	}
//});
$(function() {
	return new Application({
		title: 'Brunch example application',
		controllerSuffix: '-controller',
		routes: routes,
		pushState: true
	});
});
