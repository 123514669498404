/**
 * Created by soundview on 3/10/15.
 */
var View = require('views/base/collection-view');
var Model = require('../model');
var Collection = require('../collection');
var ItemView = require('./item-view');
var LoadingMask = require('views/loading-mask-view');

var QuickView = require('./quick-view');


var QuoteItemsView = View.extend({
	autoRender: true,
	//el: '#itemList',
	container: '#items-list',
	tagName: 'div',
	noWrap: true,
	className: '',

	//container: '#clientList',
	//region: 'clientList',

	listen: {
		//'toView collection': '_viewItem',
		//'toViewGroup collection': '_viewGroup',
		'toEdit collection': '_editItem',
		'onAdd collection': '_addItem',
		'quickView collection': '_quickView',
		'closeQuickView collection': 'closeQuickView'
		//'onSave model': '_reload'
		//'toDelete collection': '_deleteItem'
	},
	itemView: ItemView,
	doNotFetch: false,
	listSelector: 'tbody',
	template: require('./collection-template'),
	fallbackSelector: '.no-data',
	loadingSelector: '.loading-mask',
	options: {
		itemView: ItemView,
		doNotFetch: false,
		listSelector: 'tbody',
		template: require('./collection-template'),
		fallbackSelector: '.no-data',
		loadingSelector: '.loading-mask'
	},
	initialize: function (options) {
		//this.options = options;
		//this.$loadingContainer = $('<div class="loading-mask" />');
		//this.$el.append(this.$loadingContainer);

		this.collection = options.collection;
		this.options = _.defaults(options || {}, this.options);
		this.template = this.options.template;

		this.listenTo(this.collection, 'save', this._reload);


		this.listenTo(Backbone, 'quoteitem:created', this._reload);

		//this._showLoading();

		//this.itemView = this.options.itemView;

		QuoteItemsView.__super__.initialize.call(this, options);
	},
	closeQuickView: function() {
		if(!this.quickView.disposed) {
			this.quickView.dispose();
		}
		this.quickView = null;
		_.each(this.subviews, function(view){
			view.$el.removeClass('quick-view-active');
		});
		console.log(this);
		console.log('closeQuickView');
	},
	_quickView: function(view, model) {
		var toggle;
		if(this.quickView) {
			if(model.id == this.quickView.model.get('id')) {
				toggle = true;
				view.$el.removeClass('quick-view-active');
			}
			this.closeQuickView();
			//view.$el.siblings().removeClass('quick-view-active');
		}



		if(!toggle){
			this.initQuickView(new QuickView({model: model, autoRender: false}));

			view.$el.addClass('quick-view-active');

			view.$el.after(this.quickView.render().el);
		}

	},
	initQuickView: function(view) {
		this.quickView = view;
		this.listenTo(this.quickView, 'close', this.closeQuickView);
	},
	_editItem: function () {
		console.log('edit');
	},
	_addItem: function(model) {
		this.collection.add(model);
		console.log('added');
	},
	//getTemplateData: function() {
	//	return this.options.model.attributes;
	//
	//},
	initItemView: function (model) {
		if (this.itemView) {
			return new this.itemView({
				autoRender: false,
				model: model,
				attributes: {
					'data-id': model.attributes.id
				}
			});
		} else {
			QuoteItemsView.__super__.render.apply(this, arguments);
		}
	},

	_reload: function () {
		this._showLoading();
		if (this.options.doNotFetch) {
			this._hideLoading();
			return;
		}
		try {
			this.collection.fetch({
				reset: true,
				data: $.param({quoteId: this.collection.quoteId}),
				success: _.bind(function () {
					console.log(this.collection);
					var self = this;
					_.delay(function(){
						self._hideLoading();
						self.collection.trigger('updated', self.collection);
					}, 800);
				}, this),
				error: _.bind(function (collection, response) {
					//this._showLoadItemsError(response.responseJSON || {});
					console.log(response.responseJSON);
				}, this)
			});
		} catch (err) {
			//this._showLoadItemsError(err);
			console.log(err);
		}
	},
	/**
	 * Shows loading indicator
	 */
	_showLoading: function () {
		this.subview('loadingMask', new LoadingMask({
			container: this.$el
		}));
		this.subview('loadingMask').show();
	},

	/**
	 * Hides loading indicator
	 */
	_hideLoading: function () {
		this.removeSubview('loadingMask');
	}
});
module.exports = QuoteItemsView;