/**
 * Created by soundview on 8/12/15.
 */
var View = require('views/base/view');
var BaseModel = require('models/base/model');
var BaseCollection = require('models/base/collection');
var utils = require('lib/utils');

var AmountWidgetsView = require('modules/dashboard/widget/amount-widgets');
var RecentQuotesWidget = require('modules/dashboard/widget/recent-quotes');

var WindowQuotes = require('window/collection');

var DashboardView = View.extend({
	autoRender: true,
	className: 'box',
	id: 'app-content-body',
	template: require('./templates/view'),
	initialize: function(options){
		DashboardView.__super__.initialize.call(this, options);

		var WindowQuoteCollection = BaseCollection.extend({
			url: '/index.php/api/v1/w_quotes',
		});

		var QuoteAmountCollection = BaseCollection.extend({
			url: '/index.php/api/v1/dashboard',
		});



		this.recentQuotes = new WindowQuoteCollection();
		this.quoteAmounts = new QuoteAmountCollection();



		this.amountWidgets = new AmountWidgetsView({collection: this.quoteAmounts});


		console.log(this.recentQuotesWidget);





		this.recentQuotes.fetch({
			reset: true,
			data: $.param({
				recentQuotes: true
			})
		});
		this.quoteAmounts.fetch();

		console.log(this.quoteAmounts);
	},
	initRecentQuotes: function() {
		this.recentQuotesWidget = new RecentQuotesWidget({collection: this.recentQuotes, autoRender: true, container: this.$el.find('#recentQuotes')});
		//this.$el.find('#recentQuotes').append(this.recentQuotesWidget.render().el);
	},
	render: function() {
		DashboardView.__super__.render.apply(this,arguments);
		this.initRecentQuotes();
	}
});

module.exports = DashboardView;