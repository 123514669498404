/**
 * Created by soundview on 3/6/15.
 */
var View = require('views/base/view');
var QuoteInfoView = require('views/quote/quote-info');

module.exports = View.extend({
	region: 'content',
	autoRender: true,
	className: 'box',
	id: 'content-page',
	regions: {
		'main': '#app-content-body',
		'header': '#content-header',
		'info': '#info-panel',
		'detail': '#main-panel'
	},
	template: require('./templates/quote')

});
