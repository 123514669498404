/**
 * Created by soundview on 3/9/15.
 */
/**
 * Created by soundview on 3/9/15.
 */

var Model = require('./insulation-quote-item');

module.exports = Chaplin.Collection.extend({
	// Mixin a synchronization state machine.
	// initialize: function() {
	//   _.extend(this, Chaplin.SyncMachine);
	//   Chaplin.Model.prototype.apply(this, arguments);
	//   this.on('request', this.beginSync);
	//   this.on('sync', this.finishSync);
	//   this.on('error', this.unsync);
	// }
	quoteId: '',
	url: '/index.php/api/v1/insulationitems',
	//url: function(){
	//	return this.urlRoot + '?quoteId=' + this.quoteId;
	//},
	initialize: function (options) {
		options || (options = {});
		this.quoteId = options.quoteId;
	},


	model: Model,
	// Place your application-specific collection features here
	parse: function (data) {
		return data;
	},
	search: function (search) {
		if (search == "") return this;

		var pattern = new RegExp(search, "gi");
		return _(this.filter(function (data) {
			return pattern.test(data.get("name"));
		}));
	}
});