/**
 * Created by soundview on 6/11/15.
 */
/**
 * Created by soundview on 5/11/15.
 */
/**
 * Created by soundview on 3/10/15.
 */
var View = require('views/base/collection-view');
var Model = require('./model');
var Collection = require('./collection');
var ItemView = require('./item-view');
var AttributeCollectionView = View.extend({
	autoRender: false,
	//container: null,
	autoAttach: true,
	id: "attributes-view",
	tagName: 'div',
	className: 'list-group',

	container: '#attr-container',
	//region: 'clientList',

	listen: {
		//'toView collection': '_viewItem',
		//'toViewGroup collection': '_viewGroup',
		'onAdd collection': '_addItem'
		//'filter collection': 'render'
		//'onSave model': '_reload'
		//'toDelete collection': '_deleteItem'
	},
	events: {
		'click .add-item': '_addItem'
	},
	itemView: ItemView,
	doNotFetch: false,
	activeFilter: null,
	listSelector: '#attributes-list',
	template: require('./templates/collection-view'),
	fallbackSelector: '.no-data',
	loadingSelector: '.loading-mask',
	bindings: {
		'#filterTitle': {
			observe: 'activeFilter',
		}
	},
	initialize: function (options) {

		this.collection = new Collection({itemId:options.itemId});
		this.itemId = options.itemId;
		this.collection.fetch({reset: true, data: $.param({itemId: this.itemId})});

		_.bindAll(this, 'render', 'afterRender');
		var _this = this;
		this.render = _.wrap(this.render, function (render) {
			render();
			_this.afterRender();
			return _this;
		});

		this.listenTo(this.collection, 'onDelete', this.deleteAttr);


		AttributeCollectionView.__super__.initialize.call(this, options);


	},
	deleteAttr: function(model) {
		this.collection.remove(model);
		model.destroy();
	},

	_clickItem: function (e, a, o) {
		console.log(e);
		console.log(a);
	},
	filterActive: function (item) {
		//console.log(item);
		return item.get('formattedFile') === this.selectedImage;
	},
	activeCallback: function (view, included) {
		view.$el.toggleClass('active', included);
	},
	_addItem: function () {
		var model = new Model({item_id: this.itemId});
		this.collection.add(model);
		console.log('added');
	},
	_toggleList: function (e) {
		this.$('#attachments-list').toggleClass('list_view');
		$(e.target).toggleClass('active');

	},
	_filterCollection: function (e) {
		console.log(e);
		var $this = $(e.target);
		$this.is('a') || ($this = $this.closest('a'));

		var $active = $this.parent().siblings(".active");
		$active && $active.toggleClass('active');
		$this.parent().toggleClass('active');
		var filter = $this.data('filter');
		if (filter == 'ALL') {
			this.activeFilter = null;
			this.model.set('activeFilter', 'ALL');
		} else {
			this.activeFilter = filter;

			this.model.set('activeFilter', filter);
		}


		this.filter();

	},
	filterer: function (item, index) {
		var filter = this.activeFilter;
		return !this.activeFilter ? true : item.get('attachment_group') === this.activeFilter;
	},
	activeItem: function (item, index) {
		return item.get('formattedFile') === this.selectedImage;
	},
	getCollection: function () {
		this.collection.fetch({
			reset: true,
			success: function (data) {
				this.rowCount = data.length;
			}

		});
		console.log(this.collection.models.length);
	},
	//getTemplateData: function() {
	//	return this.options.model.attributes;
	//
	//},
	initItemView: function (model) {
		//if(model.get('formattedFile') == this.selectedImage){ model.set('active', true); }
		if (this.itemView) {
			return new this.itemView({
				autoRender: false,
				model: model
			});
		} else {
			AttributeCollectionView.__super__.render.apply(this, arguments);
		}
	},
	_getLoadingContainer: function () {
		var loadingContainer = this.loadingContainer;
		if (loadingContainer instanceof $) {
			// fetches loading container from options
			loadingContainer = loadingContainer.get(0);
		}
		if (!loadingContainer) {
			// uses the element as default loading container
			loadingContainer = this.$el.get(0);
		}
		return loadingContainer;
	},
	_showLoading: function () {
		this.subview('loading').show();
		console.log('loading');
	},

	_hideLoading: function () {
		this.subview('loading').hide();
	},
	activateImage: function (image) {
		this.image = image;
		this.selectedImage = image.get('formattedFile');
	},


	afterRender: function () {
		var _this = this;



	},
	//render: function(){
	//
	//
	//	this.$el.html(this.template(this.getTemplateData()));
	//
	//},

	_reload: function () {
		this._showLoading();
		if (this.options.doNotFetch) {
			this._hideLoading();
			return;
		}
		try {
			this.collection.fetch({
				reset: true,
				data: $.param({quoteId: this.collection.quoteId}),
				success: _.bind(function () {
					console.log(this.collection);
					var self = this;
					_.delay(function () {
						self._hideLoading();
						self.collection.trigger('updated', self.collection);
					}, 800);
				}, this),
				error: _.bind(function (collection, response) {
					//this._showLoadItemsError(response.responseJSON || {});
					console.log(response.responseJSON);
				}, this)
			});
		} catch (err) {
			//this._showLoadItemsError(err);
			console.log(err);
		}
	}
});
module.exports = AttributeCollectionView;