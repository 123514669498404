/**
 * Created by soundview on 3/5/15.
 */
var Model = require('./insulation-item-lookup');
var Base = require('models/base/collection');
module.exports = Base.extend({
	// Mixin a synchronization state machine.
	// initialize: function() {
	//   _.extend(this, Chaplin.SyncMachine);
	//   Chaplin.Model.prototype.apply(this, arguments);
	//   this.on('request', this.beginSync);
	//   this.on('sync', this.finishSync);
	//   this.on('error', this.unsync);
	// }


	url: '/index.php/api/v1/insulation_lookup',
	model: Model,

	parse: function (data){
		return data;
	}
	// Place your application-specific collection features here
	//parse: function (data) {
	//	return data.data;
	//},
});