/**
 * Created by soundview on 3/10/15.
 */
var View = require('views/base/view');
var Model = require('models/quote-item');

module.exports = View.extend({
	autoRender: true,
	tagName: 'tr',
	className: 'quote-item',
	template: require('./templates/quote-item'),
	events: {
		//'click': 'activate',
		'click td:not(.x-field-action)': 'onEdit',
		'click .btn-delete': 'close',
		'click .btn-copy': '_duplicate'
	},
	bindings: {
		'input[name=name]': {
			observe: 'name',
			events: ['focusout']
		}

	},
	initialize: function(options) {
		this.attributes = options.attributes;
		this.model = options.model;
		this.listenTo(this.model, 'change:name', this.save);
		//this.attributes = {
		//	'data-id': this.model.attributes.id
		//};

		this.render();

		//console.log(this.model.collection.indexOf(this.model));
		///this.listenTo(this.model, 'change:width', this.reload);

	},
	onSave: function() {
		this.model.collection.trigger('updated');
	},
	onEdit: function () {

		Chaplin.utils.redirectTo('quote#edit', { itemId: this.model.attributes.id})
		//this.model.collection.trigger('toEdit', this.model);
	},
	formatSize: function (size) {
		var inch, feet, formattedSize;
		feet = parseInt(size / 12);
		inch = size % 12;

		formattedSize = (feet > 0) ? feet + "' " : "";
		formattedSize += inch + '"';
		return formattedSize;
	},
	getTemplateData: function(){
		var data = this.model.serialize();

		data.__proto__.rowIndex = this.model.collection.indexOf(this.model) + 1;
		return data;
	},
	reload: function() {
		this.model.fetch({
			success:function(val){
				console.log(val);
			}
		});
	},
	stickitGetValues: function (attrNames, options) {
		options = options || {};

		var changes = {};
		_.each(this.bindings, function (v, selector) {
			var namespace = '.stickit.' + this.model.cid;
			var $el = this.$(selector);
			var binding = this.bindings[selector];
			if (_.isString(binding)) {
				binding = {observe: binding};
			}
			var config = Backbone.Stickit.getConfiguration($el, binding);
			if (_.contains(attrNames, config.observe)) {
				var val = config.getVal.call(this, $el, 'manual', config);
				if (!options.patch || this.model.get(config.observe) !== val) {
					changes[config.observe] = val;
				}
			}
		}, this);

		return changes;
	},
	save: function() {
		var attrs = this.stickitGetValues(this.model.keys());
		//var errors = this.model.validate(attrs);
		this.model.save(attrs, {
			wait: true,
			patch: !this.model.isNew()
		});
		this.reload();
		this.render();

	},
	_duplicate: function() {
		var newItem = new Model({quote_id: this.model.get('quote_id')});
		var attr = _.omit(this.model.attributes, 'amount', 'cid', 'created_at', 'display_order', 'id', 'rowIndex', 'service_label', 'service_type_label', 'updated_at', 'square_feet');
		//attr.quote_id = this.model.get('quote_id');
		newItem.save(attr, {patch: true});
		this.model.collection.add(newItem);
		this.model.collection.trigger('save', this);
		console.log(attr);
	},

	close: function () {
		var _this = this
		vex.dialog.confirm({
			message: 'Are you sure you want to destroy this item?',
			callback: function (value) {
				if(value == true) {
					return _this.model.destroy({wait: true});
				}
			}
		});
		//if (this.model.get('primary')) {
		//	alert(__('Primary address can not be removed'));
		//} else {
		//	this.model.destroy({wait: true});
		//}
	},
	render: function () {
		this.$el.html(this.template(this.getTemplateData()));
		this.stickit(); // Chosen is initialized.

	}
});