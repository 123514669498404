/**
 * Created by soundview on 8/13/15.
 */
/**
 * Created by soundview on 8/13/15.
 */
var View = require('views/base/collection-view');

var ItemView = require('./recent-quote');
var BaseModel = require('models/base/model');
var utils = require('lib/utils');

var RecentQuotes = Chaplin.CollectionView.extend({
	autoRender: false,
	listSelector: '#recent-quotes-container',
	itemView: ItemView,
	tagName: 'div',
	className: 'md-card',
	container: '#recentQuotes',
	noWrap: false,
	doNotFetch: false,
	template: require('./templates/recent-quotes'),
	useCssAnimation: false,
	animationStartClass: 'rubberBand animated',
	animationEndClass: '',
	fallbackSelector: '.no-data',
	initialize: function(options){
		RecentQuotes.__super__.initialize.call(this, options);
	},

	render: function() {
		this.$el.append(this.template());
		RecentQuotes.__super__.render.apply(this, arguments);

	}
});

module.exports = RecentQuotes;