var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

function program1(depth0,data) {
  
  var stack1, helper;
  if (helper = helpers.description) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.description); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  return escapeExpression(stack1);
  }

function program3(depth0,data) {
  
  
  return "No Description";
  }

  buffer += "<td class=\"x-grid-cell x-field-drag\">\n	<span><i class=\"md md-apps\"></i></span>\n</td>\n<td class=\"x-grid-cell x-field-index\">\n	<span class=\"num\">";
  if (helper = helpers.rowIndex) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.rowIndex); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n</td>\n<td class=\"x-grid-cell x-field-name\">\n	<span>\n		<div class=\"form-group form-md-line-input pt10\">\n			<input type=\"text\" id=\"";
  if (helper = helpers.cid) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.cid); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "_name\" name=\"name\" class=\"form-control input-sm input-name\" value=\"";
  if (helper = helpers.name) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\"/>\n			<label></label>\n		</div>\n	</span>\n\n</td>\n<td class=\"x-grid-cell x-field-desc two-lines\">\n	<span class=\"holder\">\n		<span>\n		<span class=\"primary\">\n			"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.service_label)),stack1 == null || stack1 === false ? stack1 : stack1.label)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + " "
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.service_type_label)),stack1 == null || stack1 === false ? stack1 : stack1.label)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\n		</span>\n		<span class=\"secondary\">\n			";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.description), {hash:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n		</span>\n	</span>\n	</span>\n\n</td>\n<td colspan=\"2\" class=\"x-grid-cell x-field-size two-lines\">\n	<span class=\"holder\">\n		<span>\n		<span class=\"primary\">\n			W:\n			<span class=\"width\">\n				";
  if (helper = helpers.width) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.width); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n			</span>\n		</span>\n		<span class=\"primary\">\n			H:\n			<span class=\"height\">\n				";
  if (helper = helpers.height) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.height); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n			</span>\n		</span>\n	</span>\n	</span>\n</td>\n<td class=\"x-grid-cell x-field-sqft\">\n	<span>\n		";
  if (helper = helpers.square_feet) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.square_feet); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n	</span>\n</td>\n<td class=\"x-grid-cell x-field-rate amount num\">\n	<span>$";
  if (helper = helpers.price) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.price); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n</td>\n<td class=\"x-grid-cell x-field-labor amount num\"><span>$";
  if (helper = helpers.labor) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.labor); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span></td>\n<td class=\"x-grid-cell x-field-total amount num\"><span>$"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.amount)),stack1 == null || stack1 === false ? stack1 : stack1.total)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</span></td>\n<td class=\"x-grid-cell x-field-action\">\n\n	<div class=\"btn-group\">\n		<a class=\"md-btn md-flat btn-delete mr-5\" href=\"#\" title=\"Delete\">\n			<i class=\"mdi-action-delete\"></i>\n		</a>\n		<a class=\"md-btn btn-copy md-flat\" title=\"Copy\">\n			<i class=\"mdi-content-content-copy\"></i>\n		</a>\n	</div>\n\n\n\n\n\n		<!--<a class=\"btn-link mr10\" href=\""
    + escapeExpression((helper = helpers.url || (depth0 && depth0.url),options={hash:{},data:data},helper ? helper.call(depth0, "quote#edit", (depth0 && depth0.id), options) : helperMissing.call(depth0, "url", "quote#edit", (depth0 && depth0.id), options)))
    + "\" title=\"Edit\"><i class=\"md md-edit\"></i></a>-->\n\n\n\n</td>\n";
  return buffer;
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}