var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<table class=\"table items table-hover fixed-header\" fixed-header id=\"clientQuotesTable\">\n\n	<thead>\n	<tr>\n		<th class=\"status\"><span>STATUS</span></th>\n		<th><span>QUOTE #</span></th>\n		<th><span>CREATED</span></th>\n		<th><span>EXPIRES</span></th>\n		<th><span>CLIENT</span></th>\n		<th class=\"amount num\" style=\"text-align: right; padding-right: 25px;\">\n			<span>AMOUNT</span></th>\n		<th class=\"x-field-action\"><span></span></th>\n	</tr>\n	</thead>\n\n	<tbody id=\"quoteList\">\n\n	</tbody>\n\n\n</table>";
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}