var utils = require('./utils');

var register = function(name, fn) {
  return Handlebars.registerHelper(name, fn);
};

register('with', function(context, options) {
  if (!context || Handlebars.Utils.isEmpty(context)) {
    return options.inverse(this);
  } else {
    return options.fn(context);
  }
});

register('without', function(context, options) {
  var inverse = options.inverse;
  options.inverse = options.fn;
  options.fn = inverse;
  return Handlebars.helpers['with'].call(this, context, options);
});

register('nl2br', function(text){
	var nl2br = (text + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2');
	return new Handlebars.SafeString(nl2br);
});

register("fPhone", function (phoneNumber) {
	phoneNumber = phoneNumber.toString();
	return "(" + phoneNumber.substr(0, 3) + ") " + phoneNumber.substr(3, 3) + "-" + phoneNumber.substr(6, 4);
});

//Handlebars.registerHelper('nl2br', function (text) {
//	var nl2br = (text + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br>' + '$2');
//	return new Handlebars.SafeString(nl2br);
//});

register('list', function(context,options){
	var ret = "<ul>";

	for (var i = 0, j = context.length; i < j; i++) {
		ret = ret + "<li>" + options.fn(context[i]) + "</li>";
	}

	return ret + "</ul>";
});

register('url', function(routeName) {
  var params = [].slice.call(arguments, 1);
  var options = params.pop();
  return utils.reverse(routeName, params);
});
