var View = require('views/base/view');
var mediator = Chaplin.mediator;
var User = require('models/user');
var utils = require('lib/utils');

module.exports = View.extend({
	template: require('./templates/login'),
	container: 'body',
	autoRender: true,

	initialize: function () {
		console.log('Initializing Login View');
	},

	events: {
		"click #loginButton": "login"
	},


	login: function (event) {
		event.preventDefault(); // Don't let this button submit the form
		$('.alert-error').hide(); // Hide any errors on a new submit
		var url = 'http://api.soundviewglass.net/authenticate';
		console.log('Loggin in... ');
		var formValues = {
			email: $('#input__email').val(),
			password: $('#input__password').val()
		};
		var _this = this;
		$.ajax({
			url: url,
			type: 'POST',
			dataType: "json",
			data: formValues,
			success: function (data) {
				console.log(["Login request details: ", data]);
				_this.accessToken = data.token;

				_this.getUserData();
				//if (data.error) {  // If there is an error, show the error messages
				//	$('.alert-error').text(data.error.text).show();
				//}
				//else { // If not, send them back to the home page
				//	window.location.replace('#');
				//}
			}
		});




		console.log(this.accessToken);
	},

	saveAuthResponse: function (response) {
		var authResponse;
		this.status = response.status;
		authResponse = response.authResponse;
		if (authResponse) {
			return this.accessToken = authResponse.accessToken;
		} else {
			return this.accessToken = null;
		}
	},
	getUserData: function() {
		var _this = this;
		$.ajax({
			beforeSend: function (xhr) {
				xhr.setRequestHeader("Authorization", "Bearer " + _this.accessToken);
			},
			url: 'http://api.soundviewglass.net/api/v1/me',
			type: 'GET',
			dataType: "json",
			success: function (data) {
				console.log([" user details: ", data]);
				var userData = [{userId: data.userId, userName: data.userName, accessToken: _this.accessToken}];

				_this.createUser(userData);

			}
		});


	},
	createUser: function(userData) {
		mediator.user = new User();
		mediator.user.set(userData);
		mediator.publish('login', userData);
		this.publishSession(userData);
		//utils.setCookie('user_id', userData.userId);
		//utils.setCookie('user_name', userData.userName);
		utils.setCookie('access_token', this.accessToken);
		Chaplin.utils.redirectTo('window#index');
	},
	loginHandler: function(resp) {
		var authResponse;
		authResponse = resp.authResponse;
		if (authResponse) {
			mediator.publish('loginSuccessful', eventPayload);
			this.publishSession(authResponse);
			return this.getUserData();
		}
	},
	publishSession: function (authResponse) {
		return mediator.publish('session', {
			userId: authResponse.userID,
			userName: authResponse.userName,
			accessToken: authResponse.accessToken
		});
	}

});