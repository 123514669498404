/**
 * Created by soundview on 3/31/15.
 */
var View = require('views/base/view');
var Model = require('models/quote-item');
var layout = require('lib/layout');

var FormView = require('views/templates/bootstrap3');

module.exports = View.extend({
	autoRender: false,
	region: 'content',
	transition: true,
	id: 'app-content-body',
	className: 'box bg-white-only',
	template: require('./templates/item-edit-view'),
	events: {
		'click .item-save-btn': 'submit',
		'click .item-dismiss-btn': 'dismiss'
	},
	services: [{
		id: -1,
		val: '',
		label: '',
		children: []
	}, {
		id: 1,
		val: 'glass',
		label: 'Glass Replacement',
		selected: false,
		children: [
			{
				val: 'g_glass_only',
				label: 'Glass Only'
			}, {
				val: 'g_vinyl',
				label: 'Vinyl'
			}, {
				val: 'g_wood',
				label: 'Wood'
			}, {
				val: 'g_aluminium',
				label: 'Aluminium'
			}
		]
	}, {
		id: 2,
		val: 'skylight',
		label: 'Skylight Replacement',
		selected: false,
		children: [
			{
				val: 'sl_vinyl',
				label: 'Vinyl'
			}, {
				val: 'sl_wood',
				label: 'Wood'
			}, {
				val: 'sl_aluminium',
				label: 'Aluminium'
			}
		]

	}, {
		id: 3,
		val: 'board_up',
		label: 'Board Up',
		selected: false,
		children: [
			{
				val: 'sl_vinyl',
				label: 'Vinyl'
			}, {
				val: 'sl_wood',
				label: 'Wood'
			}, {
				val: 'sl_aluminium',
				label: 'Aluminium'
			}
		]
	}],
	bindings: {
		'.service': {
			observe: 'service',
			initialize: function ($el) {
				$el.select2({
					width: "100%", placeholder: 'Select Service...', allowClear: true,
					minimumResultsForSearch: Infinity
				});
			},
			selectOptions: {
				collection: function () {
					return this.services;
				},
				valuePath: 'val',
				labelPath: 'label'
				//defaultOption: {
				//	label: 'Select Service..',
				//	value: null
				//}
			},
			onSet: function (val) {
				//this.model.set('service_type', null);
				this.$('.service_type').select2('val', null);
				return val;
			}
		},
		'.service_type': {
			observe: ['service_type', 'service'],
			updateModel: false,
			initialize: function ($el) {
				$el.select2({
					width: "100%", placeholder: 'Select Service Type..', allowClear: true,
					minimumResultsForSearch: Infinity
				});
				if (this.model.get('service_type') != null) {
					$el.select2('val', this.model.get('service_type'));
				}
			},
			selectOptions: {
				collection: function () {
					var serviceId = this.model.get('service');
					var state = _.find(this.services, function (item) {
						return item.val === serviceId;
						//console.log(item);
					});
					//var state = _.find(this.services, {id: 2});

					return state ? state.children : '';
				},
				valuePath: 'val',
				labelPath: 'label'
				//defaultOption: {
				//	label: 'Select Service Type..',
				//	value: null
				//}
			},
			onSet: function (val) {
				this.model.set('service_type', val);
				return val;
			}
		},
		'.floor': {
			observe: 'floor',
			updateModel: false,
			initialize: function ($el) {
				$el.select2({
					width: "100%", placeholder: "Select Floor...", allowClear: true,
					minimumResultsForSearch: Infinity
				});
				if (this.model.get("floor") != null) {
					$el.select2("val", this.model.get("floor"));
				}
			},
			onSet: function (val) {
				this.model.set('floor', val);
				return val;
			}
		}

	},
	initialize: function(options){
		this.model = options.model;
		console.log(this.model);

		this.initContainers();

		this.render();

		View.prototype.initialize.apply(this, arguments);


	},
	initContainers: function() {
		this.$formContainer = $('<div class="wrapper" id="item-wrapper"/>');
	},

	render: function() {

		var data = {model: this.model.toJSON(), services: this.services};
		this.$el.html(this.template(data));

		this.form = new Backbone.Form({
			template: require('./templates/form/quote-item-form'),
			model: this.model
		}).render();

		this.$el.find('#item-wrapper').append(this.form.el);
		this.stickit();
		layout.init(this.el);


		//this.$formContainer.append(this.form.el);
	},
	submit: function () {
		var error = this.form.validate();
		if (!error) this.save();
	},
	dismiss: function() {
		this.redirectToQuote();

	},
	save: function() {
		var data = this.form.getValue();

		console.log(data);
		//this.model.set(data);
		//this.model.on('save', this.onSave());
		//this.model.save(data, {patch: true});

		this.model.save(data, {
			wait: true,
			patch: true,
			success: _.bind(function () {
				console.log('success');
				this.redirectToQuote();
				//this.model.collection.trigger('updated', this.model);
			}, this)
		});

	},
	redirectToQuote: function() {
		Chaplin.utils.redirectTo('quote#show', {quoteId: this.model.get('quote_id')});
	}
});