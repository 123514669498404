require('lib/view-helper');
var BaseView = Chaplin.View.extend({
	transition: false,
	animateIn: 'pt-page-moveFromTopFade',
	animateOut: 'pt-page-moveToBottomFade',
	// Allow passing `template` to View constructor so it will be saved
	// as a property on an instance.
	optionNames: Chaplin.View.prototype.optionNames.concat(['template']),

	getTemplateFunction: function () {
		var template, templateFunc;
		template = this.template;
		templateFunc = null;

		if (typeof template === 'string') {
			templateFunc = _.template(template);
			// share a compiled template with all instances built with same constructor
			this.constructor.prototype.template = templateFunc;
		} else {
			templateFunc = template;
		}

		return templateFunc;
		//return this.template;
	},
	getTemplateData: function () {
		var data;
		data = BaseView.__super__.getTemplateData.apply(this, arguments);
		if (!this.model && this.collection && this.collection instanceof BaseCollection) {
			_.extend(data, this.collection.serializeExtraData());
		}
		return data;
	},
	/**
	 * Tries to find element in already declared regions, otherwise calls super _ensureElement method
	 *
	 * @private
	 * @override
	 */
	_ensureElement: function () {
		var $el, el;
		el = this.el;

		if (el && typeof el === 'string' && el.substr(0, 7) === 'region:') {
			$el = this._findRegionElem(el.substr(7));
		}

		if ($el) {
			this.setElement($el, false);
		} else {
			BaseView.__super__._ensureElement.call(this);
		}
	},

	/**
	 * Tries to find element by region name
	 *
	 * @param {string} name
	 * @returns {jQuery|undefined}
	 * @private
	 */
	_findRegionElem: function (name) {
		var $el, region, instance;
		region = Chaplin.mediator.execute('region:find', name);
		if (region != null) {
			instance = region.instance;
			if (instance.container != null) {
				$el = instance.region != null ? $(instance.container).find(region.selector) : instance.container;
			} else {
				$el = instance.$(region.selector);
			}
		}
		return $el;
	},

	transitionIn: function (callback) {

		var view = this;


		var transitionIn = function () {

			view.$el.addClass(view.animateIn + ' animated');
			view.$el.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd animationend', function () {
				view.$el.removeClass('transition');
				view.$el.removeClass(view.animateIn + ' animated');

				if (_.isFunction(callback)) {
					callback();
					console.log('Callback triggered on transitionend for TransitionIn method');
				}
			});
		};

		// setting the page class' css to position: fixed; obviates the need
		// for this and still allows transitions to work perfectly since pos
		// is absolute during animation
		_.delay(transitionIn, 0);
	},

	transitionOut: function (callback) {

		var view = this;

		view.$el.addClass(view.animateOut + ' animated');
		view.$el.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd animationend', function () {

			view.$el.removeClass(view.animateOut + ' animated');

			if (_.isFunction(callback)) {
				callback();   // hard to track bug! He's binding to transitionend each time transitionOut called
			                  // resulting in the callback being triggered callback * num of times transitionOut
			                  // has executed
				console.log('Callback triggered on transitionend for TransitionOut method');
			}
		});

	},

	///**
	// * Tries to find element in already declared regions, otherwise calls super _ensureElement method
	// *
	// * @private
	// * @override
	// */
	//_ensureElement: function () {
	//	var $el, el;
	//	el = this.el;
	//
	//	if (el && typeof el === 'string' && el.substr(0, 7) === 'region:') {
	//		$el = this._findRegionElem(el.substr(7));
	//	}
	//
	//	if ($el) {
	//		this.setElement($el, false);
	//	} else {
	//		BaseView.__super__._ensureElement.call(this);
	//	}
	//},
	//
	///**
	// * Tries to find element by region name
	// *
	// * @param {string} name
	// * @returns {jQuery|undefined}
	// * @private
	// */
	//_findRegionElem: function (name) {
	//	var $el, region, instance;
	//	region = Chaplin.mediator.execute('region:find', name);
	//	if (region != null) {
	//		instance = region.instance;
	//		if (instance.container != null) {
	//			$el = instance.region != null ? $(instance.container).find(region.selector) : instance.container;
	//		} else {
	//			$el = instance.$(region.selector);
	//		}
	//	}
	//	return $el;
	//}
});
module.exports = BaseView;
