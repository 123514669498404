/**
 * Created by soundview on 3/5/15.
 */
var Model = require('models/base/model');

module.exports = Model.extend({
	urlRoot: '/index.php/api/v1/clients',
	url: function () {
		if (!this.id) {
			return this.urlRoot
		}
		return this.urlRoot + '/' + this.id;
	},
	defaults: {
		id: null,
		name: '',
		email: '',
		phone: '',
		fax: '',
		mobile: '',
		web: '',
		address: '',
		created_at: '',
		updated_at: '',
		balance: '',
		active: true
	},
	validation: {
		name: {
			required: true,
			msg: 'Client Name required.'
		},
		address: {
			required: true,
			msg: 'Client Address required.'
		},
		phone: {
			required: true,
			msg: 'Client Phone required.'
		},
		email: {
			required: true,
			msg: 'Client Email required.'
		}
	},
	schema: {
		name: {
			title: 'Name',
			type: 'Text',
			validators: ['required'],
			editorClass: 'static',
			fieldClass: 'col-xs-6 col-sm-6 col-md-6'
		},
		address: {
			title: 'Address',
			type: 'TextArea',
			editorClass: 'static dirty',
			fieldClass: 'col-xs-12 col-sm-12 col-md-12'
		},
		email: {
			title: 'Email',
			type: 'Text',
			editorClass: 'static',
			fieldClass: 'col-xs-6 col-sm-6 col-md-6'
		},
		phone: {
			title: 'Phone',
			type: 'Text',
			//validators: ['required'],
			editorClass: 'static',
			fieldClass: 'col-xs-6 col-sm-6 col-md-6'
		},
		fax: {
			title: 'Fax',
			type: 'Text',
			validators: ['required'],
			editorClass: 'static',
			fieldClass: 'col-xs-6 col-sm-6 col-md-6'
		},
		mobile: {
			title: 'Mobile',
			type: 'Text',
			//validators: ['required'],
			editorClass: 'static',
			fieldClass: 'col-xs-6 col-sm-6 col-md-6'
		},
		web: {
			title: 'Web',
			type: 'Text',
			//validators: ['required'],
			editorClass: 'static',
			fieldClass: 'col-xs-6 col-sm-6 col-md-6'
		}
	}
});