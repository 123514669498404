/**
 * Created by soundview on 5/11/15.
 */
/**
 * Created by soundview on 3/10/15.
 */
var View = require('views/base/view');
var Model = require('./model');
require('lib/view-helper');

module.exports = View.extend({
	autoRender: true,
	tagName: 'div',
	className: 'md-list-item-wrap',
	template: require('./templates/item-view'),
	events: {
		//'click': 'activate',
		'click': 'activate'
	},
	initialize: function (options) {
		this.attributes = options.attributes;
		this.active = options.active;
		this.model = options.model;
		this.listenTo(this.model, 'change:name', this.save);
		if(this.active) {
			this.model.set('active',true);
			this.toggleActive();
		}

		this.listenTo(this.model, 'change:active', this.toggleActive);
		//this.attributes = {
		//	'data-id': this.model.attributes.id
		//};

		//console.log(offset);
		//console.log(this.model.collection.indexOf(this.model)+1/rows);
		//console.log(this.active);
		//console.log(this.model.collection.indexOf(this.model));
		///this.listenTo(this.model, 'change:width', this.reload);
		//console.log(this.model.collection.indexOf(this.model)+1);
		//console.log(this.model.collection.length);
	},
	calcStyle: function() {
		var cols = 4;
		var gutter = 12;
		var gutterGt = 8;
		var colNum = this.model.collection.indexOf(this.model);
		var rows = Math.ceil(this.model.collection.length / 4);
		var rowIndex = Math.ceil((this.model.collection.indexOf(this.model) + 1) / rows) - 1;
		var offset = Math.ceil(colNum - (rowIndex * cols));

		//console.log(rows);
		console.log('COLNUM: ' + colNum);
		//console.log(colNum);
		console.log('ROWINDEX: ' + rowIndex);
		//console.log(rowIndex);
		console.log('OFFSET: ' + offset);
		console.log('-----');
	},
	_activeImage: function(e) {
		console.log(e);
		console.log(this.model.attributes);
		this.model.collection.trigger('activeImage', this.model);
	},
	onSave: function () {
		this.model.collection.trigger('updated');
	},
	onEdit: function () {

		Chaplin.utils.redirectTo('window#edit', {itemId: this.model.attributes.id})
		//this.model.collection.trigger('toEdit', this.model);
	},
	formatSize: function (size) {
		var inch, feet, formattedSize;
		feet = parseInt(size / 12);
		inch = size % 12;

		formattedSize = (feet > 0) ? feet + "' " : "";
		formattedSize += inch + '"';
		return formattedSize;
	},
	getTemplateData: function () {
		var data = this.model.serialize();
		//data.__proto__.attr = _.pick(this.model.toJSON(), 'elevation', 'brand', 'product_line', 'width', 'height', 'style', 'operation', 'material', 'glass', 'obscure_glass', 'grid_pattern', 'exterior_finish', 'interior_finish', 'hardware_finish', 'interior_trim', 'exterior_trim');


		data.__proto__.rowIndex = this.model.collection.indexOf(this.model) + 1;
		return data;
	},
	reload: function () {
		this.model.fetch({
			success: function (val) {
				console.log(val);
			}
		});
	},
	stickitGetValues: function (attrNames, options) {
		options = options || {};

		var changes = {};
		_.each(this.bindings, function (v, selector) {
			var namespace = '.stickit.' + this.model.cid;
			var $el = this.$(selector);
			var binding = this.bindings[selector];
			if (_.isString(binding)) {
				binding = {observe: binding};
			}
			var config = Backbone.Stickit.getConfiguration($el, binding);
			if (_.contains(attrNames, config.observe)) {
				var val = config.getVal.call(this, $el, 'manual', config);
				if (!options.patch || this.model.get(config.observe) !== val) {
					changes[config.observe] = val;
				}
			}
		}, this);

		return changes;
	},
	save: function () {
		var attrs = this.stickitGetValues(this.model.keys());
		//var errors = this.model.validate(attrs);
		this.model.save(attrs, {
			wait: true,
			patch: !this.model.isNew()
		});
		this.reload();
		this.render();

	},
	_duplicate: function () {
		var newItem = new Model({quote_id: this.model.get('quote_id')});
		var attr = _.omit(this.model.attributes, 'amount', 'cid', 'created_at', 'display_order', 'id', 'rowIndex', 'install', 'attr', 'updated_at', 'square_feet');
		//attr.quote_id = this.model.get('quote_id');
		newItem.save(attr, {patch: true});
		this.model.collection.add(newItem);
		this.model.collection.trigger('save', this);
		console.log(attr);
	},

	close: function () {
		var _this = this
		vex.dialog.confirm({
			message: 'Are you sure you want to destroy this item?',
			callback: function (value) {
				if (value == true) {
					return _this.model.destroy({wait: true});
				}
			}
		});
		//if (this.model.get('primary')) {
		//	alert(__('Primary address can not be removed'));
		//} else {
		//	this.model.destroy({wait: true});
		//}
	},
	activate: function () {
		this.model.set('active', true);
		this.model.collection.trigger('activeImage', this.model);
	},

	toggleActive: function () {
		if (this.model.get('active')) {
			this.$el.addClass('active');
		} else {
			this.$el.removeClass('active');
		}
	},
	render: function () {
		this.$el.html(this.template(this.getTemplateData()));
		this.stickit(); // Chosen is initialized.

	}
});