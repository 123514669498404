/**
 * Created by soundview on 8/13/15.
 */
var View = require('views/base/collection-view');

var ItemView = require('./amount-widget');
var BaseModel = require('models/base/model');
var utils = require('lib/utils');

var AmountWidgets = View.extend({
	autoRender: true,
	listSelector: '#amount-widget-container',
	className: 'row',
	itemView: ItemView,
	useCssAnimation: false,
	animationStartClass: 'rubberBand animated',
	animationEndClass: '',
	fallbackSelector: '.no-data',
});

module.exports = AmountWidgets;