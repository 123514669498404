/**
 * Created by soundview on 4/13/15.
 */
var View = require('views/base/collection-view');
var Model = require('models/client');
var Collection = require('models/client-collection');
var ItemView = require('views/client/client-view');
var DetailView = require('views/client/client-detail-view');
var ClientsView = View.extend({
	autoRender: true,
	el: '#clientList',
	className: 'clients-list',
	listSelector: '#clientList',
	itemView: ItemView,
	noWrap: true,
	template: require('./templates/clients-view'),
	events: {
		'click .next-page': 'nextPage',
		'click .prev-page': 'prevPage',
		'click span.block': 'loadDetail'
		//'scroll': 'checkScroll'
	},
	initialize: function(options) {
		this.collection = options.collection;



		//this.collection.models = this.clients.models;

		//this.clients.fetch({reset:true});


		this.initClients();
		console.log(this.clients);
		this.listenTo(this.collection, "reset", this.togglePager);

		this.listenTo(this.collection, "search", this.filterName);

		ClientsView.__super__.initialize.call(this, options);
	},

	initClients: function() {


	},
	initItemView: function (model) {
		if (this.itemView) {
			return new this.itemView({
				autoRender: false,
				model: model,
				attributes: {
					'data-id': model.attributes.id
				}
			});
		} else {
			ClientsView.__super__.render.apply(this, arguments);
		}
	},
	filterName: function(search, collection) {
		console.log('filtername');
		console.log(search);

		if (search == "") {
			this.searchPattern = null;
		} else {
			this.searchPattern = new RegExp(search, "gi");
		}

		//var pattern = new RegExp(search, "gi");

		//this.searchPattern = pattern;
		this.filter();
	},
	filterer: function (item, index) {
		return !this.searchPattern ? true : this.searchPattern.test(item.get("name"));
	},
	addSheet: function() {
		console.log(this.collection);
	},
	loadResults: function () {
		var that = this;
		// we are starting a new load of results so set isLoading to true
		this.isLoading = true;
		// fetch is Backbone.js native function for calling and parsing the collection url
		this.collection.fetch({
			success: function (items) {
				// Once the results are returned lets populate our template
				//that.render();
				//that.renderAllItems();
				//$(that.el).append(that.template(, {tweets: tweets.models, _: _}));
				// Now we have finished loading set isLoading back to false
				that.isLoading = false;
			}
		});
	},

	nextPage: function() {
		console.log('nextpage');
		if (this.collection.hasNextPage()) {
			console.log('nextpage');
			this.collection.getNextPage();
		}
	},
	prevPage: function () {
		if (this.collection.hasPreviousPage()) {
			this.collection.getPreviousPage();
		}
	},
	checkScroll: function () {
		console.log('scroll');
		var triggerPoint = 70; // 100px from the bottom
		if (!this.isLoading && this.el.scrollTop + this.el.clientHeight + triggerPoint > this.el.scrollHeight) {
			//this.collection.state.currentPage += 1; // Load next page
			console.log('trigger');
			console.log(this.collection.links);

			console.log(this.collection.hasNextPage());
			if(this.collection.hasNextPage()){
				this.collection.getNextPage();
			}

			//this.loadResults();
		}
	},
	loadDetail: function() {

	},

	togglePager: function() {
		//var _this = this;
		//	this.$el.find('.next-page').toggleClass('disabled', this.collection.hasNextPage());
		//	this.$el.find('.prev-page').toggleClass('disabled', this.collection.hasPreviousPage());

	},
	render: function() {
		var that = this;
		//_.each(this.clients.models, function(client){
		//	that.collection.add(client);
		//});







		ClientsView.__super__.render.apply(this, arguments);
	}
});

module.exports = ClientsView;