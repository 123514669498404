var SiteView = require('views/site-view');
var mediator = require('mediator');
var messenger = require('lib/messenger');

module.exports = Chaplin.Controller.extend({
  // Compositions persist stuff between controllers.
  // You may also persist models etc.
  beforeAction: function() {
	  mediator.setHandler('addMessage', messenger.addMessage, messenger);
	  mediator.setHandler('showMessage', messenger.notificationMessage, messenger);
	  mediator.setHandler('showFlashMessage', messenger.notificationFlashMessage, messenger);
	  mediator.setHandler('showErrorMessage', messenger.showErrorMessage, messenger);
    return this.reuse('site', SiteView);
  }
});
