var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"bbm-modal__section pn\">\n	<div class=\"\">\n		<div id=\"attachments-view\" class=\"\"></div>\n		<!--<div class=\"col w-lg\">-->\n			<!--<form id=\"uploadImageForm\" class=\"dropzone\" action=\"/upload/w_item/{id}\" method=\"POST\" accept-charset=\"UTF-8\" enctype=\"multipart/form-data\">-->\n				<!--&lt;!&ndash;<input name=\"file\" class=\"input__upload_image\" type=\"file\" />&ndash;&gt;-->\n			<!--</form>-->\n			<!--<div id=\"fileTree\"></div>-->\n		<!--</div>-->\n		<!--<div class=\"col\">-->\n			<!--<div id=\"fileView\">-->\n				<!--<img style=\"max-width:300px\" src=\"\"/>-->\n			<!--</div>-->\n			<!---->\n		<!--</div>-->\n	</div>\n</div>\n<div class=\"bbm-modal__bottombar\">\n	<a href=\"#\" class=\"bbm-button btn-cancel\">close</a>\n	<a href=\"#\" class=\"bbm-button btn-submit\">submit</a>\n</div>";
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}