/**
 * Created by soundview on 6/3/15.
 */
var View = require('views/base/view');
var template = require('./templates/import-view');
var Model = require('../model');
var ImportView = View.extend({
	tagName: 'div',
	autoRender: true,
	className: 'modal md-dialog fade',
	template: template,
	id: 'importQuote',
	events: {
		'click .save-btn': 'save',
		'click .close-btn': 'close'
	},
	initialize: function (options) {

		_.bindAll(this, 'render', 'afterRender');
		var _this = this;
		this.render = _.wrap(this.render, function (render) {
			render();
			_this.afterRender();
			return _this;
		});


		this.model = options.model;

		this.model.fetch();

		console.log(this.model);

		this.listenTo(this.model, 'change', this.render);


		this.options = _.defaults(options || {}, this.options);
		//this.template = this.options.template;


		//this.itemView = this.options.itemView;

		ImportView.__super__.initialize.call(this, options);


	},
	close: function () {
		this.trigger('onClose', this.model, this);

		if (typeof this.onClose === "function") {
			this.onClose();
		}
		this.dismiss();

	},
	show: function () {
		this.$el.modal('show');
	},
	dismiss: function () {
		this.$el.modal('hide');
	},
	save: function () {
		var data = this.form.getValue();
		this.model.save(data, {
			wait: true,
			patch: true,
			success: _.bind(function () {
				console.log('success');
				this.close();

			}, this)
		});
	},
	render: function () {

		//var data = {model: this.model.toJSON()};
		//data.installAddons = options.installAddons;


		ImportView.__super__.render.apply(this, arguments);

		this.$el.html(this.template());

		//this.form = new Backbone.Form({
		//	model: this.model,
		//	template: require('./templates/form')
		//}).render();


		//this.$el.find('#clientEditForm').append(this.form.el);
	},
	afterRender: function () {
		var _this = this;

		this.$('#importQuoteForm').attr('action', '/index.php/w_quotes/' + _this.model.get('id') + '/import');
		//this.stickit();
		this.$el.on('hidden.bs.modal', function (e) {
			// do something...
			console.log('hidden modal');
			_this.dispose();
		});
	}
});

module.exports = ImportView;