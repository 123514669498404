
//var auth = require('models/session');

var backboneSync = Backbone.sync;
var getCookie = function (key) {
	var pair, pairs, val, _i, _len;
	pairs = document.cookie.split('; ');
	for (_i = 0, _len = pairs.length; _i < _len; _i++) {
		pair = pairs[_i];
		val = pair.split('=');
		if (decodeURIComponent(val[0]) === key) {
			return decodeURIComponent(val[1] || '');
		}
	}
	return null;
};

Backbone.sync = function (method, model, options) {
	/*
	 * The jQuery `ajax` method includes a 'headers' option
	 * which lets you set any headers you like
	 */
	console.log('customSync');
	options.headers = {
		/*
		 * Set the 'Authorization' header and get the access
		 * token from the `auth` module
		 */
		'Authorization': 'Bearer ' + getCookie('access_token')
	};

	/*
	 * Call the stored original Backbone.sync method with
	 * extra headers argument added
	 */
	return backboneSync.apply(this, [method, model, options]);
};


module.exports = Backbone.sync;