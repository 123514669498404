/**
 * Created by soundview on 6/11/15.
 */
var BaseModel = require('models/base/model');

var ItemAttr = BaseModel.extend({
	urlRoot: '/index.php/api/v1/w_item_attrs',
	url: function () {
		if (!this.id) {
			return this.urlRoot
		}
		return this.urlRoot + '/' + this.id;
	},
	defaults: {
		active: true,
		item_id: null,
		key: null,
		label: null,
		text: null
	}
});

module.exports = ItemAttr;