var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n							<br/>"
    + escapeExpression((helper = helpers.nl2br || (depth0 && depth0.nl2br),options={hash:{},data:data},helper ? helper.call(depth0, ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.address), options) : helperMissing.call(depth0, "nl2br", ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.address), options)))
    + "\n						";
  return buffer;
  }

function program3(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n							<br/><abbr title=\"Phone\">P:</abbr> "
    + escapeExpression((helper = helpers.fPhone || (depth0 && depth0.fPhone),options={hash:{},data:data},helper ? helper.call(depth0, ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.phone), options) : helperMissing.call(depth0, "fPhone", ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.phone), options)))
    + "\n						";
  return buffer;
  }

function program5(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n							<br/><abbr title=\"Email\">E:</abbr> <a href=\"mailto:"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.email)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\">"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.email)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</a>\n						";
  return buffer;
  }

  buffer += "<div class=\"row-row\" id=\"clientBody\" role=\"tabpanel\">\n	<div class=\"cell card card-tabs card-underline m-b-none\">\n		<div class=\"cell-inner\">\n			<ul class=\"nav nav-tabs nav-lines nav-justified\">\n				<li class=\"active\"><a data-toggle=\"tab\" href=\"#quoteClientTab\"\n				                      aria-expanded=\"true\">Client</a></li>\n				<li class=\"\"><a data-toggle=\"tab\" href=\"#quoteInfo\"\n				                aria-expanded=\"false\">Info</a></li>\n\n			</ul>\n			<div class=\"tab-content\">\n				<div id=\"quoteClientTab\" class=\"tab-pane active\">\n				<fieldset>\n					<address>\n						<strong>"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.name)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</strong>\n						";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.address), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n						";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.phone), {hash:{},inverse:self.noop,fn:self.program(3, program3, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n						";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.email), {hash:{},inverse:self.noop,fn:self.program(5, program5, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n					</address>\n				</fieldset>\n				</div>\n				<div id=\"quoteInfo\" class=\"tab-pane\">\n					<div class=\"form-group\">\n						<select class=\"form-control md-input static dirty\"\n						        name=\"job_type\" id=\"job_type\"></select>\n						<label>Job Type</label>\n					</div>\n					<div class=\"form-group\">\n						<input type=\"text\" id=\"attic_access\"\n						       placeholder='e.g. \"Garage Ceiling, 2nd Floor Bonus Room\"'\n						       class=\"form-control md-input\"/>\n						<label>Attic Access</label>\n						<!--<span class=\"help-block\">Location of access to attic</span>-->\n					</div>\n					<div class=\"form-group\">\n						<input type=\"text\" id=\"wall_const\" placeholder='e.g. \"2x4, 2x6\"'\n						       class=\"form-control md-input\"/>\n						<label>Wall Const.</label>\n						<!--<span class=\"help-block\">Wall construction size</span>-->\n					</div>\n					<div class=\"form-group\">\n						<input type=\"text\" id=\"driveway_dist\" placeholder=\"e.g. 50, 25.5\"\n						       class=\"form-control md-input\"/>\n						<label>Driveway Distance (ft)</label>\n						<!--<span class=\"help-block\">Distance from Driveway in feet</span>-->\n					</div>\n					<div class=\"form-group\">\n						<input class=\"form-control md-input\" id=\"created_at\" name=\"created_at\" />\n						<label>Created</label>\n					</div>\n					<div class=\"form-group\">\n						<input class=\"form-control md-input\" id=\"expires_at\" name=\"expires_at\" />\n						<label>Expires</label>\n					</div>\n					<div class=\"form-group\">\n						<select class=\"form-control md-input\" id=\"quote_status\" name=\"quote_status_id\"></select>\n						<label>Status</label>\n					</div>\n				</div>\n\n			</div>\n		</div>\n	</div>\n</div>\n<div class=\"col-separator-h\"></div>\n<div class=\"card m-b-none\">\n	<div class=\"card-body\">\n		<div class=\"clearfix\">\n				<span class=\"pull-left\">\n				<strong>Subtotal</strong>\n			</span>\n			<span class=\"pull-right\">\n				"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.amount)),stack1 == null || stack1 === false ? stack1 : stack1.item_subtotal)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\n			</span>\n		</div>\n		<div class=\"clearfix\">\n			<span class=\"pull-left\">\n				<strong>Tax</strong>\n			</span>\n			<span class=\"pull-right\">\n				"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.amount)),stack1 == null || stack1 === false ? stack1 : stack1.tax_total)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\n			</span>\n		</div>\n		<div class=\"clearfix\">\n			<span class=\"pull-left\">\n				<strong>Total</strong>\n			</span>\n			<span class=\"pull-right\">\n				"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.amount)),stack1 == null || stack1 === false ? stack1 : stack1.total)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\n			</span>\n		</div>\n		</div>\n</div>\n\n";
  return buffer;
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}