/**
 * Created by soundview on 3/9/15.
 */
/**
 * Created by soundview on 3/5/15.
 */
var View = require('views/base/view');
var Model = require('../model');
var QuoteInfoView = require('./info-view');
var QuoteItem = require('window-items/model');
var QuoteItemsView = require('window-items/list/collection-view');
var EditClientView = require('modules/clients/edit/view');
var ImportQuoteView = require('./import-view');
var mediator = require('mediator');

var ClientModel = require('modules/clients/model');

var QuoteItemEditView = require('window-items/edit/view');
var optionsConfig = require('window-items/options-config');
var DateTimeFormatter = require('lib/formatter/datetime');
var messenger = require('lib/messenger');

//var QuoteTable = require('modules/clients/quote_table/table');

module.exports = View.extend({
	autoRender: true,
	className: 'box',
	region: 'main',
	transition: true,
	regions: {
		'header': '#header-view',
		'info': '#info-panel'
	},
	id: 'app-content-body',
	template: require('./templates/view'),
	events: {
		'click .add-item-btn': '_addItem',
		'click .item-pdf-btn': '_toPdf',
		'click .item-critical-pdf-btn': '_toCriticalPdf',
		'click .back_btn': '_backPath',
		'click .import-quote': 'importQuote',
		'click .refresh-btn': '_testMessenger',
		'click .btn-copy-quote': '_copyQuote'
		//'click .toggle-edit': 'toggleEdit'
	},
	listen: {
		//'toView collection': '_viewItem',
		//'toViewGroup collection': '_viewGroup',
		'toEdit items': '_editItem',
		'onSave items': '_onSave'
		//'onSave items': 'refresh'
		//'toDelete collection': '_deleteItem'
	},
	bindings: {
		'#footer': {
			observe: 'footer',
			events: ['blur'],
			onSet: function (val) {
				this.model.set("footer", val);
				console.log(this.model.changed);
				this.saveAttr();
			},
			afterUpdate: function ($el) {
				//$el.prop('disabled', true);
			}
		},
		'#terms': {
			observe: 'terms',
			events: ['blur'],
			onSet: function (val, options) {
					this.model.set("terms", val);
					console.log(this.model.changed);
					this.saveAttr();
				//this.model.trigger('save', this.model);
			},
			afterUpdate: function ($el) {
				//$el.prop('disabled', true);
			}
		},
		'#input__on_factor': {
			observe: 'on_factor',
			events: ['blur'],
			onSet: function (val, options) {
				this.model.set("on_factor", val);
				this.saveAttr();
				//this.model.trigger('save', this.model);
			}
		},
		'#input__mark_up': {
			observe: 'mark_up',
			events: ['blur'],
			onSet: function (val, options) {
				this.model.set("mark_up", val);
				this.saveAttr();
				//this.model.trigger('save', this.model);
			}
		},
		'#input__brand': {
			observe: 'brand',
			//events: ['blur'],
			initialize: function ($el) {
				$el.select2({
					width: "100%",
					placeholder: 'Select Brand...', allowClear: true,
					minimumResultsForSearch: Infinity
				});
			},
			selectOptions: {
				collection: function () {
					return optionsConfig.brands;
				},
				valuePath: 'id',
				labelPath: 'text',
				defaultOption: {
					label: null,
					value: null
				}
			},
			onSet: function (val) {
				//this.model.set('service_type', null);
				this.model.set("brand", val);
				//console.log(this.model.changed);
				this.saveAttr();
				this.$('.input__product_line').select2('val', null);
				return val;
			}
		},
		'#input__product_line': {
			observe: ['product_line', 'brand'],
			//events: ['blur'],
			initialize: function ($el) {
				$el.select2({
					width: "100%",
					placeholder: 'Select Product Line..',
					allowClear: true,
					minimumResultsForSearch: Infinity
				});
				if (this.model.get('product_line') != null) {
					$el.select2('val', this.model.get('product_line'));
				}
			},
			selectOptions: {
				collection: function () {
					var brandId = this.model.get('brand');
					var brandList = _.find(optionsConfig.brands, function (item) {
						return item.id === brandId;
						//console.log(item);
					});
					//var state = _.find(this.services, {id: 2});

					return brandList ? brandList.PROD_LINES : '';
				},
				valuePath: 'id',
				labelPath: 'text',
				defaultOption: {
					label: null,
					value: null
				}
			},
			onSet: function (val) {
				this.model.set('product_line', val);
				this.saveAttr();
				return val;
			}
		},
		'#input__material': {
			observe: 'material',
			//events: ['change'],
			initialize: function ($el) {
				$el.select2({
					width: "100%", placeholder: "Select Material...", allowClear: true,
					minimumResultsForSearch: Infinity
				});
				if (this.model.get("material") != null) {
					$el.select2("val", this.model.get("material"));
				}
			},
			selectOptions: {
				collection: function () {
					return optionsConfig.materials;
				},
				valuePath: 'id',
				labelPath: 'text',
				defaultOption: {
					label: null,
					value: null
				}
			},
			onSet: function (val) {
				this.model.set('material', val);
				this.saveAttr();
				return val;
			}
		},
		'#input__interior_finish': {
			observe: 'interior_finish',
			events: ['blur'],
			onSet: function (val, options) {
				this.model.set(options.observe, val);
				this.saveAttr();
				//this.model.trigger('save', this.model);
			}
		},
		'#input__exterior_finish': {
			observe: 'exterior_finish',
			events: ['blur'],
			onSet: function (val, options) {
				this.model.set(options.observe, val);
				this.saveAttr();
			}
		},
		'#input__hardware_type': {
			observe: 'hardware_type',
			events: ['blur'],
			onSet: function (val, options) {
				this.model.set(options.observe, val);
				this.saveAttr();
			}
		},
		'#input__hardware_finish': {
			observe: 'hardware_finish',
			events: ['blur'],
			onSet: function (val, options) {
				this.model.set(options.observe, val);
				this.saveAttr();
			}
		},
		'#input__glass': {
			observe: 'glass',
			events: ['blur'],
			onSet: function (val, options) {
				this.model.set(options.observe, val);
				this.saveAttr();
			}
		},
		'#input__grid_pattern': {
			observe: 'grid_pattern',
			events: ['blur'],
			onSet: function (val, options) {
				this.model.set(options.observe, val);
				this.saveAttr();
				//this.model.trigger('save', this.model);
			}
		},
		'#input__interior_caulk': {
			observe: 'interior_caulk',
			events: ['blur'],
			onSet: function (val, options) {
				this.model.set(options.observe, val);
				this.saveAttr();
				//this.model.trigger('save', this.model);
			}
		},
		'#input__exterior_caulk': {
			observe: 'exterior_caulk',
			events: ['blur'],
			onSet: function (val, options) {
				this.model.set(options.observe, val);
				this.saveAttr();
				//this.model.trigger('save', this.model);
			}
		},
		'#input__job_outline': {
			observe: 'job_outline',
			events: ['blur'],
			onSet: function (val, options) {
				this.model.set(options.observe, val);
				this.saveAttr();
				//this.model.trigger('save', this.model);
			}
		},
		'#input__job_name': {
			observe: 'job_name',
			events: [ 'focusout' ],
			onSet: function (val, options) {

				if(val == '') {
					console.log('val blank');
					val = null;
				}
				console.log(val);
				this.model.set(options.observe, val);
				this.saveAttr();
				//this.model.trigger('save', this.model);
			}
		}
	},




	initialize: function (options) {
		//this.quote = this.model.fetch();
		//this.quote = new Collection();
		//this.collection.url = '/index.php/api/clients';
		//this.model.fetch();
		//this._initMainContainers();
		this.model = options.model;
		this.items = options.items;
		this.infoTemplate = require('views/quote/templates/quote-info');



		console.log('model:');console.log(this.model);
		console.log(this.items);
		//this.listenTo(this, 'render', this._initMainContainers());

		//this.listenTo(this.getCollection(), 'reset', this.renderList);
		//this.listenTo(this.clientSearch, 'on', this.renderList);
		//this.model.on("change", function (model) {
		//	console.log(model.get('number'));
		//});
		//
		//this.model.fetch();

		//this._initMainContainers();
		//this.$el.append(this.template);
		this.initItems();
		//this._initMainContainers();
		console.log(this.$infoContainer);

		//this.items.on('toEdit', this._editItem);


		this.listenTo(this.items, 'toEdit', this._editItem);
		this.listenTo(Backbone, 'quoteitem:created', this.refresh);

		this.listenTo(this.items, 'updated', this.refresh);
		this.listenTo(this.items, 'destroy', this.refresh);


		this.listenTo(this.model, 'editClient', this.editClient);

		//this.listenTo(this.model, 'save', this.reloadInfo);
		this.listenTo(this.model, 'updated', this.reloadInfo);
		this.listenTo(this.items, 'updated', this.reloadInfo);

		mediator.subscribe('quickView', this.quickView);

		Backbone.Validation.bind(this, {
			model: this.model
		});
		//this.listenTo(this.model, 'change:footer', this.saveAttr);

		//this.listenTo(this.model, 'sync', this.reloadInfo);


		//this.listenTo(this.items, 'add', this.refresh);


		//this.items.on('save', this.refresh());


	},

	init: function() {

	},
	_initMainContainers: function () {
		//this.$el.append(this.template);
		this.$infoContainer = this.$el.find('#info-panel');
		this.$itemsContainer = this.$el.find('#items_container');
		//this.$dialogHolder = $('<div id="dialog-holder"/>');
		//this.$el.append(this.$dialogHolder);

		this.$dialogHolder = $('<div id="dialog-holder" />');
		$('body').append(this.$dialogHolder);


	},
	initItems: function() {
		//var items = this.model.get('items');
		//this.items = new QuoteItemCollection({models: this.model.attributes.items});


	},
	_toPdf: function() {
		//console.log(window.location);

		//var url = "http://glass.soundviewseattle.org/w_quotes/"+this.model.get('id')+"/pdf";
		var err = this.model.isPrintValid();


		if (err) {
			console.log(err);
			_.each(err, function (e) {
				messenger.addMessage('alert', {title: 'Error', message: e});
			});
			messenger.setup();
			//messenger.notificationFlashMessage('danger', {title: 'Error', message: 'Missing project global variables'});
		} else {
			window.open(this.model.proposalPdf(), "_blank");
		}
	},
	_toCriticalPdf: function () {
		//console.log(window.location);
		var err;

		if(!this.model.isCriticalValid()){
			err = {quote_status_id: 'Quote Status must be Approved'};
		} else {
			err = this.model.isPrintValid();
		}

		if (err) {
			console.log(err);
			_.each(err, function(e){
				messenger.addMessage('alert', {message: e});
			});
			messenger.setup();
			//messenger.notificationFlashMessage('danger', {title: 'Error', message: 'Missing project global variables'});
		} else {
			//var url = "http://glass.soundviewseattle.org/w_quotes/"+this.model.get('id')+"/pdf";
			window.open(this.model.criticalPdf(), "_blank");
		}


		console.log(messenger);
		console.log('messenger');


	},
	_backPath: function () {
		window.history.back();
	},
	importQuote: function () {


		//this.listenTo(this.$editView, 'onClose', this.testOnClose);
		this.$importView = new ImportQuoteView({
			model: this.model
		});
		this.$el.append(this.$importView.show());

		this.listenTo(this.$importView, 'onClose', this.refresh);

		console.log(this.$dialogHolder);

	},

	editClient: function() {

		var model = new ClientModel({id: this.model.get('client_id')});
		//model.fetch().then((function (_this){
		//	_this.renderClientEdit(model);
		//})(this));

		//this.listenTo(this.$editView, 'onClose', this.testOnClose);
		this.$editView = new EditClientView({
			model: model
		});
		this.$el.append(this.$editView.show());

		this.listenTo(this.$editView, 'onClose', this.reloadInfo);

		console.log(this.$dialogHolder);

	},
	renderClientEdit: function(model) {
		console.log(model);
		this.$editView = new EditClientView({
			model: model
		});
		this.$el.append(this.$editView.render().show());
	},
	_addItem: function() {
		var item = new QuoteItem({
			quote_id: this.model.get('id'),
			brand: this.model.get('brand'),
			product_line: this.model.get('product_line'),
			material: this.model.get('material'),
			interior_finish: this.model.get('interior_finish'),
			exterior_finish: this.model.get('exterior_finish'),
			hardware_type: this.model.get('hardware_type'),
			hardware_finish: this.model.get('hardware_finish'),
			glass: this.model.get('glass'),
			grid_pattern: this.model.get('grid_pattern'),
			on_factor: this.model.get('on_factor') ? this.model.get('on_factor'): 0.00,
			mark_up: this.model.get('mark_up') ? this.model.get('mark_up') : 30.00,
		});
		////this.items.add(item);
		//this._editItem(item);
		Chaplin.utils.redirectTo('window#createItem', {model: item})
	},

	_editItem: function (model) {
		this.itemEdit = new QuoteItemEditView({model: model, container: this.$dialogHolder, id: 'w_edit_item-' + model.get('id')});
		//Chaplin.utils.redirectTo('quote#edit', {quoteId: model.get('quoteId'), itemId: model.get('id')});
	},

	renderInfo: function(){
		this.quoteInfo = new QuoteInfoView({autoRender: true, model: this.model, region: 'info'});
		this.subview('quoteInfo', this.quoteInfo);
		//this.$infoContainer.append(this.quoteInfo.render());
		//this.$infoContainer.append(this.infoTemplate(this.model.toJSON()));
	},

	renderItems: function() {
		this.quoteItems = new QuoteItemsView({autoRender: true, collection: this.items, container: this.$itemsContainer});
		//this.quoteItems.on('toEdit', _.bind(this._editItem, this));
		this.subview('quoteItems', this.quoteItems);
	},

	getModel: function() {
		return this.model;
	},

	getItems: function() {
		return this.items;
	},

	refresh: function(){
		//this.getModel().fetch({reset:true});
		//this.getItems().fetch({reset:true});
		//this.quoteItems._reload();
		console.log('refresh');

		this.items.fetch({reset: true, data: $.param({quoteId: this.items.quoteId})});
		//this.model.fetch({wait:true});
		//this.reloadInfo();
		//this.reloadInfo();
		//this.subview('quoteInfo').render();
		//this.renderInfo();
		//this.subview('quoteInfo').reload();
		//this.render();
	},
	reloadInfo: function() {
		console.log('reload-info');
		//this.model.fetch({wait:true});
		this.subview('quoteInfo').reload();
	},

	_onSave: function(model) {
		console.log('onsave-triggered' + model);
	},
	stickitGetValues: function (attrNames, options) {
		options = options || {};

		var changes = {};
		_.each(this.bindings, function (v, selector) {
			var namespace = '.stickit.' + this.model.cid;
			var $el = this.$(selector);
			var binding = this.bindings[selector];
			if (_.isString(binding)) {
				binding = {observe: binding};
			}
			var config = Backbone.Stickit.getConfiguration($el, binding);
			if (_.contains(attrNames, config.observe)) {
				var val = config.getVal.call(this, $el, 'manual', config);
				if (!options.patch || this.model.get(config.observe) !== val) {
					changes[config.observe] = val;
				}
			}
		}, this);

		return changes;
	},
	saveAttr: function(){
		//console.log(val);
		var attr = this.model.changed;

		if(!_.isEmpty(attr)) {
			this.model.save(attr, {patch: true, validate: false});
		}
		//console.log(this.model.attributes);
	},

	toggleEdit: function(e) {
		//console.log(e.target);
		//$(e.target).toggleClass('active');

		var $target = $($(e.currentTarget).data('target'));
		$target.prop('disabled', function (i, v) {
			return !v;
		});


	},

	_copyQuote: function() {
		//var quote_id, client_name, created_at, invoice_group_id;
		var created_at = new Date;
		var quoteCopy = {
			'quote_id': this.model.get('id'),
			'client_name': this.model.attributes.client.name,
			'created_at': created_at.toLocaleDateString(),
			'invoice_group_id': this.model.get('invoice_group_id')
		};

		$.post("/index.php/w_quotes/copy_quote", quoteCopy)
			.done(function (data) {
				Chaplin.utils.redirectTo('window#show', {quoteId: data.id})
				//console.log(data);
			});

		//console.log(quoteCopy);
	},
	_validateGlobals: function() {

		this.$form.validate({
			rules: {
				interior_finish: {
					required: true
				}
			}
		});


	},
	_testMessenger:function() {

		var err = this.model.validate();

		if(err){
			messenger.notificationFlashMessage('danger', {title: 'Error', message: 'Missing project global variables'});
		}
		console.log('messenger');

	},
	quickView: function() {

	},
	render: function(){
		this.$el.append(this.template(this.model.serialize()));

		this._initMainContainers();
		this.renderInfo();
		this.stickit();
		//this.renderInfo();
		this.renderItems();

		this.$globalsForm = this.$el.find('#global-variables-form');

		console.log('render: quote-edit-view');
	}



});

