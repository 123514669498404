/**
 * Created by soundview on 3/5/15.
 */
module.exports = Chaplin.Model.extend({
	url: '/index.php/clients',
	defaults: {
		id: null,
		name: '',
		email: '',
		phone: '',
		fax: '',
		mobile: '',
		web: '',
		address: '',
		created_at: '',
		updated_at: '',
		balance: '',
		active: true
	}
});