var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<header class=\"navbar md-whiteframe-z1 no-radius bg-white\" id=\"header-view\">\n	<ul class=\"nav navbar-tool pull-left\">\n		<li>\n			<a href=\"#\" class=\"back_btn waves-effect\">\n				<i class=\"mdi-navigation-arrow-back i-24\"></i>\n			</a>\n		</li>\n	</ul>\n	<div class=\"navbar-item h4 pull-left\">\n		Dashboard\n	</div>\n	<div class=\"navbar-item navbar-btns pull-right\">\n\n\n		<a class=\"md-btn md-flat md-flat-dk md-btn-icon waves-effect refresh-btn\" data-toggle=\"tooltip\"\n		   data-placement=\"bottom\"\n		   data-title=\"Refresh\" data-original-title=\"\" title=\"\"><i class=\"mdi-navigation-refresh i-20\"></i></a>\n\n	</div>\n\n</header>\n\n\n<div class=\"box-row\">\n	<div class=\"box-cell\">\n		<div class=\"box-inner scrollable wrapper bg-l1\">\n			<div id=\"amount-widget-container\" layout=\"row\" ></div>\n			<div id=\"recentQuotes\"></div>\n		</div>\n	</div>\n</div>";
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}