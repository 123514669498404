var View = require('views/base/view');
var Model = require('models/client');
var Collection = require('models/client-collection');


module.exports = View.extend({
    autoRender: true,
    className: 'ember-table-tables-container',
    template: require('./templates/home'),

	initialize: function(){
		this.collection = new Collection();
		this.collection.url = '/index.php/api/clients';

		this._initMainContainers();

		this.listenTo(this.getCollection(), 'reset', this.renderClientList);

		this.getCollection().fetch({reset: true});

	},
	_initMainContainers: function () {

		this.$tableContainer = $('<table class="table dt-clients" id="dt-clients"/>');
		this.$testContainer = $('<div id="test"/>');
		this.$el.append(this.$testContainer);

		if (!this.$el.find('.dt-clients').length) {
			this.$el.append(this.$tableContainer);
		}

	},

	renderClientList: function(items){

		this.jsonData = [];
		this.jsonData.data = [];
		items.each(function (item) {
			var column = {
				DT_RowId: item.get('id'),
				name:   item.get('name'),
			};
			this.jsonData.push(column);
		}, this);


		console.log(this.jsonData);

		var columns = [];

		var model = this.collection.get(1);

		//_.each(model.attributes, function(attr, key){
		//	var column = { 'title': key };
		//	columns.push(column);
		//	console.log(key);
		//});
		//console.log(JSON.stringify(columns));


		this.$testContainer.text(JSON.stringify(this.collection.toJSON));
		//$(function(){
		//	this.$tableContainer.dataTable({
		//		data: this.jsonData,
		//		"dataSrc": "",
		//		"columns": [{"data": "name"}]
		//	});
		//});

		//this.$tableContainer.dataTable({
		//	data: JSON.stringify(this.jsonData),
		//	"dataSrc": "",
		//	"columns": [{"title": "id"}, {"title": "created_at"}, {"title": "updated_at"}, {"title": "name"}, {"title": "address"}, {"title": "phone"}, {"title": "fax"}, {"title": "mobile"}, {"title": "email"}, {"title": "web"}, {"title": "url_key"}, {"title": "active"}, {"title": "currency_code"}, {"title": "invoice_template"}, {"title": "quote_template"}, {"title": "balance"}]
		//});

		var columns = [{
			name: "id", // The key of the model attribute
			label: "ID", // The name to display in the header
			editable: false, // By default every cell in a column is editable, but *ID* shouldn't be
			width: 100,
			// Defines a cell type, and ID is displayed as an integer without the ',' separating 1000s.
			cell: Backgrid.IntegerCell.extend({
				orderSeparator: ''
			})
		}, {
			name: "name",
			label: "Name",
			// The cell type can be a reference of a Backgrid.Cell subclass, any Backgrid.Cell subclass instances like *id* above, or a string
			cell: "string" // This is converted to "StringCell" and a corresponding class in the Backgrid package namespace is looked up
		}, {
			name: "phone",
			label: "Phone",
			cell: "string" // An integer cell is a number cell that displays humanized integers
		}];

// Initialize a new Grid instance
		var grid = new Backgrid.Grid({
			columns: columns,
			collection: this.collection
		});

// Render the grid and attach the root to your HTML document
		this.$el.append(grid.render().el);


		// Fetch some countries from the url
		//territories.fetch({reset: true});

	},

	getCollection: function () {
		return this.collection;
	},

	getJsonData: function() {

	}


});

