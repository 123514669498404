var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<header class=\"navbar md-whiteframe-z1 no-radius bg-white\" id=\"header-view\">\n	<ul class=\"nav navbar-tool pull-left\">\n		<li>\n			<a href=\"#\" class=\"back_btn waves-effect\">\n				<i class=\"mdi-navigation-arrow-back i-24\"></i>\n			</a>\n		</li>\n	</ul>\n	<div class=\"navbar-item h4 pull-left\">\n		<span>Quote #";
  if (helper = helpers.number) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.number); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n		<span class=\"font-thin text-muted\">";
  if (helper = helpers.status_label) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.status_label); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n	</div>\n	<div class=\"navbar-item navbar-btns pull-right\">\n\n		<a class=\"md-btn md-flat md-flat-dk md-btn-icon item-pdf-btn\">\n			<i class=\"mdi-action-print i-20\"></i>\n		</a>\n\n\n		<a class=\"md-btn md-btn-icon md-flat md-flat-dk add-item-btn\">\n			<i class=\"mdi-content-add i-20\"></i>\n		</a>\n\n		<div class=\"btn-group dropdown\">\n			<a class=\"md-btn md-flat md-flat-dk dropdown-toggle\" data-toggle=\"dropdown\">\n				<span class=\"dropdown-label\">Actions</span>\n				<span class=\"caret\"></span>\n			</a>\n			<ul class=\"dropdown-menu dropdown-menu-scale pull-up pull-right text-left\">\n				<li><a href=\"javascript:void(0)\" id=\"btn-copy-quote\"><i class=\"fa fa-copy\"></i> Copy Quote</a></li>\n				<li><a href=\"javascript:void(0)\" id=\"btn-quote-to-invoice\"><i class=\"fa fa-check\"></i> Quote To\n					Invoice</a></li>\n				<li><a href=\"/client_center/quote/";
  if (helper = helpers.url_key) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.url_key); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" target=\"_blank\"><i class=\"fa fa-globe\"></i>\n					Public</a></li>\n				<li class=\"divider\"></li>\n				<li><a href=\"#\"\n				       onclick=\"return vex.dialog.confirm({message:'Are you sure you want to Delete Quote #";
  if (helper = helpers.number) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.number); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "?', callback: function(){}});\"><i\n						class=\"fa fa-trash-o\"></i> Delete</a></li>\n			</ul>\n		</div>\n\n		<a class=\"md-btn md-flat md-flat-dk md-btn-icon refresh-btn\" data-toggle=\"tooltip\"\n		   data-placement=\"bottom\"\n		   data-title=\"Refresh\" data-original-title=\"\" title=\"\"><i class=\"mdi-navigation-refresh i-20\"></i></a>\n\n	</div>\n</header>\n\n<div class=\"row-row\">\n	<div class=\"cell\">\n		<div class=\"cell-inner\">\n			<div class=\"hbox\">\n				<div class=\"col\" id=\"main-panel\">\n					<div class=\"vbox bg-white-only\">\n						<div class=\"row-row\">\n							<div class=\"cell\">\n								<div class=\"cell-inner\">\n\n									<div class=\"wrapper\">\n										<div class=\"row\">\n											<div class=\"col-sm-12\">\n												<div id=\"items_container\">\n\n												</div>\n												<!--<a href=\"#\" class=\"btn btn-x btn-block mb15 add-item-btn waves-effect\">Add Item</a>-->\n											</div>\n										</div>\n										<hr />\n										<div class=\"row\">\n											<div class=\"col-sm-6\">\n												<div class=\"form-group label-lg\">\n													<textarea type=\"textarea\" id=\"terms\" name=\"terms\"\n													          class=\"form-control\" rows=\"5\"></textarea>\n													<label for=\"#terms\" class=\"text-lg\">NOTES</label>\n												</div>\n											</div>\n											<div class=\"col-sm-6\">\n												<div class=\"form-group label-lg\">\n													<textarea type=\"textarea\" id=\"footer\" name=\"footer\"\n													          class=\"form-control\" rows=\"5\"></textarea>\n													<label for=\"#footer\">SERVICES DESC</label>\n												</div>\n											</div>\n										</div>\n									</div>\n\n\n								</div>\n							</div>\n						</div>\n\n					</div>\n				</div>\n\n				<div class=\"col w-lg\" id=\"info-panel\">\n\n				</div>\n			</div>\n		</div>\n	</div>\n</div>\n\n\n";
  return buffer;
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}