/**
 * Created by soundview on 3/10/15.
 */
var View = require('views/base/view');
var Model = require('models/insulation-quote-item');
var InsulationItemLookup = require('models/insulation-item-lookup-collection');

module.exports = View.extend({
	autoRender: true,
	tagName: 'tr',
	className: 'quote-item',
	template: require('./templates/insulation-item'),
	events: {
		'click .btn-delete': 'close',
		'click .btn-save-item': 'saveNew',
		'click .btn-duplicate': '_duplicate'
	},
	insulationData: [{
		"id": 1,
		"created_at": "2014-12-08 21:34:46",
		"updated_at": "2014-12-08 21:34:46",
		"name": "Attic, R38, Blown In, Cellulose",
		"description": "",
		"price": "1.25"
	}, {
		"id": 2,
		"created_at": "2014-12-08 21:34:46",
		"updated_at": "2014-12-08 21:34:46",
		"name": "Attic, R49, Blown In, Cellulose",
		"description": "",
		"price": "1.80"
	}, {
		"id": 3,
		"created_at": "2014-12-08 21:34:46",
		"updated_at": "2014-12-08 21:34:46",
		"name": "Wall 2x4, R13, Blown In, Cellulose",
		"description": "",
		"price": "2.50"
	}, {
		"id": 4,
		"created_at": "2014-12-08 21:34:46",
		"updated_at": "2014-12-08 21:34:46",
		"name": "Wall 2x6, R20, Blown In, Cellulose",
		"description": "",
		"price": "2.25"
	}, {
		"id": 5,
		"created_at": "2014-12-08 21:34:46",
		"updated_at": "2014-12-08 21:34:46",
		"name": "Wall 2x4, R11, Batt",
		"description": "",
		"price": "2.02"
	}, {
		"id": 6,
		"created_at": "2014-12-08 21:34:46",
		"updated_at": "2014-12-08 21:34:46",
		"name": "Wall 2x4, R13, Batt",
		"description": "",
		"price": "1.11"
	}, {
		"id": 7,
		"created_at": "2014-12-08 21:34:46",
		"updated_at": "2014-12-08 21:34:46",
		"name": "Wall 2x6, R19, Batt",
		"description": "",
		"price": "1.89"
	}, {
		"id": 8,
		"created_at": "2014-12-08 21:34:46",
		"updated_at": "2014-12-08 21:34:46",
		"name": "Wall 2x6, R21, Batt",
		"description": "",
		"price": "1.44"
	}, {
		"id": 9,
		"created_at": "2014-12-08 21:34:46",
		"updated_at": "2014-12-08 21:34:46",
		"name": "Floor, R21, Batt",
		"description": "",
		"price": "1.00"
	}, {
		"id": 10,
		"created_at": "2014-12-08 21:34:46",
		"updated_at": "2014-12-08 21:34:46",
		"name": "Ceiling, R30, Batt",
		"description": "",
		"price": "1.00"
	}, {
		"id": 11,
		"created_at": "2014-12-08 21:34:46",
		"updated_at": "2014-12-08 21:34:46",
		"name": "Remove Existing, Batt",
		"description": "",
		"price": "2.00"
	}, {
		"id": 12,
		"created_at": "2014-12-08 21:34:46",
		"updated_at": "2014-12-08 21:34:46",
		"name": "Remove Existing, Cellulose",
		"description": "",
		"price": "2.00"
	}, {
		"id": 13,
		"created_at": "2014-12-08 21:34:46",
		"updated_at": "2014-12-08 21:34:46",
		"name": "Other (Non-Standard)",
		"description": "",
		"price": "0.00"
	}],
	bindings: {
		'.input-name': {
			observe: 'name',
			initialize: function($el, model, options){
				$el.select2({width: "100%"});

			},
			selectOptions: {
				collection: function(){
					return this.insulationData;
				},
				valuePath: 'name',
				labelPath: 'name',
				defaultOption: {
					label: '',
					value: null
				}
			},
			//update: function ($el, val) {
			//	$el.val(val);
			//},
			//setOptions: {silent: true},
			onSet: 'setName'
			//onSet: function (val, v) {
			//	var lookupId = val;
			//	var insItem = this.insulationItems.findWhere({name: lookupId});
			//
			//	this.model.set({'name': val, price: insItem.get('price')});
			//
			//	if(!this.model.isNew()){
			//		this.saveAttr();
			//	}
			//	//console.log(this.insulationItems);
			//
			//}
		},
		'.input-price': {
			observe: 'price',
			events: ['blur'],
			onSet: function (val) {
				this.model.set('price', val);
				this.saveAttr();
			}
		},
		'.input-description': {
			observe: 'description',
			events: ['blur'],
			onSet: function (val) {
				this.model.set('description', val);
				this.saveAttr();
			}
		},
		'.input-square_feet': {
			observe: 'square_feet',
			events: ['blur'],
			onSet: function (val) {
				this.model.set('square_feet', val);
				this.saveAttr();
			}
		}

	},
	initialize: function(options) {
		_.bindAll(this, 'render');



		this.attributes = options.attributes;
		this.test = options.test;
		//this.model = options.model;
		this.insulationItems = options.insulationItems;
		//this.listenTo(this.model, 'change:name', this.save);
		//this.insulationItems = new InsulationItemLookup();
		//
		//this.insulationItems.fetch({reset:true});





		//this.attributes = {
		//	'data-id': this.model.attributes.id
		//};

		this.constructor.__super__.initialize.call(this, options);

		//console.log(this.model.collection.indexOf(this.model));
		///this.listenTo(this.model, 'change:width', this.reload);

	},
	getIns: function(collection) {
		var d;
		_.each(collection.model, function(item){
			d.push(item.serialize());
		});

	},

	setName: function(val, v){
		console.log(v);


		var lookupId = val;

		var insData = _.find(this.insulationData, function (item) {
			return item.name === lookupId;
			//console.log(item);
		});


		//var insItem = this.insulationItems.findWhere({name: lookupId});
		//console.log(insItem);
		var price = insData.price;
		var square_feet = this.model.get('square_feet');
		var total = square_feet * price;

		this.model.set({'name': val, price: insData.price});

		if (this.model.isNew()) {
			return;
		}
		if(!this.model.isNew()){
			this.saveAttr();
		}
			//console.log(this.insulationItems);

	},
	onSave: function() {
		this.model.collection.trigger('updated');
	},
	onEdit: function () {

		Chaplin.utils.redirectTo('quote#edit', { itemId: this.model.attributes.id})
		//this.model.collection.trigger('toEdit', this.model);
	},
	formatSize: function (size) {
		var inch, feet, formattedSize;
		feet = parseInt(size / 12);
		inch = size % 12;

		formattedSize = (feet > 0) ? feet + "' " : "";
		formattedSize += inch + '"';
		return formattedSize;
	},
	getInsulationData: function() {
		return this.insulationItems;
	},
	getInsulationItems: function(collection) {
		var ins = [];
		_.each(this.insulationItems.models, function(model){
			ins.push(model.toJSON());
			console.log(model.serialize());
		});


		console.log(JSON.stringify(ins));
	},
	getTemplateData: function(){
		var data = {
			model: this.model.serialize(),
			rowIndex: this.model.collection.indexOf(this.model) + 1,
			cid: this.model.cid,
			insulationItems: this.insulationItems
		};

		//data.__proto__.rowIndex = this.model.collection.indexOf(this.model) + 1;
		return data;
	},
	reload: function() {
		this.model.fetch({
			success:function(val){
				console.log(val);
			}
		});
	},
	stickitGetValues: function (attrNames, options) {
		options = options || {};

		var changes = {};
		_.each(this.bindings, function (v, selector) {
			var namespace = '.stickit.' + this.model.cid;
			var $el = this.$(selector);
			var binding = this.bindings[selector];
			if (_.isString(binding)) {
				binding = {observe: binding};
			}
			var config = Backbone.Stickit.getConfiguration($el, binding);
			if (_.contains(attrNames, config.observe)) {
				var val = config.getVal.call(this, $el, 'manual', config);
				if (!options.patch || this.model.get(config.observe) !== val) {
					changes[config.observe] = val;
				}
			}
		}, this);

		return changes;
	},
	saveAttr: function () {
		//console.log(val);
		if(this.model.isNew()){
			return;
		}
			console.log(this.model.changed);
		var attr = this.stickitGetValues(this.model.keys());
		console.log(attr);
		this.model.save(attr, {patch:true});
		this.model.collection.trigger('save', this.model);
		//this.reload();
			//if (this.model.changed) {
			//	this.model.save(this.model.changed, {patch: true});
			//	this.model.collection.trigger('save', this.model);
			//	this.reload();
			//}

		//console.log(this.model.attributes);
	},
	saveNew: function() {
		var attrs = this.stickitGetValues(this.model.keys());
		this.model.save(attrs, { wait: true, path: true});
		this.model.collection.trigger('save', this.model);
	},
	_reload: function() {
		this.model.fetch({reset:true});
	},
	save: function() {


		var attrs = this.stickitGetValues(this.model.keys());

		//var errors = this.model.validate(attrs);
		this.model.save(attrs, {
			wait: true,
			patch: !this.model.isNew()
		});
		this.reload();
		this.render();

	},


	close: function () {
		var _this = this;
		vex.dialog.confirm({
			message: 'Are you sure you want to destroy this item?',
			callback: function (value) {
				if(value == true) {
					return _this.model.destroy({wait: true});

				}
			}
		});

		//if (this.model.get('primary')) {
		//	alert(__('Primary address can not be removed'));
		//} else {
		//	this.model.destroy({wait: true});
		//}
	},
	initSelect: function() {
		this.$('.input-name').select2('val', this.model.get('name'));

	},
	_duplicate: function(model) {
		console.log(model);
		var dup = new Model();
		var attr = this.stickitGetValues(this.model.keys());
		attr.quote_id = this.model.get('quote_id');
		dup.save(attr, {patch:true});
		this.model.collection.trigger('save', this);
	},
	render: function () {
		this.$el.html(this.template(this.getTemplateData()));

		this.stickit(); // Chosen is initialized.

		//this.$el.find('.input-name').select2()




	}
});