var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  
  return "\n		        <span class=\"secondary text-success\">Viewed</span>\n	        ";
  }

function program3(depth0,data) {
  
  
  return "\n		        <span class=\"secondary text-default\">Not Viewed</span>\n	        ";
  }

  buffer += "<td class=\"status two-lines\">\n    <span class=\"holder\">\n        <span class=\"";
  if (helper = helpers.status_text) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.status_text); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\"><span\n                class=\"primary\">";
  if (helper = helpers.status_label) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.status_label); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n	        ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.viewed), {hash:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        </span>\n    </span>\n\n</td>\n<td>\n	<a href=\"#\"\n	   title=\"Edit\"><span>";
  if (helper = helpers.number) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.number); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span></a></td>\n<td>\n	<span>";
  if (helper = helpers.formatted_created_at) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.formatted_created_at); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n</td>\n<td>\n	<span>";
  if (helper = helpers.formatted_expires_at) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.formatted_expires_at); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n</td>\n<td class=\"two-lines\">\n	<a href=\"#\"\n                         title=\"View Client\">\n                <span>\n                    <span class=\"primary\">";
  stack1 = ((stack1 = ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.name)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</span>\n                    <span class=\"secondary\">";
  stack1 = ((stack1 = ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.email)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</span>\n                </span>\n</a>\n</td>\n<td class=\"amount num\" style=\"text-align: right; padding-right: 25px;\">\n	<span>\n		"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.amount)),stack1 == null || stack1 === false ? stack1 : stack1.formatted_total)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\n	</span>\n</td>\n<td>\n\n			<span>\n				<a href=\"#\" class=\"table-action\"\n				   title=\"Edit\"><i class=\"icon-edit\"></i></a>\n				<a href=\"#\" target=\"_blank\" id=\"btn-pdf-quote\"\n				   class=\"table-action\" title=\"Print PDF\"><i class=\"fa fa-print\"></i></a>\n                <a href=\"#\" target=\"_blank\"\n                   id=\"btn-public-quote\" class=\"table-action\" title=\"Public\"><i\n	                    class=\"fa fa-globe\"></i></a>\n				<a href=\"#\" class=\"table-action\"\n				   title=\"Delete\"\n				   onclick=\"return confirm('Confirm delete?');\"><i class=\"icon-table-x\"></i></a>\n			</span>\n\n</td>\n";
  return buffer;
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}