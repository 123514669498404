var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"modal-dialog md-dialog\" id=\"importQuote\">\n	<form class=\"form\" id=\"importQuoteForm\" method=\"POST\"\n	      accept-charset=\"UTF-8\" enctype=\"multipart/form-data\">\n	<div class=\"modal-content\">\n		<header class=\"md-subheader clearfix\">\n\n\n			<div class=\"md-subheader-inner pull-left\">\n				<span class=\"md-subheader-content\" id=\"widgetTitle\">Import Quote</span>\n			</div>\n			<span flex></span>\n			<button md-ink-ripple type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\"><span\n					aria-hidden=\"true\"></span></button>\n\n		</header>\n		<div class=\"md-body tab-content\" layout=\"row\">\n			<div class=\"\" layout=\"row\" flex>\n				<div class=\"wrapper\" flex>\n\n						<div class=\"form-group\">\n							<input type=\"file\" class=\"form-control\" name=\"xml_quote\" id=\"xml_quote\" />\n						</div>\n\n\n				</div>\n			</div>\n		</div>\n		<div class=\"md-actions\">\n			<span class=\"flex\"></span>\n			<button type=\"button\" class=\"md-btn md-flat close-btn\" data-dismiss=\"modal\">Close</button>\n			<button type=\"submit\" class=\"md-btn md-flat text-primary sumbit-btn\">Submit</button>\n		</div>\n	</div>\n	</form>\n</div>";
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}