var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"wrapper\">\n	<form class=\"form\">\n		<div class=\"row\" data-fields=\"name,square_feet\"></div>\n		<div class=\"row\" data-fields=\"price\"></div>\n		<div class=\"row\" data-fields=\"description\"></div>\n	</form>\n</div>\n";
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}