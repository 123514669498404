var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, functionType="function", escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  var stack1;
  return escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.model)),stack1 == null || stack1 === false ? stack1 : stack1.name)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1));
  }

function program3(depth0,data) {
  
  
  return "New Item";
  }

  buffer += "\n	<div class=\"header bg-white md-whiteframe-z1\">\n		<span class=\"h3 m-n font-thin\">";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.model)),stack1 == null || stack1 === false ? stack1 : stack1.name), {hash:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</span>\n		<div class=\"pull-right btn-group\">\n			<a href=\"#\" class=\"md-btn md-btn-circle md-raised item-dismiss-btn mt10\"><i class=\"mdi-navigation-close\"></i></a>\n		</div>\n	</div>\n	<div class=\"box-row\">\n		<div class=\"box-cell\">\n			<div class=\"box-inner\">\n				<div class=\"wrapper\" id=\"item-wrapper\">\n\n				</div>\n			</div>\n		</div>\n	</div>\n\n	<footer class=\"footer b-t\">\n		<div class=\"pull-right btn-toolbar\">\n			<a href=\"#\" class=\"btn btn-x danger item-dismiss-btn mr10\">CANCEL</a>\n			<a href=\"#\" class=\"btn btn-x primary item-save-btn\">SAVE</a>\n		</div>\n	</footer>\n\n";
  return buffer;
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}