/**
 * Created by soundview on 3/10/15.
 */
// Application routes.
$(function(){
	var Router = Backbone.Router.extend({
		routes: {
			"item_edit": "itemEdit"
		},

		itemEdit: function () {
			alert('Test');
		}
	});
	return Router;
});

