var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  
  return " Active ";
  }

function program3(depth0,data) {
  
  
  return " Inactive ";
  }

  buffer += "<header class=\"navbar md-whiteframe-z1 no-radius bg-white\" id=\"header-view\">\n	<ul class=\"nav navbar-tool pull-left\">\n		<li>\n			<a href=\"javascript:void(0)\" class=\"back_btn waves-effect\">\n				<i class=\"mdi-navigation-arrow-back i-24\"></i>\n			</a>\n		</li>\n	</ul>\n	<div class=\"navbar-item h4 pull-left\">\n		<span>";
  if (helper = helpers.name) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n		<span class=\"font-thin text-muted\">";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.active), {hash:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</span>\n	</div>\n\n\n	<div class=\"navbar-item navbar-btns pull-right\">\n\n		<a class=\"md-btn md-flat md-flat-dk md-btn-icon item-pdf-btn\">\n			<i class=\"mdi-action-print i-20\"></i>\n		</a>\n\n\n		<a class=\"md-btn md-btn-icon md-flat md-flat-dk edit-client-btn\">\n			<i class=\"mdi-content-add i-20\"></i>\n		</a>\n\n		<div class=\"btn-group dropdown\">\n			<a class=\"md-btn md-flat md-flat-dk dropdown-toggle\" data-toggle=\"dropdown\">\n				<span class=\"dropdown-label\">Actions</span>\n				<span class=\"caret\"></span>\n			</a>\n			<ul class=\"dropdown-menu dropdown-menu-scale pull-up pull-right text-left\">\n				<li><a href=\"javascript:void(0)\" id=\"btn-copy-quote\"><i class=\"fa fa-copy\"></i> Copy Quote</a></li>\n				<li><a href=\"javascript:void(0)\" id=\"btn-quote-to-invoice\"><i class=\"fa fa-check\"></i> Quote To\n					Invoice</a></li>\n				<li><a href=\"/client_center/w_quote/";
  if (helper = helpers.url_key) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.url_key); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" target=\"_blank\"><i class=\"fa fa-globe\"></i>\n					Public</a></li>\n				<li class=\"divider\"></li>\n				<li><a href=\"#\"\n				       onclick=\"return vex.dialog.confirm({message:'Are you sure you want to Delete Quote #";
  if (helper = helpers.number) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.number); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "?', callback: function(){}});\"><i\n						class=\"fa fa-trash-o\"></i> Delete</a></li>\n			</ul>\n		</div>\n\n		<a class=\"md-btn md-flat md-flat-dk md-btn-icon refresh-btn\" data-toggle=\"tooltip\"\n		   data-placement=\"bottom\"\n		   data-title=\"Refresh\" data-original-title=\"\" title=\"\"><i class=\"mdi-navigation-refresh i-20\"></i></a>\n\n	</div>\n</header>\n<div class=\"wrapper mh-400\">\n	<div class=\"row\">\n		<div class=\"col-md-8 h100\">\n			<div class=\"card mbn\">\n				<div class=\"card-body\">\n					<h3 class=\"mtn mbn\">";
  if (helper = helpers.name) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</h3>\n\n					<div class=\"form-horizontal\">\n						<div class=\"row\">\n							<div class=\"col-sm-6\">\n								<div class=\"form-group\">\n\n									<label class=\"col-sm-2 fw600\">Address</label>\n\n									<div class=\"col-sm-8 b-l\">";
  if (helper = helpers.formatted_address) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.formatted_address); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</div>\n								</div>\n								<div class=\"form-group\">\n\n									<label class=\"col-sm-2  fw600\">Email</label>\n\n									<div class=\"col-sm-8 b-l\">";
  if (helper = helpers.email) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.email); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</div>\n								</div>\n							</div>\n							<div class=\"col-sm-6\">\n								<div class=\"form-group\">\n									<label class=\"fw600\">Address</label>\n\n									<div>";
  if (helper = helpers.formatted_address) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.formatted_address); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</div>\n								</div>\n							</div>\n						</div>\n					</div>\n\n\n\n\n\n				</div>\n			</div>\n		</div>\n		<div class=\"col-md-4 h100\">\n			<div class=\"card mbn\">\n				<div class=\"card-body\">\n					<strong>Window:</strong><span>"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.formatted_quoted_amounts)),stack1 == null || stack1 === false ? stack1 : stack1.windows)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</span>\n				</div>\n\n			</div>\n		</div>\n	</div>\n\n</div>\n<div class=\"pad12\">\n	<div class=\"wrapper-sm bg-white-only\">\n		<ul class=\"nav nav-tabs nav-lines b-info\" id=\"quoteTabs\">\n			<li role=\"presentation\" class=\"active\"><a href=\"#window_container\" aria-controls=\"window_container\" data-quotes=\"windowQuotes\" role=\"tab\" data-toggle=\"tab\">Window Quotes</a></li>\n			<li role=\"presentation\" class=\"\"><a href=\"#glass_container\" data-quotes=\"glassQuotes\" aria-controls=\"glass_container\"\n			                                          role=\"tab\" data-toggle=\"tab\">Glass Quotes</a></li>\n			<li role=\"presentation\" class=\"\"><a href=\"#insulation_container\" data-quotes=\"insulationQuotes\" aria-controls=\"insulation_container\"\n			                                          role=\"tab\" data-toggle=\"tab\">Insulation Quotes</a></li>\n		</ul>\n	</div>\n</div>\n<div class=\"row-row\">\n	<div class=\"cell\">\n		<div class=\"cell-inner\">\n			<div id=\"window_container\" class=\"vbox vbox-wrap ptn tab-pane active\"></div>\n			<div id=\"glass_container\" class=\"vbox vbox-wrap ptn tab-pane\"></div>\n			<div id=\"insulation_container\" class=\"vbox vbox-wrap ptn tab-pane\"></div>\n		</div>\n	</div>\n</div>\n<!--<div flex class=\"wrapper header\">-->\n	<!--<ul class=\"nav nav-tabs nav-lines b-info\">-->\n		<!--<li class=\"active\"><a>Window Quotes</a></li>-->\n		<!--<li><a>Glass Quotes</a></li>-->\n		<!--<li><a>Insulation Quotes</a></li>-->\n	<!--</ul>-->\n<!--</div>-->\n<!--<div class=\"box-row\">-->\n	<!--<div class=\"box-cell\">-->\n		<!--<div class=\"box-inner\">-->\n			<!--<div id=\"window_container\"></div>-->\n		<!--</div>-->\n	<!--</div>-->\n<!--</div>-->\n<!--<div id=\"dialogHolder\"></div>-->\n\n\n";
  return buffer;
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}