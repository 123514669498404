/**
 * Created by soundview on 6/16/15.
 */
/*jslint nomen:true*/
/*global define*/
var  messenger = require('lib/messenger');
var PageRegionView = require('views/base/page-region-view');

	var PageMainMenuView;

	PageMainMenuView = PageRegionView.extend({
		pageItems: ['flashMessages'],

		/**
		 * Prevents rendering a view without page data
		 *
		 * @override
		 */
		render: function () {
			if (!this.actionArgs || !this.data) {
				return this;
			}

			this.$el.empty();

			// does not show messages from cache
			if (this.actionArgs.options.fromCache !== true) {
				this._addMessages(this.data.flashMessages);
			}

			return this;
		},

		/**
		 * Add session messages
		 *
		 * @param {Object} messages
		 */
		_addMessages: function (messages) {
			_.each(messages, function (messages, type) {
				_.each(messages, function (message) {
					messenger.notificationFlashMessage(type, message);
				});
			});
		}
	});

	module.exports = PageMainMenuView;
