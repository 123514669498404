/**
 * Created by soundview on 3/6/15.
 */
/**
 * Created by soundview on 3/5/15.
 */
var View = require('views/base/view');
var Model = require('models/client');
var Collection = require('models/client-collection');


module.exports = View.extend({
	autoRender: true,
	tagName: 'a',
	container: '#clientList',
	className: 'list-group-item m-l',
	template: require('./templates/client-view'),
	events: {
		'click': 'onClick'
	},
	initialize: function(options) {
		console.log(this.model);
		_.bindAll(this, 'render');
	},
	render: function(){
		this.$el.html(this.template(this.getTemplateData()));
	},
	onClick: function(a) {
		this.model.collection.trigger('toDetailView', this.model, this);
	}


});

