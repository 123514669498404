/**
 * Created by soundview on 3/5/15.
 */
var Model = require('./client');

module.exports = Backbone.PageableCollection.extend({
	// Mixin a synchronization state machine.
	// initialize: function() {
	//   _.extend(this, Chaplin.SyncMachine);
	//   Chaplin.Model.prototype.apply(this, arguments);
	//   this.on('request', this.beginSync);
	//   this.on('sync', this.finishSync);
	//   this.on('error', this.unsync);
	// }
	url: '/index.php/api/v1/clients_list',
	model: Model,
	mode: 'client',
	// Place your application-specific collection features here
	//parse: function (data) {
	//	return data.data;
	//},
	parseRecords: function(data) {
		return data.data;
	},

	parseState: function (data) {
		var state = {
			currentPage: data.current_page,
			lastPage: data.last_page,
			totalRecords: data.total

		}
		return state;
	},
	search: function(search){
		if (search == "") return this;

		var pattern = new RegExp(search, "gi");
		return _(this.filter(function (data) {
			console.log(pattern.test(data.get('name')));
			return pattern.test(data.get("name"));
		}));

		this.trigger('search', pattern, this);
	},
	state: {

		// You can use 0-based or 1-based indices, the default is 1-based.
		// You can set to 0-based by setting ``firstPage`` to 0.
		firstPage: 1,
		currentPage: 1,
		// Required under server-mode
		//totalRecords: 200,

		pageSize: 15
	},

	// You can configure the mapping from a `Backbone.PageableCollection#state`
	// key to the query string parameters accepted by your server API.
	queryParams: {

		// `Backbone.PageableCollection#queryParams` converts to ruby's
		// will_paginate keys by default.
		//currentPage: null,
		//pageSize: "page_size",
		//lastPage: "last_page",
		//totalRecords: "total"
	},

});