/**
 * Created by soundview on 5/26/15.
 */


var LoginView = require('views/login-view2');

var SessionController = Chaplin.Controller.extend({
	login: function() {
		this.view = new LoginView();
	}
});

module.exports = SessionController;