/**
 * Created by soundview on 4/8/15.
 */
var layout;

layout = {
	init: function(container) {
		var $container = $(container);
		this.floatingLabels($container);
	},
	floatingLabels: function($container) {
		var $elements;
		$elements = $container.find('.floating-label .form-control');
		//console.log($elements);
		$elements.each(function(){
			var $this = $(this);
			if($this.val() != '' || $this.is('select') || $this.val().length > 0){
				$this.addClass('dirty').addClass('static');
			} else {
				$this.removeClass('dirty');
			}
		})
	},
	rippleEffect: function($container) {

	}
};

module.exports = layout;