/**
 * Created by soundview on 3/5/15.
 */
var View = require('views/base/view');
var Model = require('models/client');
var Collection = require('models/client-collection');
var ClientsView = require('views/client/clients-view');
var DetailView = require('views/client/client-detail-view');
var ClientList = require('views/client/client-list');


module.exports = View.extend({
	autoRender: true,
	className: 'hbox hbox-auto-xs hbox-auto-sm bg-light',
	template: require('./templates/clients'),
	regions: {
		leftCol: '#left-col',
		listCol: '#list-col',
		clientList: '#clientList',
		display: '#display-col'
	},
	events: {
		'keyup #client-search': 'clientSearch',
		//'click a.list-group-item': 'loadDetail'
	},

	initialize: function () {
		this.collection = new Collection();
		this.collection.url = '/index.php/api/v1/clients';

		//this._initMainContainers();

		//this.listenTo(this.getCollection(), 'reset', this.renderList);
		//this.listenTo(this.clientSearch, 'on', this.renderList);

		this.getCollection().fetch({reset: true});

		this.registerRegion('clientList', '#clientList');
		this.registerRegion('display', '#display');
		this.registerRegion('listCol', '#list-col');

		this.listenTo(this.collection, 'toDetailView', this.loadDetail);

		//this.clientList = new ClientList({collection: this.collection});

		//this.clientsView = new ClientsView({collection: this.collection});


	},
	_initMainContainers: function () {

		this.$tableContainer = $('<table class="table dt-clients" id="dt-clients"/>');
		this.$testContainer = $('<div id="test"/>');
		this.$el.append(this.$testContainer);

		if (!this.$el.find('.dt-clients').length) {
			this.$el.append(this.$tableContainer);
		}

	},
	renderList: function () {
		return new ClientList({collection: this.collection});
	},
	loadDetail: function(model) {
		console.log(model);
		//var $target;
		//
		//$target = $(e.currentTarget);
		//

		this.subview('clientDetail') ? this.subview('clientDetail').dispose() : '';
		this.clientDetailView = new DetailView({autoRender: true, model: model, region: 'display'});
		//
		this.subview('clientDetail', this.clientDetailView);
		//
		//console.log($target.data('id'));
	},

	clientSearch: function (e) {
		console.log($(e.target).val());
		var search = $(e.target).val();

		this.collection.trigger('search', search, this.collection);

		console.log(this.collection.search(search));

	},

	renderClientList: function (items) {

		this.jsonData = [];
		this.jsonData.data = [];
		items.each(function (item) {
			var column = {
				DT_RowId: item.get('id'),
				name: item.get('name'),
			};
			this.jsonData.push(column);
		}, this);


		console.log(this.jsonData);

		var columns = [];

		var model = this.collection.get(1);

		//_.each(model.attributes, function(attr, key){
		//	var column = { 'title': key };
		//	columns.push(column);
		//	console.log(key);
		//});
		//console.log(JSON.stringify(columns));


		this.$testContainer.text(JSON.stringify(this.collection.toJSON));
		//$(function(){
		//	this.$tableContainer.dataTable({
		//		data: this.jsonData,
		//		"dataSrc": "",
		//		"columns": [{"data": "name"}]
		//	});
		//});

		//this.$tableContainer.dataTable({
		//	data: JSON.stringify(this.jsonData),
		//	"dataSrc": "",
		//	"columns": [{"title": "id"}, {"title": "created_at"}, {"title": "updated_at"}, {"title": "name"}, {"title": "address"}, {"title": "phone"}, {"title": "fax"}, {"title": "mobile"}, {"title": "email"}, {"title": "web"}, {"title": "url_key"}, {"title": "active"}, {"title": "currency_code"}, {"title": "invoice_template"}, {"title": "quote_template"}, {"title": "balance"}]
		//});

		var columns = [{
			name: "id", // The key of the model attribute
			label: "ID", // The name to display in the header
			editable: false, // By default every cell in a column is editable, but *ID* shouldn't be
			width: 100,
			// Defines a cell type, and ID is displayed as an integer without the ',' separating 1000s.
			cell: Backgrid.IntegerCell.extend({
				orderSeparator: ''
			})
		}, {
			name: "name",
			label: "Name",
			// The cell type can be a reference of a Backgrid.Cell subclass, any Backgrid.Cell subclass instances like *id* above, or a string
			cell: "string" // This is converted to "StringCell" and a corresponding class in the Backgrid package namespace is looked up
		}, {
			name: "phone",
			label: "Phone",
			cell: "string" // An integer cell is a number cell that displays humanized integers
		}];

		// Initialize a new Grid instance
		var grid = new Backgrid.Grid({
			columns: columns,
			collection: this.collection
		});

		// Render the grid and attach the root to your HTML document
		//this.$el.append(grid.render().el);


		// Fetch some countries from the url
		//territories.fetch({reset: true});

	},

	getCollection: function () {
		return this.collection;
	},

	getJsonData: function () {

	},

	render: function() {

		this.$el.append(this.template(this.getTemplateData()));

		this.subview('clientsView', new ClientsView({collection: this.collection, region: 'listCol'}));
	}


});

