var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"title\">CUSTOM ATTRIBUTES</div>\n\n<div id=\"attributes-list\" class=\"md-list wrapper md-whiteframe-z0 clearfix\"></div>\n<a class=\"add-item btn-block mt10 md-btn md-flat md-flat-dk text-primary\">Add Custom Attribute</a>";
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}