/**
 * Created by soundview on 5/1/15.
 */
var brands = [{
		id: 'ANDERSEN',
		text: 'ANDERSEN',
		'PROD_LINES': [
			{id: '100-SERIES', text: '100-SERIES'},
			{id: '200-SERIES', text: '200-SERIES'},
			{id: '400-SERIES', text: '400-SERIES'},
			{id: 'A-SERIES', text: 'A-SERIES'},
			{id: 'E-SERIES', text: 'E-SERIES'}
		]
	}, {
		id: 'SIMONTON',
		text: 'SIMONTON',
		'PROD_LINES': [
			{id: 'DAYLIGHTMAX', text: 'DAYLIGHTMAX'},
			{id: 'MADEIRA', text: 'MADERIA'}
		]
	}, {

		id: 'MILGARD',
		text: 'MILGARD',
		'PROD_LINES': [
			{id: 'ESSENCE', text: 'ESSENCE'},
			{id: 'TUSCANY', text: 'TUSCANY'},
			{id: 'MONTECITO', text: 'MONTECITO'},
			{id: 'STYLELINE VINYL', text: 'STYLELINE VINYL'},
			{id: 'STYLELINE VINYL - TILT', text: 'STYLELINE VINYL - TILT'},
			{id: 'THERMAL BREAK ALUMINUM', text: 'THERMAL BREAK ALUMINUM'},
			{id: 'CLASSIC VINYL', text: 'CLASSIC VINYL'},
			{id: 'ULTRA', text: 'ULTRA'},
			{id: 'WOODCLAD', text: 'WOODCLAD'},
			{id: 'QUIET LINE', text: 'QUIET LINE'},
			{id: 'ALUMINIUM', text: 'ALUMINIUM'},
			{id: 'STANDARD ALUMINUM', text: 'STANDARD ALUMINUM'},
			{id: 'SIMPLICITY', text: 'SIMPLICITY'}
		]
	},
	{
		id: 'CODEL',
		text: 'CODEL',
		'PROD_LINES': [
			{id: 'SMOOTH FIBERGLASS', text: 'SMOOTH FIBERGLASS'},
			{id: 'TEXTURED FIBERGLASS', text: 'TEXTURED FIBERGLASS'},
			{id: 'STEEL COLLECTION', text: 'STEEL COLLECTION'},
			{id: 'DECORATIVE COLLECTION', text: 'DECORATIVE COLLECTION'},
			{id: '90-MINUTE COLLECTION', text: '90-MINUTE COLLECTION'},
			{id: 'CRAFTSMAN COLLECTION', text: 'CRAFTSMAN COLLECTION'},
			{id: 'PATIO COLLECTION', text: 'PATIO COLLECTION'},
			{id: 'RUSTIC COLLECTION', text: 'RUSTIC COLLECTION'}
		]
	},
	{
		id: 'THERMATRU',
		text: 'THERMATRU',
		'PROD_LINES': [
			{id: 'FIBERGLASS', text: 'FIBERGLASS'},
			{id: 'STEEL', text: 'STEEL'},
			{id: 'FRENCH/HINGED', text: 'FRENCH/HINGED'},
			{id: 'SLIDING PATIO DOOR', text: 'SLIDING PATIO DOOR'},
			{id: 'FIRE-RATED', text: 'FIRE-RATED'}
		]
	},
	{
		id: 'LINDAL',
		text: 'LINDAL',
		'PROD_LINES': [
			{id: 'SIGNATURE CEDAR-CLAD ALUMINUM', text: 'SIGNATURE CEDAR-CLAD ALUMINUM'},
			{id: 'SOLID-WOOD', text: 'SOLID-WOOD'},
			{id: 'LINDAL', text: 'LINDAL'}
		]
	},
	{
		id: 'SIMPSON',
		text: 'SIMPSON',
		PROD_LINES: [
			{id: 'SIMPSON', text: 'SIMPSON'},
			{id: 'NANTUCKET COLLECTION', text: 'NANTUCKET COLLECTION'},
			{id: 'MASTERMARK COLLECTION', text: 'MASTERMARK COLLECTION'},
			{id: 'ARTIST COLLECTION', text: 'ARTIST COLLECTION'},
			{id: 'CRAFTSMAN COLLECTION', text: 'CRAFTSMAN COLLECTION'},
			{id: 'BUNGALOW SERIES', text: 'BUNGALOW SERIES'},
			{id: 'BUILDERS ADVANTAGE SERIES', 	text:	'BUILDERS ADVANTAGE SERIES'},
			{id: 'SELECTS SERIES', text: 'SELECTS SERIES'},
			{id: 'TRADITIONAL EXTERIOR DOORS', text: 'TRADITIONAL EXTERIOR DOORS'},
			{id: 'CONTEMPORARY EXTERIOR DOORS', text: 'CONTEMPORARY EXTERIOR DOORS'},
			{id: 'EXTERIOR FRENCH & SASH DOORS', text: 'EXTERIOR FRENCH & SASH DOORS'},
			{id: 'INTERIOR PANEL DOORS', text: 'INTERIOR PANEL DOORS'},
			{id: 'BIFOLD DOORS', text: 'BIFOLD DOORS'},
			{id: 'INTERIOR FRENCH DOORS', text: 'INTERIOR FRENCH DOORS'},
			{id: 'REDI-PRIME DOORS', text: 'REDI-PRIME DOORS'},
			{id: 'INFINITY DOORS', text: 'INFINITY DOORS'},
			{id: 'BRAVO DOORS', text: 'BRAVO DOORS'},
			{id: 'OVATION DOORS', text: 'OVATION DOORS'},
			{id: 'ENCORE DOORS', text: 'ENCORE DOORS'},
			{id: 'FIRE-RATED DOORS', text: 'FIRE-RATED DOORS'}
		]
	}, {
		id: 'ROGUE VALLEY',
		text: 'ROGUE VALLEY',
		PROD_LINES: [
			{id: 'ROGUE VALLEY', text: 'ROGUE VALLEY'},
		]
	}, {
		id: 'PLY-GEM',
		text: 'PLY GEM',
		PROD_LINES: [
			{id: 'PLY GEM', text: 'PLY GEM'},
			{id: 'BUILDER SERIES', text: 'BUILDER SERIES'},
			{id: 'PRO SERIES', text: 'PRO SERIES'},
			{id: 'PREMIUM MIRA SERIES', text: 'PREMIUM MIRA SERIES'},
			{id: 'CONTRACTOR SERIES', text: 'CONTRACTOR SERIES'},
			{id: 'PREMIUM SERIES', text: 'PREMIUM SERIES'}
		]
	},
	{
		id: 'CDI',
		text: 'COMFORT DESIGN',
		PROD_LINES: [
			{id: 'COMFORT-SERIES', text: 'COMFORT SERIES'},
			{id: 'ARCHITECTURAL-SERIES', text: 'ARCHITECTURAL SERIES'}
		]
	},
	{
		id: 'OTHER',
		text: 'OTHER (SEE NOTES)',
		PROD_LINES: [
			{id: 'OTHER', text: 'OTHER (SEE NOTES)'}
		]
	}
];
var materials = [
	{
		id: 'VINYL',
		text: 'VINYL'
	}, {
		id: 'WOOD',
		text: 'WOOD'
	},
	{
		id: 'ALUMINUM',
		text: 'ALUMINUM'
	},
	{
		id: 'FIBERGLASS',
		text: 'FIBERGLASS'
	},
	{
		id: 'WOOD/CLAD',
		text: 'WOOD/CLAD'
	}
];
var styles = [
	{

		'id': 'AWNING',
		'text': 'AWNING',
		'CONFIGS': [
			{id: 'A2x', text: 'A2x'},
			{id: 'A2xB', text: 'A2xB'},
			{id: 'A2xO', text: 'A2xO'},
			{id: 'AB', text: 'AB'},
			{id: 'AB2x', text: 'AB2x'},
			{id: 'ABE', text: 'ABE'},
			{id: 'AFULL', text: 'AFULL'},
			{id: 'AO', text: 'AO'},
			{id: 'AO2x', text: 'AO2x'}
		]
	},
	{
		'id': 'CASEMENT',
		'text': 'CASEMENT',
		'CONFIGS': [
			{id: 'CB', text: 'CB'},
			{id: 'CL', text: 'CL'},
			{id: 'CO', text: 'CO'},
			{id: 'CPC', text: 'CPC'},
			{id: 'CPL', text: 'CPL'},
			{id: 'CR', text: 'CR'},
			{id: 'DC', text: 'DC'},
			{id: 'PCR', text: 'PCR'}
		]
	},
	{
		'id': 'DOUBLE-HUNG',
		'text': 'DOUBLE-HUNG',
		'CONFIGS': [
			{id: 'DH', text: 'DH'},
			{id: 'DH2x', text: 'DH2x'},
			{id: 'DH3x', text: 'DH3x'},
			{id: 'DHP', text: 'DHP'},
			{id: 'DHPDH', text: 'DHPDH'}
		]
	},
	{
		'id': 'HORIZONTAL-SLIDER',
		'text': 'HORIZONTAL-SLIDER',
		'CONFIGS': [
			{id: 'DH', text: 'DH'},
			{id: 'DH2x', text: 'DH2x'},
			{id: 'DH3x', text: 'DH3x'},
			{id: 'DHP', text: 'DHP'},
			{id: 'DHPDH', text: 'DHPDH'}
		]
	},
	{
		'id': 'PICTURE',
		'text': 'PICTURE',
		'CONFIGS': [
			{id: 'DH', text: 'DH'},
			{id: 'DH2x', text: 'DH2x'},
			{id: 'DH3x', text: 'DH3x'},
			{id: 'DHP', text: 'DHP'},
			{id: 'DHPDH', text: 'DHPDH'}
		]
	},
	{
		'id': 'SINGLE-HUNG',
		'text': 'SINGLE-HUNG',
		'CONFIGS': [
			{id: 'DH', text: 'DH'},
			{id: 'DH2x', text: 'DH2x'},
			{id: 'DH3x', text: 'DH3x'},
			{id: 'DHP', text: 'DHP'},
			{id: 'DHPDH', text: 'DHPDH'}
		]
	},
	{
		id: 'BOW & BAY',
		text: 'BOW & BAY'
	},{
		id: 'SPECIALTY',
		text: 'SPECIALTY'
	}, {
		id: 'GARDEN WINDOW',
		text: 'GARDEN WINDOW'
	},
	{
		id: 'SKYLIGHT',
		text: 'SKYLIGHT'
	},
	{
		id: 'PATIO DOOR',
		text: 'PATIO DOOR'
	}, {
		id: 'ENTRY DOOR',
		text: 'ENTRY DOOR'
	}, {
		id: 'FRENCH DOOR',
		text: 'FRENCH DOOR'
	}
];
var installMethods = [
	{
		id: 'POCKET',
		text: 'POCKET INSTALL',
		prices: [
			{
				material: 'WOOD',
				price: 415.00
			},
			{
				material: 'ALL',
				price: 370.00
			}
		],
		price: 500.00
	},
	{
		id: 'POCKET FILLER',
		text: 'POCKET FILLER',
		price: 485.00
	},
	{
		id: 'FRAME EXPANDER',
		text: 'FRAME EXPANDER',
		price: 59.00
	},
	{
		id: 'IGU',
		text: 'IGU',
		sqFt: true,
		material: true,
		prices: [
			{
				material: 'VINYL',
				base: 375.00,
				level2: 445.00,
				level3: 550.00
			}, {
				material: 'ALL',
				base: 437.00,
				level2: 495.00,
				level3: 600.00
			}
		]
	},
	{
		id: 'INSERT (INTERIOR)',
		text: 'INSERT (INTERIOR)',
		price: 405.00
	},
	{
		id: 'INSERT (EXTERIOR)',
		text: 'INSERT (EXTERIOR)',
		price: 450.00
	},
	{
		id: 'INSERT (MASONRY)',
		text: 'INSERT (MASONRY)',
		price: 478.00
	},
	{
		id: 'SIDING/LINER CUTBACK',
		text: 'SIDING/LINER CUTBACK',
		price: 492.00
	},
	{
		id: 'SIDING/LINER CUTBACK (MASONRY)',
		text: 'SIDING/LINER CUTBACK (MASONRY)',
		price: 525.00
	},
	{
		id: 'FULL FRAME',
		text: 'FULL FRAME',
		price: 795.00
	},
	{
		id: 'STORM WINDOW',
		text: 'STORM WINDOW',
		price: 295.00
	}
];

var installAddons = [{
	"val": "SQFT25 Upcharge",
	"label": "25 sq ft  - 40 ft2 upcharge (Above 8' elevation - custom quote)",
	"PRICE": 120.00,
	"DESC": ""
},
	{"val": "SQFT40 Upcharge", "label": "Over 40 ft2(Above 8' elevation - custom quote)", "PRICE": 250.00, "DESC": ""},
	{
		"val": "Bucking New Opening",
		"label": "Bucking New Opening (Frame in opening on concrete)",
		"PRICE": 245.00,
		"DESC": "(All windows must be installed on wood surface - per manufacturers warranty)"
	},
	{
		"val": "Vinyl Siding",
		"label": "Vinyl siding existing",
		"PRICE": 35.00,
		"DESC": "(SVWD will not be held responsible for chipping, cracking) (Customer will need to source replacement siding) (White J Channel will be used only)"
	},
	{
		"val": "Aluminum Siding",
		"label": "Aluminum siding existing",
		"PRICE": 42.50,
		"DESC": "(SVWD will not be held responsible for chipping, cracking)(Customer will need to source replacement siding)(White vinyl J Channel will be used only)"
	},
	{
		"val": "Aluminum Coil Wrap",
		"label": "Aluminum coil wrap existing",
		"PRICE": 15.00,
		"DESC": "(Removal of coil stock)"
	},
	{
		"val": "Miltiple Layer Siding",
		"label": "Multiple layers of siding existing",
		"PRICE": 125.00,
		"DESC": "(Cut out multiple layers and fir out the sub-sheathing, must put filler piece to the interior)"
	},
	{"val": "Wrap Corner Post", "label": "wrap corner post", "PRICE": 385.00, "DESC": ""},
	{
		"val": "New Header",
		"label": "New Header (8' or under)",
		"PRICE": 1400.00,
		"DESC": "(New sheet rock to interior, takes full day, must let customer know that the drywall will be cut beyond the new opening)"
	},
	{"val": "New Head Over 8ft", "label": "New Header (over 8')", "PRICE": null, "DESC": ""},
	{"val": "Drop Sill", "label": "Drop Sill", "PRICE": 412.50, "DESC": ""},
	{"val": "Reroute Vent Pipe", "label": "Reroute vent pipe", "PRICE": 615.00, "DESC": ""},
	{
		"val": "Security Bar Removal",
		"label": "Security Bar Removal (We do not re-install)",
		"PRICE": 175.00,
		"DESC": ""
	},
	{
		"val": "Pre delivery of trim",
		"label": "Pre delivery of trim (will deliver 75% of expected trim)",
		"PRICE": 250.00,
		"DESC": ""
	},
	{"val": "Field Mulling", "label": "Field Mulling", "PRICE": 225.00, "DESC": ""}];


var options = {
	brands: brands,
	materials: materials,
	styles: styles,
	installMethods: installMethods,
	installAddons: installAddons
}


module.exports = options;