var View = require('views/base/collection-view');
var Model = require('models/insulation-quote');
var Collection = require('models/insulation-quote-collection');
var ItemView = require('views/insulation/insulation-view');
var BaseModel = require('models/base/model');

var QuoteIndex = View.extend({
	autoRender: true,
	className: 'quote-list',
	listSelector: '#quoteList',
	itemView: ItemView,
	noWrap: true,
	currentStatus: 'all',
	template: require('./templates/insulation-index'),
	events: {
		'click .next-page': 'nextPage',
		'click .prev-page': 'prevPage',
		'click .status_nav': 'filterStatus',
		'click .back_btn': '_backPath'
		//'scroll': 'checkScroll'
	},
	listen: {
		'reset collection': 'toggleActive'
	},
	statuses: {
		0: {label: 'All', name: 'all', status_id: 0},
		1: {label: 'Draft', name: 'draft', status_id: 1},
		2: {label: 'Sent', name: 'sent', status_id: 2},
		3: {name: 'approved', status_id: '3', label: 'Approved'},
		4: {name: 'rejected', status_id: '4', label: 'Rejected'},
		5: {name: 'canceled', status_id: '5', label: 'Canceled'}
	},
	initialize: function (options) {
		this.collection = new Collection();

		this.collection.fetch({reset:true});


		this.listenTo(this.collection, 'toView', this.toView);
		//this.collection.models = this.clients.models;

		//this.clients.fetch({reset:true});

		//this.listenTo(this.collection, "reset", this.togglePager);
		QuoteIndex.__super__.initialize.call(this, options);
	},
	nextPage: function () {
		if (this.collection.hasNextPage()) {
			this.collection.getNextPage();
		}
	},
	prevPage: function () {
		if (this.collection.hasPreviousPage()) {
			this.collection.getPreviousPage();
		}
	},
	filterStatus: function(e) {
		var status, $target;

		$target = $(e.currentTarget);
		status = $target.data('status');

		this.collection.queryParams.status = status;
		this.currentStatus = status;
		this.collection.fetch({reset: true});

	},
	toView: function(model) {
		Chaplin.utils.redirectTo('insulation#show', {quoteId: model.attributes.id});
	},
	_backPath: function() {
		window.history.back();
	},
	toggleActive: function() {
		var _this = this;
		var currentStatus = this.currentStatus;
		var navs = this.$el.find('.status_nav');
		navs.each(function(){
			console.log(currentStatus);
				console.log($(this).data('status'));
				console.log($(this).data('status') == currentStatus);
				$(this).data('status') == currentStatus ? $(this).addClass('active') : $(this).removeClass('active');
				//console.log($(this).data('status'));

		});
		//console.log(navs);
	},
	togglePager: function () {
		//var _this = this;
		//	this.$el.find('.next-page').toggleClass('disabled', this.collection.hasNextPage());
		//	this.$el.find('.prev-page').toggleClass('disabled', this.collection.hasPreviousPage());

	}
});

module.exports = QuoteIndex;
