/**
 * Created by soundview on 3/9/15.
 */
var sizeTemplate = _.template('\
	<div>\
    <div  class="form-group floating-label field-<%= key %>">\
	<div class="input-group">\
		<div data-editor class="input-group-content">\
	        <label class="control-label" for="<%= editorId %>">\
				<% if (titleHTML){ %><%= titleHTML %>\
				<% } else { %><%- title %><% } %>\
			</label>\
		</div>\
		<span class="input-group-addon">in.</span>\
	    <p class="help-block" data-error></p>\
	    <p class="help-block"><%= help %></p>\
	    </div>\
    </div>\
    </div>\
  ');
var moneyTemplate = _.template('\
	<div>\
    <div data-editor class="form-group floating-label append-icon field-<%= key %>">\
	        <label class="control-label" for="<%= editorId %>">\
				<% if (titleHTML){ %><%= titleHTML %>\
				<% } else { %><%- title %><% } %>\
			</label>\
			<i class="fa fa-usd fa-lg"></i>\
	    <p class="help-block" data-error></p>\
	    <p class="help-block"><%= help %></p>\
    </div>\
    </div>\
  ');
var percentTemplate = _.template('\
	<div>\
    <div  class="form-group floating-label field-<%= key %>">\
	<div class="input-group">\
		<div data-editor class="input-group-content">\
	        <label class="control-label" for="<%= editorId %>">\
				<% if (titleHTML){ %><%= titleHTML %>\
				<% } else { %><%- title %><% } %>\
			</label>\
		</div>\
		<span class="input-group-addon">%</span>\
	    <p class="help-block" data-error></p>\
	    <p class="help-block"><%= help %></p>\
	    </div>\
    </div>\
    </div>\
  ');
var configOptions = require('./options-config');
var Model = require('models/base/model');
module.exports = Model.extend({
	installMethods: configOptions.installMethods,
	urlRoot: '/index.php/api/v1/w_items',
	url: function () {
		var url;
		if(this.id) {
			url = this.urlRoot + '/' + this.id;
		} else {
			url = this.urlRoot;
		}
		return url;
	},
	initialize: function() {

		this.on('change:id', function(){
			return Backbone.trigger('quoteitem:created', this);
		});

		this.on('change:width', function(){
			this.squareFt = (this.get('width') * this.get('height') /144);
			var i = this.getInstallPrice(this.get('install_method'));
			var a = this.get('install_addons_price');
			//this.set('install_addons_price', a);
			this.set('msrp_install_price', parseFloat(i) + parseFloat(a));
		});
		this.on('change:height', function () {
			this.squareFt = (this.get('width') * this.get('height') / 144);
			var i = this.getInstallPrice(this.get('install_method'));
			var a = this.get('install_addons_price');
			//this.set('install_addons_price', a);
			this.set('msrp_install_price', parseFloat(i) + parseFloat(a));
		});
		this.on('change:material', function() {
			var i = this.getInstallPrice(this.get('install_method'));
			var a = this.get('install_addons_price');
			//this.set('install_addons_price', a);
			this.set('msrp_install_price', parseFloat(i) + parseFloat(a));
		});
		this.on('change:install_method', function(val){
			var i = this.getInstallPrice(this.get('install_method'));
			var a = this.get('install_addons_price');
			//this.set('install_addons_price', a);
			this.set('msrp_install_price', parseFloat(i) + parseFloat(a));
		});
		this.on('change:install_addons_price', function(){
			var i = this.getInstallPrice(this.get('install_method'));
			var a = this.get('install_addons_price');
			//this.set('install_addons_price', a);
			this.set('msrp_install_price', parseFloat(i) + parseFloat(a));
		});


		//console.log(this.installMethods);

		this.squareFt = (this.get('width') * this.get('height') / 144);
		Backbone.Form.validators.errMessages.required = '<i class="fa fa-exclamation-circle"></i> Required';
	},
	schema: {
		name: {
			title: 'Name',
			type: 'Text',
			validators: ['required'],
			editorClass: 'static',
			fieldClass: 'col-xs-8 col-sm-8 col-md-8'
		},
		image: {
			title: 'Image',
			type: 'Text',
			editorClass: 'input__image static dirty'
		},
		list_price: {
			title: 'List Price',
			type: 'Number',
			validators: ['required'],
			fieldClass: 'col-sm-6',
			editorClass: 'static',
			template: moneyTemplate
		},
		on_factor: {title: 'On Factor (%, i.e .315 = 31.5)', type: 'Number', fieldClass: 'col-sm-3', template: percentTemplate,
			editorClass: 'static'},
		mark_up: {
			title: 'Mark Up (%, i.e .25 = 25)', type: 'Number', fieldClass: 'col-sm-3', editorClass: 'input__markup static', template: percentTemplate,
			validators: [
				function(value, formValues){
					var err = {
						type: 'username',
						message: 'Usernames must be at least 3 characters long'
					};
					//if(formValues.install_method == 'IGU' && value < 50) return err;
					if(formValues.install_method == 'IGU'){
						if(value < 50) {
							var err = {
								type: 'required',
								message: 'Min 50% Markup on IGU'
							};
							return err;
						}
					}
					console.log(value);
					console.log(formValues);
				}
			]},
		width: {
			title: 'Width (in.)',
			type: 'Number',
			fieldClass: 'col-sm-4 col-md-4 col-xs-6 x-field-num',
			template: sizeTemplate,
			editorClass: 'input__width static'
		},
		height: {
			title: 'Height (in.)',
			type:'Number',
			fieldClass: 'col-sm-4 col-md-4 col-xs-6 x-field-num',
			template: sizeTemplate,
			editorClass: 'input__height static'
		},
		leg_height: {
			title: 'Short Leg Height (in.)',
			type: 'Number',
			fieldClass: 'col-sm-4 col-md-4 col-xs-6 x-field-num',
			template: sizeTemplate,
			editorClass: 'input__leg_height static'
		},
		brand: {
			title: 'Brand',
			type: 'Select',
			validators: ['required'],
			fieldClass: 'col-xs-8 col-sm-8 col-md-4',
			editorClass: 'static input__brand',
			options: [{}]
		},
		product_line: {
			title: 'Product Line',
			type: 'Select',
			validators: ['required'],
			fieldClass: 'col-xs-8 col-sm-8 col-md-4',
			editorClass: 'static input__product_line',
			options: [{}]
		},
		material: {
			title: 'Material',
			type: 'Text',
			validators: ['required'],
			fieldClass: 'col-xs-8 col-sm-8 col-md-4',
			editorClass: 'static input__material',
			options: [{}]
		},
		style: {
			title: 'Style',
			type: 'Text',
			validators: ['required'],
			fieldClass: 'col-xs-8 col-sm-8 col-md-6',
			editorClass: 'input__style static'
		},
		operation: {
			title: 'Config',
			type: 'Text',
			validators: ['required'],
			editorClass: 'input__operation static',
			fieldClass: 'col-xs-8 col-sm-8 col-md-6'},

		glass: {
			title: 'Glass',
			type: 'Text',
			validators: ['required'],
			editorClass: 'static',
			fieldClass: 'col-xs-8 col-sm-8 col-md-4'},
		obscure_glass: {
			title: 'Obscure Glass',
			type: 'Text',
			validators: ['required'],
			editorClass: 'static',
			fieldClass: 'col-xs-8 col-sm-8 col-md-4'},
		grid_pattern: {
			title: 'Grid Pattern',
			type: 'Text',
			validators: ['required'],
			editorClass: 'static',
			fieldClass: 'col-xs-8 col-sm-8 col-md-4'},
		exterior_finish: {
			title: 'Exterior Finish',
			type: 'Text',

			validators: ['required'],
			editorClass: 'static'},
		interior_finish: {
			title: 'Interior Finish',
			type: 'Text',
			validators: ['required'],
			editorClass: 'static'},
		hardware_type: {
			title: 'Hardware Type',
			type: 'Text',
			validators: ['required'],
			editorClass: 'static'
		},
		hardware_finish: {
			title: 'Hardware Finish',
			type: 'Text',
			validators: ['required']},
		interior_trim: {
			title: 'Interior Trim',
			type: 'Text',
			validators: ['required'],
			editorClass: 'static'},
		exterior_trim: {
			title: 'Exterior Trim',
			type: 'Text',
			validators: ['required'],
			editorClass: 'static'
		},
		install_addons: {
			title: 'Install Addons',
			type: 'Checkboxes',
			editorClass: 'input__install_addons static',
			fieldClass: 'block clearfix',
			options: configOptions.installAddons
		},
		'install_method': {title: 'Install Method', type: 'Select', fieldClass:'col-sm-6', editorClass: 'static input__install_method', options: [{}]},
		'install_price': { title: 'Price', template: moneyTemplate, type: 'Number',
			fieldClass: 'col-sm-3',
			editorClass: 'static input__install_price'},
		'msrp_install_price': {
			title: 'Suggested Price', template: moneyTemplate, type: 'Text',
			fieldClass: 'col-sm-3',
			editorAttrs: {'readonly': ''},
			editorClass: 'static input__msrp_install_price'
		},
		'install_addons_price': {
			title: 'Addons Price',  type: 'Number',
			fieldClass: 'col-sm-3',
			editorAttrs: {'readonly': ''},
			editorClass: 'static input__install_addons_price'
		},


		//service: { title: 'Service', type: 'Select', editorClass: 'service', fieldClass:'col-sm-6', options: [{ val: null, label: null},{val: 'glass', label: 'Glass Replacement'},{val: 'skylight', label: 'Skylight'},{val: 'board_up', label: 'Board Up'}] },
		//service_type: { title: 'Service Type', type: 'Select', editorClass: 'service_type', fieldClass: 'col-sm-6', options: [{val:null, label: null}]},
		elevation: { fieldClass: 'col-md-4 col-sm-4 col-xs-4', title: 'Elevation', type: 'Select', editorClass: 'static input__elevation', options: [{val: null, label: null},{val: 'first', label: 'First'}, {val: 'second', label: 'Second'}, {val: 'third', label: 'Third'}]},
		description: { title: 'Notes', type: 'TextArea', fieldClass: 'col-sm-12', editorClass: 'static', editorAttrs: { resize: 'vertical', rows: '5'}}
	},
	//schema: {
	//	name: {
	//		title: 'Name',
	//		type: 'Text',
	//		validators: ['required'],
	//		editorClass: 'static',
	//		//fieldClass: 'col-xs-8 col-sm-8 col-md-8'
	//	},
	//	image: {
	//		title: 'Image',
	//		type: 'Text',
	//		editorClass: 'input__image static dirty'
	//	},
	//	list_price: {
	//		titleHTML: 'List Price',
	//		type: 'Number',
	//		validators: ['required'],
	//		//fieldClass: 'col-sm-6',
	//		editorClass: 'static',
	//		template: moneyTemplate
	//
	//	},
	//	on_factor: {
	//		title: 'On Factor (%, i.e .315 = 31.5)', type: 'Number',
	//		//fieldClass: 'col-sm-3',
	//		template: percentTemplate,
	//		editorClass: 'static'
	//	},
	//	mark_up: {
	//		title: 'Mark Up (%, i.e .25 = 25)',
	//		type: 'Number',
	//		//fieldClass: 'col-sm-3',
	//		editorClass: 'input__markup static',
	//		template: percentTemplate,
	//		validators: [
	//			function (value, formValues) {
	//				var err = {
	//					type: 'username',
	//					message: 'Usernames must be at least 3 characters long'
	//				};
	//				//if(formValues.install_method == 'IGU' && value < 50) return err;
	//				if (formValues.install_method == 'IGU') {
	//					if (value < 50) {
	//						var err = {
	//							type: 'required',
	//							message: 'Min 50% Markup on IGU'
	//						};
	//						return err;
	//					}
	//				}
	//				console.log(value);
	//				console.log(formValues);
	//			}
	//		]
	//	},
	//	width: {
	//		title: 'Width (in.)',
	//		type: 'Number',
	//		//fieldClass: 'col-sm-4 col-md-4 col-xs-6 x-field-num',
	//		template: sizeTemplate,
	//		editorClass: 'input__width static'
	//	},
	//	height: {
	//		title: 'Height (in.)',
	//		type: 'Number',
	//		//fieldClass: 'col-sm-4 col-md-4 col-xs-6 x-field-num',
	//		template: sizeTemplate,
	//		editorClass: 'input__height static'
	//	},
	//	leg_height: {
	//		title: 'Short Leg Height (in.)',
	//		type: 'Number',
	//		//fieldClass: 'col-sm-4 col-md-4 col-xs-6 x-field-num',
	//		template: sizeTemplate,
	//		editorClass: 'input__leg_height static'
	//	},
	//	brand: {
	//		title: 'Brand',
	//		type: 'Select',
	//		validators: ['required'],
	//		//fieldClass: 'col-xs-8 col-sm-8 col-md-4',
	//		editorClass: 'static input__brand',
	//		options: [{}]
	//	},
	//	product_line: {
	//		title: 'Product Line',
	//		type: 'Select',
	//		validators: ['required'],
	//		//fieldClass: 'col-xs-8 col-sm-8 col-md-4',
	//		editorClass: 'static input__product_line',
	//		options: [{}]
	//	},
	//	material: {
	//		title: 'Material',
	//		type: 'Text',
	//		validators: ['required'],
	//		//fieldClass: 'col-xs-8 col-sm-8 col-md-4',
	//		editorClass: 'static input__material',
	//		options: [{}]
	//	},
	//	style: {
	//		title: 'Style',
	//		type: 'Text',
	//		validators: ['required'],
	//		//fieldClass: 'col-xs-8 col-sm-8 col-md-6',
	//		editorClass: 'input__style static'
	//	},
	//	operation: {
	//		title: 'Config',
	//		type: 'Text',
	//		validators: ['required'],
	//		editorClass: 'input__operation static',
	//		//fieldClass: 'col-xs-8 col-sm-8 col-md-6'
	//	},
	//
	//	glass: {
	//		title: 'Glass',
	//		type: 'Text',
	//		validators: ['required'],
	//		editorClass: 'static',
	//		//fieldClass: 'col-xs-8 col-sm-8 col-md-4'
	//	},
	//	obscure_glass: {
	//		title: 'Obscure Glass',
	//		type: 'Text',
	//		validators: ['required'],
	//		editorClass: 'static',
	//		//fieldClass: 'col-xs-8 col-sm-8 col-md-4'
	//	},
	//	grid_pattern: {
	//		title: 'Grid Pattern',
	//		type: 'Text',
	//		validators: ['required'],
	//		editorClass: 'static',
	//		//fieldClass: 'col-xs-8 col-sm-8 col-md-4'
	//	},
	//	exterior_finish: {
	//		title: 'Exterior Finish',
	//		type: 'Text',
	//
	//		validators: ['required'],
	//		editorClass: 'static'
	//	},
	//	interior_finish: {
	//		title: 'Interior Finish',
	//		type: 'Text',
	//		validators: ['required'],
	//		editorClass: 'static'
	//	},
	//	hardware_type: {
	//		title: 'Hardware Type',
	//		type: 'Text',
	//		validators: ['required'],
	//		editorClass: 'static'
	//	},
	//	hardware_finish: {
	//		title: 'Hardware Finish',
	//		type: 'Text',
	//		validators: ['required']
	//	},
	//	interior_trim: {
	//		title: 'Interior Trim',
	//		type: 'Text',
	//		validators: ['required'],
	//		editorClass: 'static'
	//	},
	//	exterior_trim: {
	//		title: 'Exterior Trim',
	//		type: 'Text',
	//		validators: ['required'],
	//		editorClass: 'static'
	//	},
	//	install_addons: {
	//		title: 'Install Addons',
	//		type: 'Checkboxes',
	//		editorClass: 'input__install_addons static',
	//		//fieldClass: 'block clearfix',
	//		options: configOptions.installAddons
	//	},
	//	'install_method': {
	//		title: 'Install Method',
	//		type: 'Select',
	//		//fieldClass: 'col-sm-6',
	//		editorClass: 'static input__install_method',
	//		options: [{}]
	//	},
	//	'install_price': {
	//		title: 'Price', template: moneyTemplate, type: 'Number',
	//		//fieldClass: 'col-sm-3',
	//		editorClass: 'static input__install_price'
	//	},
	//	'msrp_install_price': {
	//		title: 'Suggested Price', template: moneyTemplate, type: 'Text',
	//		//fieldClass: 'col-sm-3',
	//		editorAttrs: {'readonly': ''},
	//		editorClass: 'static input__msrp_install_price'
	//	},
	//	'install_addons_price': {
	//		title: 'Addons Price', type: 'Number',
	//		editorAttrs: {'readonly': ''},
	//		editorClass: 'static input__install_addons_price'
	//	},
	//
	//
	//	//service: { title: 'Service', type: 'Select', editorClass: 'service', fieldClass:'col-sm-6', options: [{ val: null, label: null},{val: 'glass', label: 'Glass Replacement'},{val: 'skylight', label: 'Skylight'},{val: 'board_up', label: 'Board Up'}] },
	//	//service_type: { title: 'Service Type', type: 'Select', editorClass: 'service_type', fieldClass: 'col-sm-6', options: [{val:null, label: null}]},
	//	elevation: {
	//		title: 'Elevation',
	//		type: 'Select',
	//		editorClass: 'static input__elevation',
	//		options: [{val: null, label: null}, {val: 'first', label: 'First'}, {
	//			val: 'second',
	//			label: 'Second'
	//		}, {val: 'third', label: 'Third'}]
	//	},
	//	description: {
	//		title: 'Notes',
	//		type: 'TextArea',
	//		editorClass: 'static',
	//		editorAttrs: {resize: 'vertical', rows: '5'}
	//	}
	//},
	defaults: {
		tax_rate_id: 0,
		quantity: 1,
		display_order: 1,
		description: '',
		install_addons_price: 0,
		msrp_install_price: parseFloat(0.00)
	},
	services: [
		{
		id: 1,
		val: 'glass',
		label: 'Glass Replacement',
		selected: false,
		children: [
			{
				val: 'g_glass_only',
				label: 'Glass Only'
			}, {
				val: 'g_vinyl',
				label: 'Vinyl'
			}, {
				val: 'g_wood',
				label: 'Wood'
			}, {
				val: 'g_aluminium',
				label: 'Aluminium'
			}
		]
	},
		{
		id: 2,
		val: 'skylight',
		label: 'Skylight Replacement',
		selected: false,
		children: [
			{
				val: 'sl_vinyl',
				label: 'Vinyl'
			}, {
				val: 'sl_wood',
				label: 'Wood'
			}, {
				val: 'sl_aluminium',
				label: 'Aluminium'
			}
		]

	},
		{
		id: 3,
		val: 'board_up',
		label: 'Board Up',
		selected: false,
		children: [
			{
				val: 'bu_vinyl',
				label: 'Vinyl'
			}, {
				val: 'bu_wood',
				label: 'Wood'
			}, {
				val: 'bu_aluminium',
				label: 'Aluminium'
			}
		]
	}],
	squareFt: 0,
	getInstallPrice: function (installId) {
		var material = this.get('material');
		if(!installId) {
			return 0.00;
		}
		var _this = this;

		var methodList = _.find(this.installMethods, function (item) {
			return item.id === installId;
			//console.log(item);
		});
		//console.log(methodList);
		var mPrices;
		switch(methodList.id) {
			case 'IGU':
				var mat = material == 'VINYL' ? 'VINYL' : 'ALL';
				mPrices = _.find(methodList.prices, function (item) {
					return item.material === mat;
					//console.log(item);
				});
				if (this.squareFt <= 10) {
					return parseFloat(mPrices.base);
				} else if (this.squareFt > 10 <= 25) {
					return parseFloat(mPrices.level2);
				} else if (this.squareFt > 25) {
					return parseFloat(mPrices.level3);
				} else {
					return 0;
				}
				break;
			case 'POCKET':
				var mat = material == 'WOOD' ? 'WOOD' : 'ALL';
				mPrices = _.find(methodList.prices, function (item) {
					return item.material === mat;
					//console.log(item);
				});
				return parseFloat(mPrices.price);
				break;
			default: return parseFloat(methodList.price);

		}
		//if (methodList.id == 'IGU') {
		//	mPrices = _.find(methodList.prices, function (item) {
		//		return item.material === material;
		//		//console.log(item);
		//	});
		//	console.log(mPrices);
		//	console.log(this.squareFt <= 10);
		//	console.log(this.squareFt > 10 <= 25);
		//
		//	if (this.squareFt <= 10) {
		//		return mPrices.base;
		//	} else if (this.squareFt > 10 <= 25) {
		//		return mPrices.level2;
		//	} else if (this.squareFt > 25) {
		//		return mPrices.level3;
		//	}
		//}
		//var state = _.find(this.services, {id: 2});

		//return brandList ? brandList.price : '';
	},
	installPrice: null,
	calcInstall: function() {
		var i = this.getInstallPrice(this.get('install_method'));
		var a = this.get('install_addons_price');

		return (parseFloat(i) + parseFloat(a));
	},
	formatSize: function (size) {
		var inch, feet;
		feet = parseInt(size/12);
		inch = size % 12;

		return feet + "' " + inch + '"';
	},
	serialize: function () {
		var json = Chaplin.Model.prototype.serialize.apply(this, arguments);

		json.__proto__.cid = this.cid;
		return json;
	},
	validate: function (attrs) {
		var errs = {};

		if (attrs.install_method == 'IGU' && attrs.mark_up > 50) errs.mark_up = 'Minimumn Markup for IGU 50%';

		if(!_.isEmpty(errs)) return errs;
	}
});