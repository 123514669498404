/**
 * Created by soundview on 4/13/15.
 */
var View = require('views/base/view');
var Model = require('../model');
var layout = require('lib/layout');
var FormView = require('views/templates/bootstrap3');
var select2Focus = function () {
	var select2 = $(this).data('select2');
	setTimeout(function () {
		if (!select2.opened()) {
			select2.open();
		}
	}, 0);
};
module.exports = View.extend({
	autoRender: true,
	className: 'vbox',
	region: 'main',
	template: require('./templates/create'),
	events: {
		'click .parseForm': 'submit'
	},
	bindings: {
		'.created_at': {
			observe: 'created_at',
			initialize: function($el) {
				$el.datepicker({
					prevText: '<i class="fa fa-angle-left"></i>',
					nextText: '<i class="fa fa-angle-right"></i>'
				});
			}
		},
		'.client': {
			observe: 'client_name',
			events: ['blur'],
			initialize: function($el) {
				$el.typeahead(null, {
					minLength: 3,
					source: this.clients.ttAdapter()
				}).val(this.clientName);
			},
			onGet: function(val) {
				console.log(val);
			},
			onSet: function(val) {
				this.model.set('client_name', val);
			}
		},
		'.invoice_group_id': {
			observe: 'invoice_group_id',
			initialize: function ($el) {
				$el.select2({
					width: "100%",
					placeholder: 'Select Group...', allowClear: true,
					minimumResultsForSearch: Infinity
				}).one('select2-focus', select2Focus).on("select2-blur", function () {
					$(this).one('select2-focus', select2Focus)
				});
			},
			selectOptions: {
				collection: function () {
					return this.invoiceGroups;
				},
				valuePath: 'id',
				labelPath: 'text',
				defaultOption: {
					label: null,
					value: null
				}
			},
			onSet: function (val) {
				this.model.set('invoice_group_id', val);
				return val;
			}
		}

	//	'.client': {
	//		observe: 'client_name',
	//		initialize: function ($el) {
	//			$el.select2({
	//				width: "100%",
	//				placeholder: 'Select Client...',
	//				allowClear: true,
	//				ajax: {
	//					url: "/index.php/clients/ajax/name_lookup",
	//					dataType: 'json',
	//					delay: 250,
	//					data: function (params) {
	//						return {
	//							query: params.term // search term
	//						};
	//					},
	//					processResults: function (data) {
	//						// parse the results into the format expected by Select2.
	//						// since we are using custom formatting functions we do not need to
	//						// alter the remote JSON data
	//						return {
	//							results: data
	//						};
	//					},
	//					cache: true
	//				},
	//				escapeMarkup: function (markup) {
	//					return markup;
	//				},
	//				minimumInputLength: 1,
	//				templateResult: function(data){
	//					if (data.loading) return data.text;
	//					var markup = "<div class='clearfix'>"+data.text+"</div>";
	//					return markup;
	//				},
	//				templateSelection: function(data){
	//					return data.text;
	//				}
	//			});
	//		},
	//		selectOptions: {
	//			collection: function () {
	//				return [];
	//				//return this.services;
	//			},
	//			valuePath: 'text',
	//			labelPath: 'text'
	//			//defaultOption: {
	//			//	label: 'Select Client..',
	//			//	value: null
	//			//}
	//		},
	//		onSet: function (val) {
	//			console.log(val);
	//		}
	//	}
	},
	initialize: function(options){
		this.clientName = options.clientName;

		this.invoiceGroups = [{
			id: 4,
			text: 'Sound View Seattle',
		}, {
			id: 5,
			text: 'Sound View Glass'
			}];

		this.clients = new window.Bloodhound({
			datumTokenizer: function (d) {
				return Bloodhound.tokenizers.whitespace(d.num);
			},
			queryTokenizer: Bloodhound.tokenizers.whitespace,
			remote: '/index.php/clients/ajax/name_lookup?query=%QUERY'});

		this.clients.initialize();

		this.model = new Model();

		this.model.set('client_name', this.clientName);

		console.log(this.model);
	},
	parseForm: function() {
		var form = this.$el.find('form').serializeArray();

		var data2 = {};
		_.each(form, function (val) {
			data2 = val.value;
			//console.log(val.name+': '+val.value);
		});

		console.log(form);
	},
	submit: function() {
		console.log(this.model.isNew());
		var error = this.form.validate();
		if (!error) this.save();
	},
	save: function() {
		var data = this.form.getValue();

		console.log(data);
		//this.model.set(data);
		//this.model.on('save', this.onSave());
		//this.model.save(data, {patch: true});

		this.model.save(data, {
			wait: true,
			patch: true,
			success: _.bind(function (resp) {
				//console.log(resp);
				Chaplin.utils.redirectTo('window#show', {quoteId: resp.id});
				//this.model.collection.trigger('updated', this.model);
			}, this)
		});
	},
	render: function() {
		this.$el.append(this.template(this.getTemplateData()));

		this.form = new Backbone.Form({model: this.model}).render();
		this.$el.find('.quoteForm').append(this.form.el);
		this.stickit();
		this.$el.find('.twitter-typeahead').addClass('static dirty');
		layout.init(this.$el);

	}
});