/**
 * Created by soundview on 8/13/15.
 */
/**
 * Created by soundview on 8/13/15.
 */
var View = require('views/base/view');


module.exports = View.extend({
	autoRender: true,
	tagName: 'tr',
	className: '',
	container: '#recent-quotes-container',
	template: require('./templates/recent-quote'),
});