/**
 * Created by soundview on 3/9/15.
 */
var View = require('views/base/view');
var Model = require('models/quote');
var DateTimeFormatter = require('lib/formatter/datetime');


module.exports = View.extend({
	autoRender: true,
	container: '#info-panel',
	//container: '#info-panel',
	tag: 'div',
	className: 'vbox wrapper',
	//region: 'info',
	template: require('./templates/quote-info'),
	statuses: [{
		value: 1,
		label: 'Draft'
	}, {
		value: 2, label: 'Sent'
	},
		{value: 3, label: 'Approved'},
		{value: 4, label: 'Rejected'},
		{value: 5, label: 'Canceled'}
	],
	bindings: {
		'#created_at': {
			observe: 'created_at',
			events: ['blur'],
			initialize: function($el){
				$el.datepicker({
					prevText: '<i class="fa fa-angle-left"></i>',
					nextText: '<i class="fa fa-angle-right"></i>'
				});
			},
			onGet: function(val) {
				return DateTimeFormatter.formatDate(val);
			},
			onSet: function (val) {
				var d = DateTimeFormatter.convertDateToBackendFormat(val);
				this.model.set("created_at", d);
				this.saveAttr();
			},
			afterUpdate: function ($el) {
				//$el.prop('disabled', true);
			}
		},
		'#quote_status': {
			observe: 'quote_status_id',
			initialize: function ($el) {
				$el.select2({
					width: "100%", placeholder: 'Select Status...', allowClear: true,
					minimumResultsForSearch: Infinity
				});
			},
			selectOptions: {
				collection: function () {
					return this.statuses;
				},
				valuePath: 'value',
				labelPath: 'label'
				//defaultOption: {
				//	label: 'Select Service..',
				//	value: null
				//}
			},
			onSet: function (val) {
				this.model.set('quote_status_id', val);
				this.saveAttr();
				return val;
			}
		},
		'#expires_at': {
			observe: 'expires_at',
			events: ['blur'],
			initialize: function ($el) {
				$el.datepicker({
					prevText: '<i class="fa fa-angle-left"></i>',
					nextText: '<i class="fa fa-angle-right"></i>'
				});
			},
			onGet: function (val) {
				return DateTimeFormatter.formatDate(val);
			},
			onSet: function (val, options) {
				var d = DateTimeFormatter.convertDateToBackendFormat(val);
				this.model.set("expires_at", d);
				this.saveAttr();
				//this.model.trigger('save', this.model);
			},
			afterUpdate: function ($el) {
				//$el.prop('disabled', true);
			}
		}
	},
	initialize: function (options) {
		this.model = options.model;
		//this.listenTo(this.model, 'change', this.reload);
		//this.model.fetch();

		//console.log(this.model.attributes);
		//this.collection = options.collection || new Collection();
		//this.collection.url = '/index.php/api/clients';

		//this._initMainContainers();
		//this.listenTo(this.getCollection(), 'reset', this.renderClientList);

		//this.getCollection().fetch({reset: true});
		//this.listenTo(this.model, 'change:amount', this.reload);

	},
	initComponents: function() {

	},
	reload: function() {
		this.model.fetch({
			wait: true,
			success: _.bind(function () {
				console.log(this.model);
				var self = this;
				_.delay(function () {
					self.render();
				}, 800);
			}, this)
		});

		console.log('reload info-view');
		this.render();
	},
	saveAttr: function () {
		//console.log(val);
		if (!_.isEmpty(this.model.changed)) {
			this.model.save(this.model.changed, {
				patch: true,
				success: function(resp) {
					console.log(resp);
				}
			});
		}
		//console.log(this.model.attributes);
	},
	render: function(){


		//var data = this.model.toJSON();
		////console.log(data);
		//
		//console.log(DateTimeFormatter.unformatDate(this.model.get('expires_at')));
		//
		//this.$el.append(this.template(data));

		View.__super__.render.apply(this, arguments);
		this.$el.find('.dp').datepicker({
			prevText: '<i class="fa fa-angle-left"></i>',
			nextText: '<i class="fa fa-angle-right"></i>'
		});

		this.stickit();

		return this;
	}



});

