var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  
  return "-->\n		<!--<p class=\"secondary text-success\">Viewed</p>-->\n	<!--";
  }

function program3(depth0,data) {
  
  
  return "-->\n		<!--<p class=\"secondary text-default\">Not Viewed</p>-->\n	<!--";
  }

function program5(depth0,data) {
  
  
  return "\n		        <span class=\"secondary text-success\">Viewed</span>\n	        ";
  }

function program7(depth0,data) {
  
  
  return "\n		        <span class=\"secondary text-default\">Not Viewed</span>\n	        ";
  }

  buffer += "<!--<div class=\"md-list-item-text\">-->\n	<!--<h3 class=\"primary\">";
  if (helper = helpers.number) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.number); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</h3>-->\n	<!--<p class=\"secondary\">";
  if (helper = helpers.job_name) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.job_name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</p>-->\n<!--</div>-->\n<!--<div class=\"md-list-item-text\">-->\n	<!--<h3 class=\"primary\">";
  stack1 = ((stack1 = ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.name)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</h3>-->\n	<!--<p class=\"secondary\">";
  stack1 = ((stack1 = ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.email)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</p>-->\n<!--</div>-->\n<!--<div class=\"md-list-item-text\">-->\n	<!--<h3 class=\"primary\">";
  if (helper = helpers.status_label) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.status_label); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</h3>-->\n	<!--";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.viewed), {hash:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "-->\n<!--</div>-->\n<!--<div class=\"md-secondary-container\">-->\n	<!--<a href=\"#\" class=\"md-button dropdown-toggle\" data-toggle=\"dropdown\"><i-->\n			<!--class=\"mdi-navigation-more-vert i-20\"></i> </a>-->\n	<!--<ul class=\"dropdown-menu dropdown-menu-scale pull-up pull-right text-left\">-->\n		<!--<li><a href=\"#\" class=\"action_edit\" title=\"Edit\">Edit</a></li>-->\n		<!--<li><a href=\"#\" class=\"action_print_pdf\" title=\"Print PDF\">Print PDF</a></li>-->\n		<!--<li><a href=\"#\" class=\"action_delete\" title=\"Delete\">Delete</a></li>-->\n	<!--</ul>-->\n<!--</div>-->\n\n\n<td class=\"status two-lines\">\n    <span class=\"holder\">\n        <span class=\"";
  if (helper = helpers.status_text) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.status_text); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\"><span\n		        class=\"primary\">";
  if (helper = helpers.status_label) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.status_label); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n	        ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.viewed), {hash:{},inverse:self.program(7, program7, data),fn:self.program(5, program5, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        </span>\n    </span>\n\n</td>\n<td class=\"two-lines\">\n	<a href=\"#\"\n	   title=\"Edit\">\n		<span>\n			<span class=\"primary\">";
  if (helper = helpers.number) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.number); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n			<span class=\"secondary\">";
  if (helper = helpers.job_name) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.job_name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n\n		</span>\n	</a>\n</td>\n\n<!--<td>-->\n	<!--<span>";
  if (helper = helpers.formatted_expires_at) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.formatted_expires_at); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>-->\n<!--</td>-->\n<td class=\"two-lines\">\n	<a href=\"#\"\n	   title=\"View Client\">\n                <span>\n                    <span class=\"primary\">";
  stack1 = ((stack1 = ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.name)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</span>\n                    <span class=\"secondary\">";
  stack1 = ((stack1 = ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.email)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1);
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</span>\n                </span>\n	</a>\n</td>\n<td class=\"two-lines\">\n	<span>\n		<span class=\"primary\">";
  if (helper = helpers.formatted_created_at) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.formatted_created_at); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n		<span class=\"secondary\"><small class=\"text-muted\">Expires:</small> ";
  if (helper = helpers.formatted_expires_at) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.formatted_expires_at); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n	</span>\n\n</td>\n<!--<td class=\"amount num\" style=\"text-align: right; padding-right: 25px;\">-->\n<!--<span>-->\n<!--"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.amount)),stack1 == null || stack1 === false ? stack1 : stack1.formatted_total)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "-->\n<!--</span>-->\n<!--</td>-->\n<td class=\"x-grid-cell x-field-action\">\n	<div class=\"btn-group\">\n		<a href=\"#\" class=\"md-btn md-flat table-action dropdown-toggle\" data-toggle=\"dropdown\"><i\n				class=\"mdi-navigation-more-vert i-20\"></i> </a>\n		<ul class=\"dropdown-menu dropdown-menu-scale pull-up pull-right text-left\">\n			<li><a href=\"#\" class=\"action_edit\" title=\"Edit\">Edit</a></li>\n			<li><a href=\"#\" class=\"action_print_pdf\" title=\"Print PDF\">Print PDF</a></li>\n			<li><a href=\"#\" class=\"action_delete\" title=\"Delete\">Delete</a></li>\n		</ul>\n		<!--<a href=\"#\" class=\"md-btn md-flat table-action action_edit\" title=\"Edit\"><i class=\"mdi-editor-mode-edit\"></i></a>-->\n		<!--<a href=\"#\" target=\"_blank\" class=\"md-btn md-flat table-action action_print_pdf\" title=\"Print PDF\"><i class=\"mdi-action-print\"></i></a>-->\n		<!--<a href=\"#\" class=\"md-btn md-flat table-action action_delete\" title=\"Delete\"><i class=\"mdi-action-delete\"></i></a>-->\n\n	</div>\n\n</td>\n";
  return buffer;
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}