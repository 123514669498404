var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"md-toolbar\">\n	<div class=\"md-toolbar-tools\">\n		<h2>\n			<span>Recent Quotes</span>\n		</h2>\n		<span flex></span>\n		<button class=\"md-icon-button md-button\" aria-label=\"Settings\">\n			<span class=\"mdi-navigation-refresh md-icon i-24\"></span>\n		</button>\n		<button class=\"md-icon-button md-button\" aria-label=\"Settings\">\n			<span class=\"mdi-navigation-more-vert md-icon i-24\"></span>\n		</button>\n	</div>\n</div>\n<div class=\"md-card-content no-padder\">\n	<!--<div id=\"recent-quotes-container\" class=\"md-list\"></div>-->\n		<table class=\"table table-hover mbn\" id=\"recent-quotes\">\n	<thead>\n	<tr>\n		<th class=\"status\"><span>STATUS</span></th>\n		<th><span>QUOTE #</span></th>\n		<th><span>CLIENT</span></th>\n		<th><span>CREATED</span></th>\n		<!--<th class=\"amount num\" style=\"text-align: right; padding-right: 25px;\">-->\n		<!--<span>AMOUNT</span></th>-->\n		<th class=\"x-field-action\"><span></span></th>\n	</tr>\n	</thead>\n\n	<tbody id=\"recent-quotes-container\">\n\n	</tbody>\n	<tbody class=\"no-data\">\n		<tr>\n			<td colspan=\"7\">\n				<span>NO DATA</span>\n			</td>\n		</tr>\n	</tbody>\n	</table>\n</div>";
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}