/**
 * Created by soundview on 3/10/15.
 */
var View = require('views/base/collection-view');
var Model = require('models/insulation-quote-item');
var Collection = require('models/insulation-quote-item-collection');
var ItemView = require('views/insulation-item/insulation-item-view');

var InsulationItemLookup = require('models/insulation-item-lookup-collection');

var QuoteItemsView = View.extend({
	autoRender: true,
	//el: '#itemList',
	container: '#items-list',
	tagName: 'div',
	//noWrap: true,
	className: '',

	//container: '#clientList',
	//region: 'clientList',

	listen: {
		//'toView collection': '_viewItem',
		//'toViewGroup collection': '_viewGroup',
		'toEdit collection': '_editItem',
		'onAdd collection': '_addItem'
		//'onSave model': '_reload'
		//'toDelete collection': '_deleteItem'
	},
	itemView: ItemView,
	doNotFetch: false,
	listSelector: 'tbody',
	template: require('./templates/insulation-items'),
	fallbackSelector: '.no-data',
	loadingSelector: '.loading-mask',
	renderItems: false,
	options: {
		itemView: ItemView,
		doNotFetch: false,
		listSelector: 'tbody',
		template: require('./templates/insulation-items'),
		fallbackSelector: '.no-data',
		loadingSelector: '.loading-mask'
	},
	initialize: function (options) {
		//this.options = options;
		this.$loadingContainer = $('<div class="loading-mask" />');
		this.$el.append(this.$loadingContainer);
		this.collection = options.collection;
		this.itemView = ItemView;
		this.renderItems = false;
		this.options = _.defaults(options || {}, this.options);
		this.template = this.options.template;
		this.insulationItems = new InsulationItemLookup();
		this.insulationItems.fetch();






		this.listenTo(this.collection, 'save', this._reload);

		this.listenTo(Backbone, 'quoteitem:created', this._reload);

		//this.itemView = this.options.itemView;

		QuoteItemsView.__super__.initialize.call(this, options);
	},

	_editItem: function () {
		console.log('edit');
	},
	_addItem: function(model) {
		this.collection.add(model);
		console.log('added');
	},
	//getTemplateData: function() {
	//	return this.options.model.attributes;
	//
	//},
	initItemView: function (model) {
		if (this.itemView) {
			return new this.itemView({
				autoRender: false,
				model: model,
				insulationItems: this.insulationItems,
				attributes: {
					'data-id': model.attributes.id
				}
			});
		} else {
			QuoteItemsView.__super__.render.apply(this, arguments);
		}
	},
	_getLoadingContainer: function () {
		var loadingContainer = this.loadingContainer;
		if (loadingContainer instanceof $) {
			// fetches loading container from options
			loadingContainer = loadingContainer.get(0);
		}
		if (!loadingContainer) {
			// uses the element as default loading container
			loadingContainer = this.$el.get(0);
		}
		return loadingContainer;
	},
	_showLoading: function () {
		this.subview('loading').show();
		console.log('loading');
	},

	_hideLoading: function () {
		this.subview('loading').hide();
	},
	_reload: function () {
		this._showLoading();
		if (this.options.doNotFetch) {
			this._hideLoading();
			return;
		}
		try {
			this.collection.fetch({
				reset: true,
				data: $.param({quoteId: this.collection.quoteId}),
				success: _.bind(function () {
					console.log(this.collection);
					var self = this;
					_.delay(function(){
						self._hideLoading();
						self.collection.trigger('updated', self.collection);
					}, 300);
				}, this),
				error: _.bind(function (collection, response) {
					//this._showLoadItemsError(response.responseJSON || {});
					console.log(response.responseJSON);
				}, this)
			});
		} catch (err) {
			//this._showLoadItemsError(err);
			console.log(err);
		}
	}
});
module.exports = QuoteItemsView;