var Model = require('./model');
var utils = require('lib/utils');
var auth = require('models/session');
module.exports = Backbone.PageableCollection.extend({
	//options: {
	//	headers: {
	//		/*
	//		 * Set the 'Authorization' header and get the access
	//		 * token from the `auth` module
	//		 */
	//		'Authorization': 'Bearer ' + utils.getCookie('access_token')
	//	}
	//},
	initialize: function () {
		_.extend(this, Chaplin.SyncMachine);
		//this.headers = this.getHeaders();
		//Chaplin.Model.prototype.apply(this, arguments);
		this.on('request', this.beginSync);
		this.on('sync', this.finishSync);
		this.on('error', this.onErr);
		//console.log(this.options);
	},
	onErr: function (context, e) {

		if(e.status == 401) {
			//Chaplin.utils.redirectTo('quote#dashboard');
			window.location.href = '/index.php/login';
		}
	}
	//sync: function (method, model, options) {
	//	var backboneSync = Backbone.Sync;
	//
	//	options.headers = this.options.headers;
	//
	//	return Backbone.sync.apply(this, [method, model, options]);
	//}

	// Place your application-specific collection features here
});
