/**
 * Created by soundview on 3/5/15.
 */
var Controller = require('controllers/base/controller');
var HeaderView = require('views/layout/header-view');
var HomePageView = require('views/home/home-page-view');
var TestPageView = require('views/home/test-page-view');
var AsideLeftView = require('views/layout/sidebar-view');
var SiteView = require('views/site-view');


module.exports = Chaplin.Controller.extend({
	beforeAction: function () {
		//this.constructor.__super__.beforeAction.apply(this, arguments);
		this.reuse('siteView', SiteView);
		//this.reuse('header', HeaderView, {region: 'header'});
		this.reuse('asideLeft', AsideLeftView, {region: 'asideLeft'});

	},

	index: function () {
		this.view = new HomePageView({region: 'page'});
	},

	test: function () {
		this.view = new TestPageView({region: 'main'});
	}


});