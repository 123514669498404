var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<!--<div class=\"hbox hbox-auto-xs hbox-auto-sm bg-light\">-->\n	<!--<div class=\"col w br\" id=\"left-col\"></div>-->\n	<!--<div class=\"col w-lg lter b-r\" id=\"list-col\"></div>-->\n	<!--<div class=\"col bg-white-only\" id=\"display-col\"></div>-->\n<!--</div>-->\n<!--<div class=\"hbox hbox-auto-xs bg-light bg-gd\" id=\"app-content-body\"></div>-->";
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}