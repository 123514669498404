/**
 * Created by soundview on 5/26/15.
 */

var utils = require('lib/utils');

var SessionModel = Chaplin.Model.extend({
	defaults: {
		'access_token': null,
		'user_id': null,
		'user_name': null
	},
	getAccessToken: function() {
		return this.get('access_token');
	},
	initialize: function(){
		this.load();
	},
	authenticated: function() {
		return Boolean(this.get('access_token'));
	},
	save: function(auth_hash) {
		utils.setCookie('user_id', auth_hash.id);
		utils.setCookie('access_token', auth_hash.access_token);
	},
	load: function() {
		this.set({
			'user_id': utils.getCookie('user_id'),
			'access_token': utils.getCookie('access_token')
		});
	}
});

module.exports = SessionModel;