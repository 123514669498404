var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div id=\"app-bar\"></div>\n<div id=\"aside\" class=\"app-aside md-sidenav-left folded hidden-xs\"></div>\n<div id=\"app-content\" class=\"app-content\" role=\"main\">\n\n</div>\n<div id=\"flash-messages\">\n	<div class=\"flash-messages-frame\">\n		<div class=\"flash-messages-holder\"></div>\n	</div>\n</div>\n";
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}