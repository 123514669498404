/**
 * Created by soundview on 5/11/15.
 */
/**
 * Created by soundview on 4/29/15.
 */
/**
 * Created by soundview on 3/9/15.
 */
var DateFormatter = require('lib/formatter/datetime');

module.exports = Chaplin.Model.extend({
	urlRoot: '/index.php/api/v1/attachments',
	formattedFile: null,
	url: function () {
		if (!this.id) {
			return this.urlRoot
		}
		return this.urlRoot + '/' + this.id;
	},
	initialize: function(){
		this.formattedFile = this.parseFileName();
	},
	parseFileName: function() {
		return '/'+this.get('file_path')+'/'+this.get('file_name')+'.'+this.get('file_extension');
	},


	defaults: {
		active: false
	},
	serialize: function () {
		var json = Chaplin.Model.prototype.serialize.apply(this, arguments);

		json.__proto__.formattedFile = this.parseFileName();
		return json;
	}

});