var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<div class=\"container w-xxl w-auto-xs\">\n	<a href class=\"navbar-brand block m-t\">Sound View</a>\n\n	<div class=\"m-b-lg\">\n		<form name=\"form\" class=\"form-validation\">\n			<!--<div class=\"text-danger wrapper text-center\" ng-show=\"authError\">-->\n				<!--";
  if (helper = helpers.authError) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.authError); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "-->\n			<!--</div>-->\n			<div class=\"list-group list-group-sm\">\n				<div class=\"list-group-item\">\n					<input type=\"email\" id=\"input__email\" placeholder=\"Email\" class=\"form-control no-border\" required>\n				</div>\n				<div class=\"list-group-item\">\n					<input type=\"password\" placeholder=\"Password\" class=\"form-control no-border\"\n					       id=\"input__password\" required>\n				</div>\n			</div>\n			<button type=\"submit\" class=\"btn btn-lg btn-primary btn-block\" id=\"loginButton\">Log in\n			</button>\n			<div class=\"line line-dashed\"></div>\n		</form>\n	</div>\n\n</div>\n\n";
  return buffer;
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}