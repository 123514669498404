var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "-->\n				<!--";
  stack1 = helpers['if'].call(depth0, depth0, {hash:{},inverse:self.noop,fn:self.program(2, program2, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "-->\n				<!--";
  return buffer;
  }
function program2(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "-->\n				<!--<li><strong>"
    + escapeExpression(((stack1 = (data == null || data === false ? data : data.key)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + ":</strong> "
    + escapeExpression(((stack1 = (data == null || data === false ? data : data.key)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</li>-->\n					<!--";
  return buffer;
  }

function program4(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "&ndash;&gt;-->\n			<!--&lt;!&ndash;";
  stack1 = helpers['if'].call(depth0, depth0, {hash:{},inverse:self.noop,fn:self.program(5, program5, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "&ndash;&gt;-->\n		<!--&lt;!&ndash;";
  return buffer;
  }
function program5(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "&ndash;&gt;-->\n				<!--&lt;!&ndash;"
    + escapeExpression(((stack1 = (data == null || data === false ? data : data.key)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + ": "
    + escapeExpression((typeof depth0 === functionType ? depth0.apply(depth0) : depth0))
    + ";&ndash;&gt;-->\n			<!--&lt;!&ndash;";
  return buffer;
  }

  buffer += "<td colspan=\"9\">\n	<div class=\"card mbn no-shadow\">\n		<!--<div class=\"card-heading p10\">-->\n			<!---->\n		<!--</div>-->\n		<div class=\"card-tools\">\n			<button class=\"close quick-view-close\">\n				<i class=\"mdi-navigation-close\"></i>\n			</button>\n		</div>\n		<div class=\"card-body pbn\">\n			<div class=\"text-left\">";
  if (helper = helpers.product_line) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.product_line); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + ", ";
  if (helper = helpers.style) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.style); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + " ";
  if (helper = helpers.operation) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.operation); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + ", ";
  if (helper = helpers.formatted_item_size) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.formatted_item_size); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + ", ";
  if (helper = helpers.material) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.material); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n		</div>\n		<div class=\"card-divider\"></div>\n		<div class=\"card-body pbn pt10\">\n\n			<ul class=\"item-attr-list\">\n				<li><label>Brand</label>\n\n					<div>"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.attr)),stack1 == null || stack1 === false ? stack1 : stack1.brand)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</div>\n				</li>\n				<li><label>Product Line:</label>\n\n					<div>"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.attr)),stack1 == null || stack1 === false ? stack1 : stack1.product_line)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</div>\n				</li>\n				<li><label>Style:</label>\n\n					<div>";
  if (helper = helpers.style) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.style); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n				</li>\n				<li><label>Config:</label>\n\n					<div>";
  if (helper = helpers.operation) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.operation); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n				</li>\n				<li><label>Width:</label>\n\n					<div>";
  if (helper = helpers.width) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.width); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n				</li>\n				<li><label>Height:</label>\n\n					<div>";
  if (helper = helpers.height) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.height); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n				</li>\n				<li><label>Sq Ft:</label>\n\n					<div>";
  if (helper = helpers.square_feet) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.square_feet); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n				</li>\n				<li><label>Material:</label>\n\n					<div>";
  if (helper = helpers.material) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.material); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n				</li>\n				<li><label>Glass:</label>\n\n					<div>";
  if (helper = helpers.glass) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.glass); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n				</li>\n				<li><label>Obsc. Glass:</label>\n\n					<div>";
  if (helper = helpers.obscure_glass) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.obscure_glass); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n				</li>\n				<li><label>Grid Pattern:</label>\n\n					<div>";
  if (helper = helpers.grid_pattern) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.grid_pattern); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n				</li>\n				<li><label>Ext Finish:</label>\n\n					<div>";
  if (helper = helpers.exterior_finish) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.exterior_finish); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n				</li>\n				<li><label>Int Finish:</label>\n\n					<div>";
  if (helper = helpers.interior_finish) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.interior_finish); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n				</li>\n				<li><label>Hardware Finish:</label>\n\n					<div>";
  if (helper = helpers.hardware_finish) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.hardware_finish); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n				</li>\n				<li><label>Ext Trim:</label>\n\n					<div>";
  if (helper = helpers.exterior_trim) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.exterior_trim); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n				</li>\n				<li><label>Int Trim:</label>\n\n					<div>";
  if (helper = helpers.interior_trim) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.interior_trim); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n				</li>\n			</ul>\n		</div>\n		<div class=\"card-divider\"></div>\n		<div class=\"card-body ptn pbn\">\n			<ul class=\"item-attr-list\">\n				<li><label>List Price</label>\n\n					<div class=\"field\">";
  if (helper = helpers.list_price) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.list_price); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n				</li>\n				<li><label>On Factor:</label> ";
  if (helper = helpers.on_factor) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.on_factor); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</li>\n				<li><label>Mark Up:</label> ";
  if (helper = helpers.mark_up) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.mark_up); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</li>\n				<li><label>Sell Price:</label> "
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.amount)),stack1 == null || stack1 === false ? stack1 : stack1.subtotal_product)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</li>\n			</ul>\n			<!--<pre>-->\n				<!--";
  stack1 = helpers.each.call(depth0, (depth0 && depth0.attr), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "-->\n			<!--</pre>-->\n\n		</div>\n	</div>\n\n	<!--<header class=\"md-subheader b-b\">-->\n		<!--<div class=\"md-subheader-inner\">-->\n			<!--<div class=\"md-subheader-content pull-left\">";
  if (helper = helpers.name) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>-->\n			<!--<div class=\"md-subheader-content\">-->\n				<!--<a href=\"#\" class=\"close\">-->\n					<!--<i class=\"mdi-navigation-close\"></i>-->\n				<!--</a>-->\n			<!--</div>-->\n		<!--</div>-->\n\n	<!--</header>-->\n	<!--<div class=\"wrapper\">-->\n\n\n		<!--";
  if (helper = helpers.name) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "-->\n		<!--&lt;!&ndash;";
  stack1 = helpers.each.call(depth0, (depth0 && depth0.attr), {hash:{},inverse:self.noop,fn:self.program(4, program4, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "&ndash;&gt;-->\n	<!--</div>-->\n</td>\n\n\n";
  return buffer;
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}