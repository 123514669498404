/**
 * Created by soundview on 4/29/15.
 */
/**
 * Created by soundview on 3/6/15.
 */
/**
 * Created by soundview on 3/5/15.
 */
var View = require('views/base/view');
var Model = require('../model');
var Collection = require('../collection');


module.exports = View.extend({
	autoRender: true,
	tagName: 'tr',
	container: '#clientList',
	template: require('./templates/view'),
	events: {
		'click td:not(.x-field-action)': 'onClick',
		'click .action_edit': 'onClick',
		'click .action_delete': 'deleteItem',
		'click .action_print_pdf': '_toPdf'
	},
	initialize: function (options) {
		_.bindAll(this, 'render');
	},
	render: function () {
		this.$el.html(this.template(this.getTemplateData()));
	},
	onClick: function (a) {
		this.model.collection.trigger('toView', this.model, this);
	},
	deleteItem: function() {
		var _this = this;
		vex.dialog.confirm({
			message: 'Are you sure you want to destroy this item?',
			callback: function (value) {
				if (value == true) {
					return _this.model.destroy({wait: true});
				}
			}
		});
	},
	_toPdf: function () {
		var url = "/w_quotes/" + this.model.get('id') + "/pdf";
		window.open(url, "_blank");
	}


});

