/**
 * Created by soundview on 3/9/15.
 */

var Model = require('./insulation-quote');

module.exports = InsulationCollection = Backbone.PageableCollection.extend({
	// Mixin a synchronization state machine.
	initialize: function(options) {
		InsulationCollection.__super__.initialize.apply(this, arguments);
	   //_.extend(this, Chaplin.SyncMachine);
	   //Chaplin.Model.prototype.apply(this, arguments);
	   //this.on('request', this.beginSync);
	   //this.on('sync', this.finishSync);
	   //this.on('error', this.unsync);
	},
	urlRoot: '/index.php/api/v1/insulation',
	url: function() {
		if(this.client_id) {
			return this.urlRoot + '/?client_id=' + this.client_id;
		} else {
			return this.urlRoot;
		}
	},
	model: Model,
	mode: 'server',
	parseRecords: function (data) {
		return data.data;
	},

	parseState: function (data) {
		var state = {
			currentPage: data.current_page,
			lastPage: data.last_page,
			totalRecords: data.total

		}
		return state;
	},
	state: {

		// You can use 0-based or 1-based indices, the default is 1-based.
		// You can set to 0-based by setting ``firstPage`` to 0.
		firstPage: 1,
		currentPage: 1,
		status: 'all',
		// Required under server-mode
		//totalRecords: 200,

		pageSize: 15
	},
	queryParams: {

		// `Backbone.PageableCollection#queryParams` converts to ruby's
		// will_paginate keys by default.
		//currentPage: null,
		//pageSize: "page_size",
		//lastPage: "last_page",
		//totalRecords: "total"
		status: "all"
	},
	search: function (search) {
		if (search == "") return this;

		var pattern = new RegExp(search, "gi");
		return _(this.filter(function (data) {
			return pattern.test(data.get("name"));
		}));
	}
});