/**
 * Created by soundview on 4/13/15.
 */
var View = require('views/base/view');
var Model = require('models/client');
var Collection = require('models/client-collection');

var WindowQuotesCollection = require('window/collection');
var WindowQuotesTable = require('window/table/table');

var DetailView = View.extend({
	autoRender: true,
	className: 'box',
	template: require('./templates/client-detail'),
	tagName: 'div',
	initialize: function (options) {
		this.options = options || {};
		this.model = options.model;
		DetailView.__super__.initialize.apply(this, options);

		this.windowQuotes = new WindowQuotesCollection({data: $.param({client: this.model.get('id')})});

		this.registerRegion('windowQuotes', '#windowQuotes');


		console.log(this);
	},
	render: function(){
		var data = this.model.serialize();
		console.log(data);
		this.$el.append(this.template(data));
		this.subview('windowQuotesTable', new WindowQuotesTable({
			collection: this.windowQuotes,
			region: "windowQuotes",
			clientId: this.model.get('id')
		}));
		console.log(this.windowQuotes);
	}
});

module.exports = DetailView;
