/**
 * Created by soundview on 8/13/15.
 */
var View = require('views/base/view');



module.exports = View.extend({
	autoRender: true,
	tagName: 'div',
	className: 'amount-widget',
	container: '#amount-widget-container',
	template: require('./templates/amount-widget'),
	attributes: {
		flex: '25'
	},
	initialize: function (options) {
		_.bindAll(this, 'render');
	},
	render: function () {
		this.$el.html(this.template(this.getTemplateData()));
	}
});