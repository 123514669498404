/**
 * Created by soundview on 3/9/15.
 */
var DateFormatter = require('lib/formatter/datetime');

module.exports = Chaplin.Model.extend({
	urlRoot: '/index.php/api/v1/insulation',
	url: function(){
		if(!this.id) { return this.urlRoot }
		return this.urlRoot + '/' + this.id;
	},
	viewRoute: function () {
		if (!this.id) {
			return this.urlRoot
		}
		return '/insulation/' + this.id;
	},
	statuses: [{
		status_id: '1',
		label: 'Draft'
	}, {
		status_id: '2', label: 'Sent'
	},
		{status_id: '3', label: 'Approved'},
		{status_id: '4', label: 'Rejected'},
		{status_id: '5', label: 'Canceled'}
	],
	defaults: {
		client: {},
		client_id: '',
		job_type: 'Remodel',
		created_at: DateFormatter.formatDate(Date.now()),
		currency_code: '',
		exchange_rate: '',
		expires_at: '',
		footer: '',
		invoice_group_id: 3,
		attic_access: '',
		wall_const: '',
		driveway_dist: '',
		invoice_id: '',
		items: [],
		number: '',
		quote_status_id: '',
		tax_rates: [],
		template: '',
		terms: '',
		updated_at: '',
		url_key: '',
		user_id: ''
	},
	schema: {
		client_name: {
			title: 'Client',
			type: 'Text',
			validators: ['required'],
			fieldClass: 'static',
			editorClass: 'client static',
			editorAttrs: {
				'autocomplete': 'off'
			}
		},
		created_at: {
			titleHTML: 'Quote Date',
			type: 'Text',
			validators: ['required'],
			fieldClass: '',
			editorClass: 'created_at dp'

		},
		invoice_group_id: {
			title: 'Invoice Group',
			type: 'Hidden',
			value: 3
		}
	},
	serialize: function () {
		var json = Chaplin.Model.prototype.serialize.apply(this, arguments);

		return json;
	}

});