/**
 * Created by soundview on 3/10/15.
 */
/**
 * Created by soundview on 3/10/15.
 */
var View = require('views/base/view');
var Model = require('models/insulation-quote-item');

var FormView = require('views/templates/bootstrap3');

module.exports = View.extend({
	autoRender: false,
	//region: 'main',
	tagName: 'div',
	isEdit: false,
	className: 'modal fade',
	template: require('./templates/insulation-item-edit'),
	id: '',
	events: {
		//'click': 'activate',
		'click .item-save-btn': 'submit',
		'click .item-close-btn': 'close',
		'click .back_btn': '_backPath'
	},
	attributes: {
		//tabindex: "-1",
		role: "dialog",
		'aria-labelledby': "widgetTitle",
		'aria-hidden': "true"
	},
	services: [{
			id: -1,
			val: '',
			label: '',
			children: []
		},{
			id: 1,
			val: 'glass',
			label: 'Glass Replacement',
			selected: false,
			children: [
				{
					val: 'g_glass_only',
					label: 'Glass Only'
				}, {
					val: 'g_vinyl',
					label: 'Vinyl'
				}, {
					val: 'g_wood',
					label: 'Wood'
				}, {
					val: 'g_aluminium',
					label: 'Aluminium'
				}
			]
		},{
			id: 2,
			val: 'skylight',
			label: 'Skylight Replacement',
			selected: false,
			children: [
				{
					val: 'sl_vinyl',
					label: 'Vinyl'
				}, {
					val: 'sl_wood',
					label: 'Wood'
				}, {
					val: 'sl_aluminium',
					label: 'Aluminium'
				}
			]

		},{
			id: 3,
			val: 'board_up',
			label: 'Board Up',
			selected: false,
			children: [
				{
					val: 'sl_vinyl',
					label: 'Vinyl'
				}, {
					val: 'sl_wood',
					label: 'Wood'
				}, {
					val: 'sl_aluminium',
					label: 'Aluminium'
				}
			]
	}],

	initialize: function(options) {
		//_.bindAll(this);
		this.model = options.model;
		var _this = this;



		if(this.model.get('id')){
			this.isEdit = true;
		}



		this.testList = '/quotes/testList';
		_.bindAll(this, 'render');
		//this.listenTo(this.model, 'change', this.onSave());
		//this.id = 'w_item-' + this.model.get('id');
		//this.$el.attr({});

		this.render();
	},
	bindings: {
		'.service': {
			observe: 'service',
			initialize: function ($el) {
				$el.select2({width: "100%", placeholder: 'Select Service...', allowClear: true,
					minimumResultsForSearch: Infinity});
			},
			selectOptions: {
				collection: function () {
					return this.services;
				},
				valuePath: 'val',
				labelPath: 'label'
				//defaultOption: {
				//	label: 'Select Service..',
				//	value: null
				//}
			},
			onSet: function (val) {
				//this.model.set('service_type', null);
				this.$('.service_type').select2('val', null);
				return val;
			}
		},
		'.service_type': {
			observe: ['service_type', 'service'],
			updateModel: false,
			initialize: function ($el) {
				$el.select2({width: "100%", placeholder: 'Select Service Type..', allowClear: true,
					minimumResultsForSearch: Infinity});
				if (this.model.get('service_type') != null) {
					$el.select2('val', this.model.get('service_type'));
				}
			},
			selectOptions: {
				collection: function () {
					var serviceId = this.model.get('service');
					var state = _.find(this.services, function (item) {
						return item.val === serviceId;
						//console.log(item);
					});
					//var state = _.find(this.services, {id: 2});

					return state ? state.children : '';
				},
				valuePath: 'val',
				labelPath: 'label'
				//defaultOption: {
				//	label: 'Select Service Type..',
				//	value: null
				//}
			},
			onSet: function (val) {
				this.model.set('service_type', val);
				return val;
			}
		},
		'.floor': {
			observe: 'floor',
			updateModel: false,
			initialize: function($el) {
				$el.select2({width: "100%", placeholder: "Select Floor...", allowClear: true,
					minimumResultsForSearch: Infinity});
				if (this.model.get("floor") != null) {
					$el.select2("val", this.model.get("floor"));
				}
			},
			onSet: function(val) {
				this.model.set('floor', val);
				return val;
			}
		}

	},
	initQuestions: function(){
		this.$questions = this.$el.find('#questions');
		//this.$el.append(this.$questions);
		this.working = false;

		this.complete = this.model.get('is_complete');

		console.log(this.complete);

		this.loaded = false;

		$('#preloader').ajaxStart(function () {
			$(this).show();
		}).ajaxStop(function () {
			$(this).hide();
		});


		//this.initSelects();

		// Initially load the product select
		this.fetchSelect('serviceList');

		console.log(this.model.get('service'));

		if(this.model.get('service')){
			console.log('fetch select:' + this.model.get('service'));

			this.fetchSelect(this.model.get('service'));

			this.working = false;

			this.fetchSelect('serviceList');


		} else {
			this.fetchSelect('serviceList');
		}

		//this.refreshSelects();

	},
	stickitGetValues: function (attrNames, options) {
		options = options || {};

		var changes = {};
		_.each(this.bindings, function (v, selector) {
			var namespace = '.stickit.' + this.model.cid;
			var $el = this.$(selector);
			var binding = this.bindings[selector];
			if (_.isString(binding)) {
				binding = {observe: binding};
			}
			var config = Backbone.Stickit.getConfiguration($el, binding);
			if (_.contains(attrNames, config.observe)) {
				var val = config.getVal.call(this, $el, 'manual', config);
				if (!options.patch || this.model.get(config.observe) !== val) {
					changes[config.observe] = val;
				}
			}
		}, this);

		return changes;
	},
	_backPath: function () {
		window.history.back();
	},
	fetchSelect: function(val, working) {
		if (this.working) {
			return false;
		}

		this.working = true;
		var _thisView = this;
		var _model = this.model;

			$.getJSON(this.testList, {key: val}, _.bind(function (r) {
				var connection, options = '';
				var $selectContainer = $('<div class="form-group">\
	                    <label class="control-label">' + r.title + '</label>\
	            </div>');
				var $select = $('<select></select>');
				$select.attr('name', r.name);
				if (r.defaultText) {
					// The chose plugin requires that we add an empty option
					// element if we want to display a "Please choose" text
					$select.append($('<option></option>'));//options = '<option></option>' + options;
				}

				$.each(r.items, function (k, v) {
					connection = '';
					var $option = $('<option></option>');
					var _isSelected;
					var selected = '';
					//console.log(_model);
					if (_model.get(r.name) == v.value) {
						_isSelected = true;
						selected = 'selected';
						$option.attr('selected', true);
					}

					if (v.connection) {
						$option.attr("data-connection", v.connection);
						//connection = 'data-connection="' + v.connection + '"';
						//console.log(connection);
					}
					$option.val(v.value);
					$option.text(k);

					$select.append($option);

					//options += '<option value="' + v.value + '" ' + connection + selected + '>' + k + '</option>';
					//options += $option;
				});

				// Building the markup for the select section
				//var $select = $('<div class="form-group">\
				//       <label class="col-sm-3 control-label">' + r.title + '</label>\
				//       <div class="col-sm-9">\
				//           <select class="form-control" name="' + r.name +'" data-placeholder="' + r.defaultText + '">\
				//               ' + options + '\
				//           </select>\
				//       </div>\
				//   </div>');
				$select.append(options);
				$select.appendTo($selectContainer);
				$selectContainer.appendTo(_thisView.$questions);

				//if($select.find('option').eq(this.selectedIndex)){
				//	console.log('selected');
				//	var selected = $select.find('option').eq(this.selectedIndex);
				//	// Look up the data-connection attribute
				//	_thisView.fetchSelect(selected.data('connection'));
				//}


				//$select.chosen({
				//	disable_search_threshold: 0,
				//	no_results_text: "Oops, nothing found!",
				//	width: "100%"
				//});
				//var selected = $(select).find('option').eq(this.selectedIndex);
				//// Look up the data-connection attribute
				//var conn = selected.data('connection');
				//if (conn) {
				//	_thisView.fetchSelect(conn);
				//}
				//console.log('select: ' + select);
				//_thisView.$questions.append(this.select);
				//console.log(this.$questions);

				_thisView.refreshSelects();
				_thisView.working = false;
				//console.log(_thisView.$questions);
			}), this);



	},
	refreshSelects: function () {
		var selects = this.$questions.find('select');
		var _this = this;


		selects.chosen({
			disable_search_threshold: 0,
			no_results_text: "Oops, nothing found!",
			width: "100%"
		});



		selects.unbind('change').bind('change', function () {
			console.log('change');
			// The selected option
			var selected = $(this).find('option').eq(this.selectedIndex);
			// Look up the data-connection attribute
			var connection = selected.data('connection');

			console.log(connection);

			// Removing the li containers that follow (if any)
			selected.closest('#questions .form-group').nextAll().remove();

			if (connection) {
				_this.fetchSelect(connection);
			}
		});


	},

	close: function () {
		this.hide();
		this.dispose();
	},
	render: function() {
		var data = {model: this.model.toJSON(), services: this.services};

		this.$el.append(this.template(data));

		this.form = new Backbone.Form({
			//template: require('./templates/form/quote-item-form'),
			model: this.model
		}).render();

		this.$el.find('.modal-body').append(this.form.el);

		this.stickit();
		//_this = this;
		//
		//form.on('service:change', function (form, serviceEditor) {
		//	var service = serviceEditor.getValue(),
		//	    //newOptions = ["Select Service Type..."];
		//	    newOptions = _this.services[service].children;
		//
		//	form.fields.service_type.editor.setOptions(newOptions);
		//	form.fields.service_type.editor.$el.chosen();
		//});

		this.$el.modal('show');
	},
	submit: function() {
		var error = this.form.validate();
		if(!error) this.save();
	},
	save: function() {
		var form = this.$el.find('form').serializeArray();

		var data2 = {};
		_.each(form, function(val){
			data2[val.name] = val.value;
			//console.log(val.name+': '+val.value);
		});

		console.log(form);


		var data = this.form.getValue();

		console.log(data);
		//this.model.set(data);
		//this.model.on('save', this.onSave());
		//this.model.save(data, {patch: true});

		this.model.save(data, {
			wait: true,
			patch: true,
			success: _.bind(function () {
				console.log('success');
				this.hide();
				this.dispose();
				//this.model.collection.trigger('updated', this.model);
			}, this)
		});

		if(!this.isEdit) {
			Backbone.trigger('quoteitem:created', this.model);
		} else {
			console.log(this.model.collection);
			this.model.collection.trigger('save', this.model);
		}
		//this.$el.modal('hide');

		//this.model.collection.trigger('onSave', this, this.model);
	},
	hide: function() {
		this.$el.modal('hide');
	},
	onSave: function() {
		this.model.trigger('onSave', this, this.model);
	}
});