/**
 * Created by soundview on 3/10/15.
 */
var View = require('views/base/view');
var Model = require('../model');
var QuickView = require('./quick-view');
var mediator = require('mediator');
require('lib/view-helper');

module.exports = View.extend({
	autoRender: true,
	tagName: 'tr',
	noWrap: true,
	className: 'quote-item',
	template: require('./item-template'),
	events: {
		//'click': 'activate',
		'click td:not(.x-field-action):not(.x-field-name):not(.x-field-drag)': 'onEdit',
		'click .toggle-quick-view': '_quickView',
		'click .btn-delete': 'close',
		'click .btn-copy': '_duplicate'
	},
	bindings: {
		'input[name=name]': {
			observe: 'name',
			events: ['focusout']
		}

	},
	initialize: function(options) {
		this.attributes = options.attributes;
		this.model = options.model;
		this.listenTo(this.model, 'change:name', this.save);
		//this.attributes = {
		//	'data-id': this.model.attributes.id
		//};

		this.render();

		//console.log(this.model.collection.indexOf(this.model));
		///this.listenTo(this.model, 'change:width', this.reload);

	},
	onSave: function() {
		this.model.collection.trigger('updated');
	},
	onEdit: function () {

		Chaplin.utils.redirectTo('window#edit', { itemId: this.model.attributes.id});



		//this.model.collection.trigger('toEdit', this.model);
	},
	_quickView: function() {

		this.model.collection.trigger('quickView', this, this.model);

		//var view = new QuickView({model: this.model, autoRender: false});

		//this.$el.after(view.render().el);
		//var $tr = $('<tr class="quick_view"></tr>');
		//var quickView = new QuickView({model: this.model, $el: $tr});
		//this.subview('quickView', quickView);
		//
		//this.$el.after($tr);
		//console.log(this.$el);
	},
	formatSize: function (size) {
		var inch, feet, formattedSize;
		feet = parseInt(size / 12);
		inch = size % 12;

		formattedSize = (feet > 0) ? feet + "' " : "";
		formattedSize += inch + '"';
		return formattedSize;
	},
	getTemplateData: function(){
		var data = this.model.serialize();
		data.__proto__.attr = _.pick(this.model.toJSON(), 'elevation', 'brand', 'product_line', 'width', 'height', 'style', 'operation', 'material', 'glass', 'obscure_glass', 'grid_pattern', 'exterior_finish', 'interior_finish', 'hardware_finish', 'interior_trim', 'exterior_trim');


		data.__proto__.rowIndex = this.model.collection.indexOf(this.model) + 1;
		return data;
	},
	reload: function() {
		this.model.fetch({
			success:function(val){
				console.log(val);
			}
		});
	},
	stickitGetValues: function (attrNames, options) {
		options = options || {};

		var changes = {};
		_.each(this.bindings, function (v, selector) {
			var namespace = '.stickit.' + this.model.cid;
			var $el = this.$(selector);
			var binding = this.bindings[selector];
			if (_.isString(binding)) {
				binding = {observe: binding};
			}
			var config = Backbone.Stickit.getConfiguration($el, binding);
			if (_.contains(attrNames, config.observe)) {
				var val = config.getVal.call(this, $el, 'manual', config);
				if (!options.patch || this.model.get(config.observe) !== val) {
					changes[config.observe] = val;
				}
			}
		}, this);

		return changes;
	},
	save: function() {
		var attrs = this.stickitGetValues(this.model.keys());
		//var errors = this.model.validate(attrs);
		this.model.save(attrs, {
			wait: true,
			patch: !this.model.isNew()
		});
		this.reload();
		this.render();

	},
	_duplicate: function() {
		var newItem = new Model({quote_id: this.model.get('quote_id')});
		var attr = _.omit(this.model.attributes, 'amount', 'cid', 'created_at', 'display_order', 'id', 'rowIndex', 'install', 'attr', 'updated_at', 'square_feet', 'attributes', 'formatted_item_size');
		//attr.quote_id = this.model.get('quote_id');

		console.log(attr);

		newItem.save(attr, {patch: true});
		//this.model.collection.add(newItem);
		this.model.collection.trigger('save', this);
		//console.log(attr);
	},

	close: function () {
		var _this = this
		vex.dialog.confirm({
			message: 'Are you sure you want to destroy this item?',
			callback: function (value) {
				if(value == true) {
					return _this.model.destroy({wait: true});
				}
			}
		});
		//if (this.model.get('primary')) {
		//	alert(__('Primary address can not be removed'));
		//} else {
		//	this.model.destroy({wait: true});
		//}
	},
	render: function () {
		this.$el.html(this.template(this.getTemplateData()));


		//this.$el.insertAfter("<tr><td colspan='7'></td></tr>");

		this.stickit(); // Chosen is initialized.

	}
});