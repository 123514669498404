var View = require('./view');
var LoadingMaskView = require('views/loading-mask-view');
var BaseCollectionView = Chaplin.CollectionView.extend({
	/**
	 * Selector of the element that should be covered with loading mask
	 *
	 * @property {null|string|jQuery}
	 * @default null
	 */
	loadingContainerSelector: null,
	transition: false,

	/**
	 * Show loader indicator on sync action even for not empty collections
	 *
	 * @property {boolean}
	 * @default true
	 */
	showLoadingForce: true,

	initialize: function (options) {
		_.extend(this, _.pick(options, ['fallbackSelector', 'loadingSelector', 'loadingContainerSelector',
			'itemSelector', 'listSelector', 'animationDuration']));
		BaseCollectionView.__super__.initialize.apply(this, arguments);
	},
	// This class doesn’t inherit from the application-specific View class,
	// so we need to borrow the method from the View prototype:
	getTemplateFunction: View.prototype.getTemplateFunction,
	_ensureElement: View.prototype._ensureElement,
	_findRegionElem: View.prototype._findRegionElem,
	/**
	 * Fetches model related view
	 *
	 * @param {Chaplin.Model} model
	 * @returns {Chaplin.View}
	 */
	getItemView: function (model) {
		return this.subview("itemView:" + model.cid);
	},


	/**
	 * Initializes loading indicator
	 *
	 *  - added support loadingMask subview
	 *
	 * @returns {jQuery}
	 * @override
	 */
	initLoadingIndicator: function () {
		var loading, loadingContainer;
		loadingContainer = this._getLoadingContainer();
		if (loadingContainer) {
			loading = new LoadingMaskView({
				container: loadingContainer
			});
			this.subview('loading', loading);
			this.loadingSelector = loading.$el;
		}
		return BaseCollectionView.__super__.initLoadingIndicator.apply(this, arguments);
	},


	/**
	 * Fetches loading container element
	 *
	 * @returns {HTMLElement|undefined}
	 * @protected
	 */
	_getLoadingContainer: function () {
		var loadingContainer;
		if (this.loadingContainerSelector) {
			loadingContainer = this.$(this.loadingContainerSelector).get(0);
		}
		return loadingContainer;
	},

	/**
	 * Toggles loading indicator
	 *
	 *  - added extra flag showLoadingForce that shows loader event for not empty collections
	 *  - added support loadingMask subview
	 *
	 * @returns {jQuery}
	 * @override
	 */
	toggleLoadingIndicator: function () {
		var visible;

		visible = (this.collection.length === 0 || this.showLoadingForce) && this.collection.isSyncing();
		if (this.subview('loading')) {
			this.subview('loading').toggle(visible);
		} else {
			this.$loading.toggle(visible);
		}

		return this.$loading;
	},
	transitionIn: function (callback) {

		var view = this;


		var transitionIn = function () {

			view.$el.addClass(view.animateIn + ' animated');
			view.$el.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd animationend', function () {
				view.$el.removeClass('transition');
				view.$el.removeClass(view.animateIn + ' animated');

				if (_.isFunction(callback)) {
					callback();
					console.log('Callback triggered on transitionend for TransitionIn method');
				}
			});
		};

		// setting the page class' css to position: fixed; obviates the need
		// for this and still allows transitions to work perfectly since pos
		// is absolute during animation
		_.delay(transitionIn, 0);
	},

	transitionOut: function (callback) {

		var view = this;

		view.$el.addClass(view.animateOut + ' animated');
		view.$el.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd animationend', function () {

			view.$el.removeClass(view.animateOut + ' animated');

			if (_.isFunction(callback)) {
				callback();   // hard to track bug! He's binding to transitionend each time transitionOut called
			                  // resulting in the callback being triggered callback * num of times transitionOut
			                  // has executed
				console.log('Callback triggered on transitionend for TransitionOut method');
			}
		});

	}
});
module.exports = BaseCollectionView;
