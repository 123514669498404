/**
 * Created by soundview on 3/5/15.
 */
var View = require('views/base/view');

module.exports = View.extend({
	autoRender: true,
	className: 'box bg-white',
	tagName: 'div',
	template: require('./templates/sidebar')
});