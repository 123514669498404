var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"modal-dialog\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\"><span\n						aria-hidden=\"true\"></span></button>\n				<h4 class=\"modal-title\" id=\"widgetTitle\">Modal title</h4>\n			</div>\n			<div class=\"modal-body\">\n\n			</div>\n			<div class=\"modal-footer\">\n				<button type=\"button\" class=\"btn btn-x item-close-btn\" data-dismiss=\"modal\">Close</button>\n				<button type=\"button\" class=\"btn btn-x item-save-btn\">Save changes</button>\n			</div>\n		</div>\n	</div>";
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}