/**
 * Created by soundview on 3/9/15.
 */
var View = require('views/base/view');
var Model = require('models/insulation-quote');
var DateTimeFormatter = require('lib/formatter/datetime');


module.exports = View.extend({
	autoRender: true,
	container: '#info-panel',
	//container: '#info-panel',
	tag: 'div',
	className: 'vbox wrapper',
	//region: 'info',
	template: require('./templates/insulation-info'),
	statuses: [{
		value: 1,
		label: 'Draft'
	}, {
		value: 2, label: 'Sent'
	},
		{value: 3, label: 'Approved'},
		{value: 4, label: 'Rejected'},
		{value: 5, label: 'Canceled'}
	],
	bindings: {
		'#job_type': {
			observe: 'job_type',
			selectOptions: {
				collection: function () {
					return [{id: 'Remodel', name: 'Remodel'}, {id: 'New Construction', name: 'New Construction'}]
				},
				valuePath: 'id',
				labelPath: 'name'
			},
			events: ['blur'],
			onSet: function (val) {
				this.model.set('job_type', val);
				this.saveAttr();
			}
		},
		'#attic_access': {
			observe: 'attic_access',
			events: ['blur'],
			onSet: function(val) {
				this.model.set('attic_access', val);
				this.saveAttr();
			}
		},
		'#wall_const': {
			observe: 'wall_const',
			events: ['blur'],
			onSet: function (val) {
				this.model.set('wall_const', val);
				this.saveAttr();
			}
		},
		'#driveway_dist': {
			observe: 'driveway_dist',
			events: ['blur'],
			onSet: function (val) {
				this.model.set('driveway_dist', val);
				this.saveAttr();
			}
		},
		'#created_at': {
			observe: 'created_at',
			events: ['blur'],
			initialize: function($el){
				$el.datepicker({
					prevText: '<i class="fa fa-angle-left"></i>',
					nextText: '<i class="fa fa-angle-right"></i>',
					dateFormat: 'yy-mm-dd'
				});
			},
			onSet: function (val) {
				//var d = DateTimeFormatter.convertDateToBackendFormat(val);
				this.model.set("created_at", val);
				this.saveAttr();
			},
			afterUpdate: function ($el) {
				//$el.prop('disabled', true);
			}
		},
		'#quote_status': {
			observe: 'quote_status_id',
			initialize: function ($el) {
				$el.select2({
					width: "100%", placeholder: 'Select Status...', allowClear: true,
					minimumResultsForSearch: Infinity
				});
			},
			selectOptions: {
				collection: function () {
					return this.statuses;
				},
				valuePath: 'value',
				labelPath: 'label'
				//defaultOption: {
				//	label: 'Select Service..',
				//	value: null
				//}
			},
			onSet: function (val) {
				this.model.set('quote_status_id', val);
				this.saveAttr();
			}
		},
		'#expires_at': {
			observe: 'expires_at',
			events: ['blur'],
			initialize: function ($el) {
				$el.datepicker({
					prevText: '<i class="fa fa-angle-left"></i>',
					nextText: '<i class="fa fa-angle-right"></i>',
					dateFormat: 'yy-mm-dd'
				});
			},
			onSet: function (val, options) {
				this.model.set("expires_at", val);
				this.saveAttr();
				//this.model.trigger('save', this.model);
			},
			afterUpdate: function ($el) {
				//$el.prop('disabled', true);
			}
		}
	},
	initialize: function (options) {
		this.model = options.model;

		_.bindAll(this, 'render');

		this.model.fetch({reset:true});
		//this.model.fetch();

		//console.log(this.model.attributes);
		//this.collection = options.collection || new Collection();
		//this.collection.url = '/index.php/api/clients';

		//this._initMainContainers();
		//this.listenTo(this.getCollection(), 'reset', this.renderClientList);

		//this.getCollection().fetch({reset: true});
		//this.listenTo(this.model, 'change:amount', this.reload);

	},
	initComponents: function() {

	},
	stickitGetValues: function (attrNames, options) {
		options = options || {};

		var changes = {};
		_.each(this.bindings, function (v, selector) {
			var namespace = '.stickit.' + this.model.cid;
			var $el = this.$(selector);
			var binding = this.bindings[selector];
			if (_.isString(binding)) {
				binding = {observe: binding};
			}
			var config = Backbone.Stickit.getConfiguration($el, binding);
			if (_.contains(attrNames, config.observe)) {
				var val = config.getVal.call(this, $el, 'manual', config);
				if (!options.patch || this.model.get(config.observe) !== val) {
					changes[config.observe] = val;
				}
			}
		}, this);

		return changes;
	},
	reload: function() {
		this.model.fetch({
			wait: true,
			success: _.bind(function () {
				console.log(this.model);
				var self = this;
				_.delay(function () {
					self.render();
				}, 800);
			}, this)
		});

		console.log('reload info-view');
		this.render();
	},
	saveAttr: function () {
		var attr = this.stickitGetValues(this.model.keys());
		console.log(attr);
		if (!_.isEmpty(this.model.changed)) {
			this.model.save(attr, {
				patch: true,
				success: function(resp) {
					console.log(resp);
				}
			});
		}
		//console.log(this.model.attributes);
	},
	render: function(){


		//var data = this.model.toJSON();
		////console.log(data);
		//
		//console.log(DateTimeFormatter.unformatDate(this.model.get('expires_at')));
		//
		//this.$el.append(this.template(data));

		View.__super__.render.apply(this, arguments);
		this.$el.find('.dp').datepicker({
			prevText: '<i class="fa fa-angle-left"></i>',
			nextText: '<i class="fa fa-angle-right"></i>'
		});

		this.stickit();

		return this;
	}



});

