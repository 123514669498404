var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

function program1(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "-->\n				<!--";
  stack1 = helpers['if'].call(depth0, depth0, {hash:{},inverse:self.noop,fn:self.program(2, program2, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "-->\n			<!--";
  return buffer;
  }
function program2(depth0,data) {
  
  var buffer = "";
  buffer += "-->\n					<!--"
    + escapeExpression((typeof depth0 === functionType ? depth0.apply(depth0) : depth0))
    + ";-->\n				<!--";
  return buffer;
  }

function program4(depth0,data) {
  
  var stack1, helper;
  if (helper = helpers.description) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.description); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  return escapeExpression(stack1);
  }

function program6(depth0,data) {
  
  
  return "No Description";
  }

  buffer += "<td class=\"x-grid-cell x-field-drag\">\n	<span>\n		<a class=\"toggle-quick-view\">\n			<i class=\"mdi-navigation-unfold-more i-20 show-more\"></i>\n			<i class=\"mdi-navigation-unfold-less i-20 show-less\"></i>\n		</a>\n	</span>\n\n</td>\n<td class=\"x-grid-cell x-field-index\">\n	<span class=\"num\">";
  if (helper = helpers.rowIndex) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.rowIndex); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n</td>\n<td class=\"x-grid-cell x-field-name\">\n	<span>\n		<div class=\"form-group form-md-line-input pt10\">\n			<input type=\"text\" id=\"";
  if (helper = helpers.cid) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.cid); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "_name\" name=\"name\" class=\"form-control input-sm input-name\" value=\"";
  if (helper = helpers.name) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\"/>\n			<label></label>\n		</div>\n	</span>\n\n</td>\n<!--<td class=\"x-grid-cell x-field-desc two-lines\">-->\n	<!--<span class=\"holder\">-->\n		<!--<span>-->\n		<!--<span class=\"primary\">-->\n			<!--";
  stack1 = helpers.each.call(depth0, (depth0 && depth0.attr), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "-->\n		<!--</span>-->\n		<!--<span class=\"secondary\">-->\n			<!--";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.description), {hash:{},inverse:self.program(6, program6, data),fn:self.program(4, program4, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "-->\n		<!--</span>-->\n	<!--</span>-->\n	<!--</span>-->\n\n<!--</td>-->\n<td colspan=\"2\" class=\"x-grid-cell x-field-size two-lines\">\n	<span class=\"holder\">\n		<span>\n		<span class=\"primary\">\n			W:\n			<span class=\"width\">\n				";
  if (helper = helpers.width) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.width); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n			</span>\n		</span>\n		<span class=\"primary\">\n			H:\n			<span class=\"height\">\n				";
  if (helper = helpers.height) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.height); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n			</span>\n		</span>\n	</span>\n	</span>\n</td>\n<!--<td class=\"x-grid-cell x-field-sqft\">-->\n	<!--<span>-->\n		<!--";
  if (helper = helpers.square_feet) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.square_feet); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "-->\n	<!--</span>-->\n<!--</td>-->\n<td class=\"x-grid-cell x-field-rate two-lines amount num\">\n	<span class=\"holder\">\n		<span>\n			<span class=\"primary\">$";
  if (helper = helpers.list_price) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.list_price); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n			<span class=\"secondary\">";
  if (helper = helpers.on_factor) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.on_factor); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "/";
  if (helper = helpers.mark_up) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.mark_up); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n		</span>\n	</span>\n</td>\n<td class=\"x-grid-cell x-field-total two-lines amount num\">\n	<span class=\"holder\">\n		<span>\n			<span class=\"primary\">$"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.amount)),stack1 == null || stack1 === false ? stack1 : stack1.subtotal_product)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</span>\n			<span class=\"primary\">$"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.amount)),stack1 == null || stack1 === false ? stack1 : stack1.subtotal_install)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</span>\n		</span>\n	</span>\n</td>\n<td class=\"x-grid-cell x-field-total two-lines amount num\">\n	<span class=\"holder\">\n		<span>\n			<span class=\"primary\">$"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.amount)),stack1 == null || stack1 === false ? stack1 : stack1.total)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</span>\n			<span class=\"secondary\">"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.amount)),stack1 == null || stack1 === false ? stack1 : stack1.payment_modifier)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</span>\n		</span>\n	</span>\n\n\n\n</td>\n<td class=\"x-grid-cell x-field-action\">\n\n	<div class=\"btn-group\">\n		<a href=\"#\" class=\"md-btn md-flat table-action dropdown-toggle\" data-toggle=\"dropdown\"><i\n				class=\"mdi-navigation-more-vert i-20\"></i> </a>\n		<ul class=\"dropdown-menu dropdown-menu-scale pull-up pull-right text-left\">\n			<li>\n				<a class=\"btn-copy\" title=\"Copy\">\n					<i class=\"mdi-content-content-copy i-16 mr10\"></i>Copy</a>\n			<li>\n			<li>\n				<a class=\"btn-delete\" href=\"#\" title=\"Delete\"><i class=\"mdi-action-delete mr10 i-16\"></i>Delete</a>\n			</li>\n\n		</ul>\n	</div>\n\n\n\n\n\n		<!--<a class=\"btn-link mr10\" href=\""
    + escapeExpression((helper = helpers.url || (depth0 && depth0.url),options={hash:{},data:data},helper ? helper.call(depth0, "quote#edit", (depth0 && depth0.id), options) : helperMissing.call(depth0, "url", "quote#edit", (depth0 && depth0.id), options)))
    + "\" title=\"Edit\"><i class=\"md md-edit\"></i></a>-->\n\n\n\n</td>\n\n\n\n";
  return buffer;
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}