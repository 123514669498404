var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"vbox\">\n	<div class=\"wrapper-xs b-b\">\n		<div class=\"input-group m-b-xxs\">\n			<span class=\"input-group-addon input-sm no-border no-bg\"><i\n					class=\"icon-magnifier text-md m-t-xxs\"></i></span>\n			<input type=\"text\" class=\"form-control input-sm no-border no-bg text-md\"\n			       placeholder=\"Search All Contacts\"  id=\"client-search\">\n		</div>\n	</div>\n	<div class=\"row-row\">\n		<div class=\"cell scrollable hover\">\n			<div class=\"cell-inner\">\n\n				<div id=\"clientList\" style=\"height:100%; overflow-y: scroll;\"\n				     class=\"list-group list-group-lg no-radius no-border no-bg m-b-none\">\n\n				</div>\n\n				<div class=\"text-center pos-abt w-full ng-hide\" style=\"top: 50%; display: none;\">No Contacts\n				</div>\n			</div>\n		</div>\n	</div>\n	<div class=\"wrapper b-t text-center\" id=\"clientListPager\">\n		<a href=\"#\" class=\"btn btn-sm btn-icon btn-x prev-page\"><i class=\"md md-chevron-left\"></i> </a>\n		<a href=\"\" class=\"btn btn-sm btn-default btn-addon\" ng-click=\"createItem()\"><i\n				class=\"fa fa-plus fa-fw m-r-xs\"></i> New Contact</a>\n		<a href=\"#\" class=\"btn btn-sm btn-icon btn-x next-page\"><i class=\"md md-chevron-right\"></i> </a>\n	</div>\n</div>";
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}