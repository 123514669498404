/**
 * Created by soundview on 5/22/15.
 */
var __bind = function (fn, me) {
	return function () {
		return fn.apply(me, arguments);
	};
},
__hasProp  = {}.hasOwnProperty,
__extends  = function (child, parent) {
	for (var key in parent) {
		if (__hasProp.call(parent, key)) child[key] = parent[key];
	}
	function ctor() {
		this.constructor = child;
	}

	ctor.prototype = parent.prototype;
	child.prototype = new ctor();
	child.__super__ = parent.prototype;
	return child;
};

var User = require('models/user');
var LoginView = require('views/login');
var mediator = Chaplin.mediator;
var Google = require('lib/services/google');

var SessionController = Chaplin.Controller.extend({
	// Was the login status already determined?
	loginStatusDetermined: false,

	// This controller governs the LoginView
	loginView: null,

	// Current service provider
	serviceProviderName: null,
	initialize: function() {
		this.subscribeEvent('serviceProviderSession', this.serviceProviderSession);
		this.subscribeEvent('logout', this.logout);
		this.subscribeEvent('userData', this.userData);
		this.subscribeEvent('!showLogin', this.showLoginView);
		this.subscribeEvent('!login', this.triggerLogin);
		this.subscribeEvent('!logout', this.triggerLogout);
		return this.getSession();
	},
	createUser: function(userData) {
		return mediator.user = new User(userData);
	},
	getSession: function () {
		var name, serviceProvider, _ref, _results;
		this.loadServiceProviders();
		_ref = SessionController.serviceProviders;
		_results = [];
		for (name in _ref) {
			serviceProvider = _ref[name];
			_results.push(serviceProvider.done(serviceProvider.getLoginStatus));
		}
		return _results;
	},
	showLoginView: function () {
		if (this.loginView) {
			return;
		}
		this.loadServiceProviders();
		return this.loginView = new LoginView({
			serviceProviders: SessionController.serviceProviders
		});
	},
	triggerLogin: function (serviceProviderName) {
		var serviceProvider;
		serviceProvider = SessionController.serviceProviders[serviceProviderName];
		if (!serviceProvider.isLoaded()) {
			mediator.publish('serviceProviderMissing', serviceProviderName);
			return;
		}
		mediator.publish('loginAttempt', serviceProviderName);
		return serviceProvider.triggerLogin();
	},
	serviceProviderSession: function (session) {
		this.serviceProviderName = session.provider.name;
		this.disposeLoginView();
		session.id = session.userId;
		delete session.userId;
		this.createUser(session);
		return this.publishLogin();
	},
	publishLogin: function () {
		this.loginStatusDetermined = true;
		mediator.publish('login', mediator.user);
		return mediator.publish('loginStatus', true);
	},
	triggerLogout: function () {
		return mediator.publish('logout');
	},

	logout: function () {
		this.loginStatusDetermined = true;
		this.disposeUser();
		this.serviceProviderName = null;
		this.showLoginView();
		return mediator.publish('loginStatus', false);
	},

	userData: function (data) {
		return mediator.user.set(data);
	},

	disposeLoginView: function () {
		if (!this.loginView) {
			return;
		}
		this.loginView.dispose();
		return this.loginView = null;
	},

	disposeUser: function () {
		if (!mediator.user) {
			return;
		}
		mediator.user.dispose();
		return mediator.user = null;
	}


});

module.exports = SessionController;