/**
 * Created by soundview on 3/9/15.
 */
var sizeTemplate = _.template('\
	<div>\
    <div  class="form-group floating-label field-<%= key %>">\
	<div class="input-group">\
		<div data-editor class="input-group-content">\
	        <label class="control-label" for="<%= editorId %>">\
				<% if (titleHTML){ %><%= titleHTML %>\
				<% } else { %><%- title %><% } %>\
			</label>\
		</div>\
		<span class="input-group-addon">in.</span>\
	    <p class="help-block" data-error></p>\
	    <p class="help-block"><%= help %></p>\
	    </div>\
    </div>\
    </div>\
  ');
var moneyTemplate = _.template('\
	<div>\
    <div data-editor class="form-group floating-label append-icon field-<%= key %>">\
	        <label class="control-label" for="<%= editorId %>">\
				<% if (titleHTML){ %><%= titleHTML %>\
				<% } else { %><%- title %><% } %>\
			</label>\
			<i class="fa fa-usd fa-lg"></i>\
	    <p class="help-block" data-error></p>\
	    <p class="help-block"><%= help %></p>\
    </div>\
    </div>\
  ');

module.exports = Chaplin.Model.extend({
	urlRoot: '/index.php/api/v1/quoteitems',
	url: function () {
		var url;
		if(this.id) {
			url = this.urlRoot + '/' + this.id;
		} else {
			url = this.urlRoot;
		}
		return url;
	},
	initialize: function() {
		this.on('change:id', function(){
			return Backbone.trigger('quoteitem:created', this);
		});
		Backbone.Form.validators.errMessages.required = '<i class="fa fa-exclamation-circle"></i>';
	},
	schema: {
		name: {
			title: 'Name',
			type: 'Text',
			validators: ['required'],
			fieldClass: 'col-xs-8 col-sm-8 col-md-8'
		},
		price: {
			titleHTML: 'Price (per ft<sup>2</sup>)',
			type: 'Number',
			validators: ['required'],
			fieldClass: 'col-sm-6',
			template: moneyTemplate

		},
		labor: {title: 'Labor Price', type: 'Number', fieldClass: 'col-sm-6', template: moneyTemplate},
		width: {
			title: 'Width (in.)',
			type: 'Number',
			fieldClass: 'col-sm-4 col-md-4 col-xs-6 x-field-num',
			template: sizeTemplate
		},
		height: {
			title: 'Height (in.)',
			type:'Number',
			fieldClass: 'col-sm-4 col-md-4 col-xs-6 x-field-num',
			template: sizeTemplate
		},
		thickness: {
			title: 'Thickness (in.)',
			type: 'Number',
			fieldClass: 'col-sm-4',
			template: sizeTemplate
		},
		service: { title: 'Service', type: 'Select', editorClass: 'service', fieldClass:'col-sm-6', options: [{ val: null, label: null},{val: 'glass', label: 'Glass Replacement'},{val: 'skylight', label: 'Skylight'},{val: 'board_up', label: 'Board Up'}] },
		service_type: { title: 'Service Type', type: 'Select', editorClass: 'service_type', fieldClass: 'col-sm-6', options: [{val:null, label: null}]},
		floor: { fieldClass: 'col-md-4 col-sm-4 col-xs-4', title: 'Floor', type: 'Select', editorClass: 'floor', options: [{val: null, label: null},{val: 'first', label: 'First'}, {val: 'second', label: 'Second'}, {val: 'third', label: 'Third'}]},
		description: { title: 'Description', type: 'TextArea', fieldClass: 'col-sm-12', editorAttrs: { resize: 'vertical', rows: '5'}}
	},
	defaults: {
		tax_rate_id: 0,
		quantity: 1,
		display_order: 1,
		description: '',
		service: null,
		service_type: null,
		floor: null,
		thickness: 0
	},
	services: [{
		id: 1,
		val: 'glass',
		label: 'Glass Replacement',
		selected: false,
		children: [
			{
				val: 'g_glass_only',
				label: 'Glass Only'
			}, {
				val: 'g_vinyl',
				label: 'Vinyl'
			}, {
				val: 'g_wood',
				label: 'Wood'
			}, {
				val: 'g_aluminium',
				label: 'Aluminium'
			}
		]
	}, {
		id: 2,
		val: 'skylight',
		label: 'Skylight Replacement',
		selected: false,
		children: [
			{
				val: 'sl_vinyl',
				label: 'Vinyl'
			}, {
				val: 'sl_wood',
				label: 'Wood'
			}, {
				val: 'sl_aluminium',
				label: 'Aluminium'
			}
		]

	}, {
		id: 3,
		val: 'board_up',
		label: 'Board Up',
		selected: false,
		children: [
			{
				val: 'bu_vinyl',
				label: 'Vinyl'
			}, {
				val: 'bu_wood',
				label: 'Wood'
			}, {
				val: 'bu_aluminium',
				label: 'Aluminium'
			}
		]
	}],
	formatSize: function (size) {
		var inch, feet;
		feet = parseInt(size/12);
		inch = size % 12;

		return feet + "' " + inch + '"';
	},
	serialize: function () {
		var json = Chaplin.Model.prototype.serialize.apply(this, arguments);

		json.__proto__.service_label = _.findWhere(this.services, { val: this.get('service')});
		if(this.get('service_type')) {
			json.__proto__.service_type_label = _.findWhere(_.findWhere(this.services, { val: this.get('service')}).children, {val: this.get('service_type')});
		}


		json.__proto__.cid = this.cid;
		return json;
	}
});