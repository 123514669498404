/**
 * Created by soundview on 6/11/15.
 */

var Model = require('./model');

var ItemAttrCollection = Chaplin.Collection.extend({
	// Mixin a synchronization state machine.
	//initialize: function(options) {
	//	options || (options = {});
	//	this.itemId = options.itemId;
	//   //_.extend(this, Chaplin.SyncMachine);
	//   //Chaplin.Collection.prototype.initialize.apply(this, arguments);
	//   //this.on('request', this.beginSync);
	//   //this.on('sync', this.finishSync);
	//   //this.on('error', this.unsync);
	//},
	urlRoot: '/index.php/api/v1/w_item_attrs',
	itemId: '',
	//url: '/index.php/api/v1/w_items',
	url: function(){
		//if(!this.itemId){
		//	return this.urlRoot;
		//} else {
		//	return this.urlRoot + '?itemId=' + this.itemId;
		//}
		return this.urlRoot;

	},
	model: Model
});

module.exports = ItemAttrCollection;