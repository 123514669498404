var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<div class=\"row-row\">\n	<div class=\"cell bg-white-only\">\n		<div class=\"cell-inner\">\n			<div class=\"wrapper\">\n				<table class=\"table table-condensed td-vm table-hover fixed-header\" fixed-header>\n\n					<thead>\n					<tr>\n						<th class=\"status\"><span>STATUS</span></th>\n						<th><span>QUOTE #</span></th>\n						<th><span>CREATED</span></th>\n						<th><span>EXPIRES</span></th>\n						<th><span>CLIENT</span></th>\n						<th class=\"amount num\" style=\"text-align: right; padding-right: 25px;\">\n							<span>AMOUNT</span></th>\n						<th class=\"x-field-action\"><span></span></th>\n					</tr>\n					</thead>\n\n					<tbody class=\"table_view\" id=\"";
  if (helper = helpers.listSelector) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.listSelector); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" data-list=\"";
  if (helper = helpers.listSelector) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.listSelector); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n\n					</tbody>\n\n\n				</table>\n			</div>\n		</div>\n	</div>\n</div>\n<footer id=\"quoteFooter\" flex class=\"footer bg-white b-t\" layout=\"row\">\n	<div flex>\n		<a class=\"md-btn pull-left md-btn-icon md-flat md-flat-dk waves-effect mt10 prev-page\">\n			<i class=\"mdi-navigation-chevron-left i-20\"></i>\n		</a>\n	</div>\n	<div flex>\n		<div style=\"width:100%;\" class=\"text-center page-number pt15\">\n			Page:\n			<span class=\"\" id=\"currentPage\"></span>\n			<span>of</span>\n			<span class=\"\" id=\"totalPages\"></span>\n\n\n		</div>\n	</div>\n	<div flex>\n		<a class=\"md-btn pull-right md-btn-icon md-flat md-flat-dk waves-effect mt10 next-page\">\n			<i class=\"mdi-navigation-chevron-right i-20\"></i>\n		</a>\n	</div>\n\n\n</footer>\n\n\n\n\n\n";
  return buffer;
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}