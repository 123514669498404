/**
 * Created by soundview on 5/1/15.
 */
var styles = [{
		'NAME': 'CASEMENT',
		'LABEL': 'CASEMENT',
		'PROD_LINES': {
			'100SERIES': '100SERIES',
			'200SERIES': '200SERIES'
		}
	}, {
		'NAME': 'PICTURE',
		'LABEL': 'PICTURE',
		'PROD_LINES': {
			'ESSENCE': 'ESSENCE',
			'TUSCANY': 'TUSCANY'
		}
	}
];


module.exports = styles;