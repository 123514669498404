/**
 * Created by soundview on 3/9/15.
 */
/**
 * Created by soundview on 3/5/15.
 */
var View = require('views/base/view');
var Model = require('../model');
var QuoteInfoView = require('./info-view');
var QuoteItem = require('window-items/model');
var QuoteItemsView = require('window-items/list/collection-view');


var QuoteItemEditView = require('window-items/edit/view');
var optionsConfig = require('window-items/options-config');

var EditClientView = require('../edit/view');

var QuoteTable = require('modules/clients/quote_table/table');

module.exports = View.extend({
	autoRender: true,
	className: 'vbox',
	region: 'main',
	transition: true,
	regions: {
		'header': '#header-view',
		'info': '#info-panel'
	},

	id: 'app-content-body',
	template: require('./templates/view'),
	events: {
		'click .edit-client-btn': '_toEdit',
		'click .item-pdf-btn': '_toPdf',
		'click .back_btn': '_backPath',
		'click #quoteTabs a': '_onTabClick'
		//'click .toggle-edit': 'toggleEdit'
	},
	listen: {
		//'toView collection': '_viewItem',
		//'toViewGroup collection': '_viewGroup',
		'toEdit items': '_editItem',
		'onSave items': '_onSave'
		//'onSave items': 'refresh'
		//'toDelete collection': '_deleteItem'
	},
	bindings: {
		'#footer': {
			observe: 'footer',
			events: ['blur'],
			onSet: function (val) {
				this.model.set("footer", val);
				console.log(this.model.changed);
				this.saveAttr();
			},
			afterUpdate: function ($el) {
				//$el.prop('disabled', true);
			}
		},
		'#terms': {
			observe: 'terms',
			events: ['blur'],
			onSet: function (val, options) {
					this.model.set("terms", val);
					console.log(this.model.changed);
					this.saveAttr();
				//this.model.trigger('save', this.model);
			},
			afterUpdate: function ($el) {
				//$el.prop('disabled', true);
			}
		},
		'#input__brand': {
			observe: 'brand',
			//events: ['blur'],
			initialize: function ($el) {
				$el.select2({
					width: "100%",
					placeholder: 'Select Brand...', allowClear: true,
					minimumResultsForSearch: Infinity
				});
			},
			selectOptions: {
				collection: function () {
					return optionsConfig.brands;
				},
				valuePath: 'id',
				labelPath: 'text',
				defaultOption: {
					label: null,
					value: null
				}
			},
			onSet: function (val) {
				//this.model.set('service_type', null);
				this.model.set("brand", val);
				//console.log(this.model.changed);
				this.saveAttr();
				this.$('.input__product_line').select2('val', null);
				return val;
			}
		},
		'#input__product_line': {
			observe: ['product_line', 'brand'],
			//events: ['blur'],
			initialize: function ($el) {
				$el.select2({
					width: "100%",
					placeholder: 'Select Product Line..',
					allowClear: true,
					minimumResultsForSearch: Infinity
				});
				if (this.model.get('product_line') != null) {
					$el.select2('val', this.model.get('product_line'));
				}
			},
			selectOptions: {
				collection: function () {
					var brandId = this.model.get('brand');
					var brandList = _.find(optionsConfig.brands, function (item) {
						return item.id === brandId;
						//console.log(item);
					});
					//var state = _.find(this.services, {id: 2});

					return brandList ? brandList.PROD_LINES : '';
				},
				valuePath: 'id',
				labelPath: 'text',
				defaultOption: {
					label: null,
					value: null
				}
			},
			onSet: function (val) {
				this.model.set('product_line', val);
				this.saveAttr();
				return val;
			}
		},
		'#input__material': {
			observe: 'material',
			//events: ['change'],
			initialize: function ($el) {
				$el.select2({
					width: "100%", placeholder: "Select Material...", allowClear: true,
					minimumResultsForSearch: Infinity
				});
				if (this.model.get("material") != null) {
					$el.select2("val", this.model.get("material"));
				}
			},
			selectOptions: {
				collection: function () {
					return optionsConfig.materials;
				},
				valuePath: 'id',
				labelPath: 'text',
				defaultOption: {
					label: null,
					value: null
				}
			},
			onSet: function (val) {
				this.model.set('material', val);
				this.saveAttr();
				return val;
			}
		},
		'#input__interior_finish': {
			observe: 'interior_finish',
			events: ['blur'],
			onSet: function (val, options) {
				this.model.set(options.observe, val);
				this.saveAttr();
				//this.model.trigger('save', this.model);
			}
		},
		'#input__exterior_finish': {
			observe: 'exterior_finish',
			events: ['blur'],
			onSet: function (val, options) {
				this.model.set(options.observe, val);
				this.saveAttr();
			}
		},
		'#input__hardware_type': {
			observe: 'hardware_type',
			events: ['blur'],
			onSet: function (val, options) {
				this.model.set(options.observe, val);
				this.saveAttr();
			}
		},
		'#input__hardware_finish': {
			observe: 'hardware_finish',
			events: ['blur'],
			onSet: function (val, options) {
				this.model.set(options.observe, val);
				this.saveAttr();
			}
		},
		'#input__glass': {
			observe: 'glass',
			events: ['blur'],
			onSet: function (val, options) {
				this.model.set(options.observe, val);
				this.saveAttr();
			}
		},
		'#input__grid_pattern': {
			observe: 'grid_pattern',
			events: ['blur'],
			onSet: function (val, options) {
				this.model.set(options.observe, val);
				this.saveAttr();
				//this.model.trigger('save', this.model);
			}
		},
		'#input__interior_caulk': {
			observe: 'interior_caulk',
			events: ['blur'],
			onSet: function (val, options) {
				this.model.set(options.observe, val);
				this.saveAttr();
				//this.model.trigger('save', this.model);
			}
		},
		'#input__exterior_caulk': {
			observe: 'exterior_caulk',
			events: ['blur'],
			onSet: function (val, options) {
				this.model.set(options.observe, val);
				this.saveAttr();
				//this.model.trigger('save', this.model);
			}
		},
		'#input__job_outline': {
			observe: 'job_outline',
			events: ['blur'],
			onSet: function (val, options) {
				this.model.set(options.observe, val);
				this.saveAttr();
				//this.model.trigger('save', this.model);
			}
		},
		'#input__job_name': {
			observe: 'job_name',
			events: ['blur'],
			onSet: function (val, options) {
				this.model.set(options.observe, val);
				this.saveAttr();
				//this.model.trigger('save', this.model);
			}
		}
	},




	initialize: function (options) {
		//this.quote = this.model.fetch();
		//this.quote = new Collection();
		//this.collection.url = '/index.php/api/clients';
		//this.model.fetch();
		//this._initMainContainers();
		this.model = options.model;
		this.quotes = options.quotes;

		console.log(this.quotes);
		//this.items = options.items;
		this.infoTemplate = require('views/quote/templates/quote-info');



		//console.log('model:');console.log(this.model);
		//console.log(this.items);
		//this.listenTo(this, 'render', this._initMainContainers());

		//this.listenTo(this.getCollection(), 'reset', this.renderList);
		//this.listenTo(this.clientSearch, 'on', this.renderList);
		//this.model.on("change", function (model) {
		//	console.log(model.get('number'));
		//});
		//
		//this.model.fetch();

		//this._initMainContainers();
		//this.$el.append(this.template);
		//this.initItems();
		//this._initMainContainers();
		console.log(this.$infoContainer);

		//this.items.on('toEdit', this._editItem);

		//this.listenTo(this.items, 'toEdit', this._editItem);
		this.listenTo(Backbone, 'quoteitem:created', this.refresh);

		//this.listenTo(this.items, 'updated', this.refresh);
		//this.listenTo(this.items, 'destroy', this.refresh);

		//this.listenTo(this.model, 'save', this.reloadInfo);
		this.listenTo(this.model, 'updated', this.reloadInfo);
		//this.listenTo(this.items, 'updated', this.reloadInfo);
		//this.listenTo(this.model, 'change:footer', this.saveAttr);

		//this.listenTo(this.model, 'sync', this.reloadInfo);


		//this.listenTo(this.items, 'add', this.refresh);


		//this.items.on('save', this.refresh());


	},

	init: function() {

	},
	_initMainContainers: function () {
		//this.$el.append(this.template);
		this.$infoContainer = this.$el.find('#info-panel');
		this.$itemsContainer = this.$el.find('#items_container');
		this.$windowContainer = this.$el.find('#window_container');
		this.$glassContainer = this.$el.find('#glass_container');
		this.$insulationContainer= this.$el.find('#insulation_container');
		//this.$dialogHolder = $('<div id="dialog-holder"/>');
		//this.$el.append(this.$dialogHolder);

		this.$dialogHolder = $('<div id="dialog-holder" />');
		$('body').append(this.$dialogHolder);


	},
	initItems: function() {
		//var items = this.model.get('items');
		//this.items = new QuoteItemCollection({models: this.model.attributes.items});


	},
	_toPdf: function() {
		var url = "http://glass.soundviewseattle.org/w_quotes/"+this.model.get('id')+"/pdf";
		window.open(url, "_blank");
	},
	_backPath: function () {
		window.history.back();
	},
	_addItem: function() {
		var item = new QuoteItem({
			quote_id: this.model.get('id'),
			brand: this.model.get('brand'),
			product_line: this.model.get('product_line'),
			material: this.model.get('material'),
			interior_finish: this.model.get('interior_finish'),
			exterior_finish: this.model.get('exterior_finish'),
			hardware_type: this.model.get('hardware_type'),
			hardware_finish: this.model.get('hardware_finish'),
			glass: this.model.get('glass'),
			grid_pattern: this.model.get('grid_pattern')
		});
		////this.items.add(item);
		//this._editItem(item);
		Chaplin.utils.redirectTo('window#createItem', {model: item})
	},
	_toEdit: function() {
		this.$editView = new EditClientView({
			model: this.model
		});
		//this.listenTo(this.$editView, 'onClose', this.testOnClose);

		console.log(this.$dialogHolder);
		this.$el.append(this.$editView.render().show());
	},

	_editItem: function (model) {
		this.itemEdit = new QuoteItemEditView({model: model, container: this.$dialogHolder, id: 'w_edit_item-' + model.get('id')});
		//Chaplin.utils.redirectTo('quote#edit', {quoteId: model.get('quoteId'), itemId: model.get('id')});
	},
	_onTabClick: function(e) {
		var quotes = $(e.currentTarget).data('quotes');

		console.log(this.subview(quotes));
		if(!this.subview(quotes)){
			if(quotes == 'glassQuotes') {
				this.renderGlassQuotes();
			} else if(quotes == 'insulationQuotes'){
				this.renderInsulationQuotes();
			}
		}

		console.log(e);
	},

	renderInfo: function(){
		this.quoteInfo = new QuoteInfoView({autoRender: true, model: this.model, region: 'info'});
		this.subview('quoteInfo', this.quoteInfo);
		//this.$infoContainer.append(this.quoteInfo.render());
		//this.$infoContainer.append(this.infoTemplate(this.model.toJSON()));
	},

	renderWindowQuotes: function() {
		this.windowQuotes = new QuoteTable({collection: this.quotes.windows, el: this.$windowContainer, container: this.$windowContainer, listSelector: '#window_quotes_view'});
		this.subview('windowQuotes', this.windowQuotes);
	},
	renderGlassQuotes: function () {
		this.glassQuotes = new QuoteTable({
			collection: this.quotes.glass,
			el: this.$glassContainer,
			container: this.$glassContainer,
			listSelector: '#glass_quotes_view'
		});
		this.subview('glassQuotes', this.glassQuotes);
	},
	renderInsulationQuotes: function () {
		this.insulationQuotes = new QuoteTable({
			collection: this.quotes.insulation,
			el: this.$insulationContainer,
			container: this.$insulationContainer,
			listSelector: '#insulation_quotes_view'
		});
		this.subview('insulationQuotes', this.insulationQuotes);
	},

	renderItems: function() {
		this.quoteItems = new QuoteItemsView({autoRender: true, collection: this.items, container: this.$itemsContainer});
		//this.quoteItems.on('toEdit', _.bind(this._editItem, this));
		this.subview('quoteItems', this.quoteItems);
	},

	getModel: function() {
		return this.model;
	},

	getItems: function() {
		return this.items;
	},

	refresh: function(){
		//this.getModel().fetch({reset:true});
		//this.getItems().fetch({reset:true});
		//this.quoteItems._reload();
		console.log('refresh');

		this.items.fetch({reset: true, data: $.param({quoteId: this.items.quoteId})});
		//this.model.fetch({wait:true});
		//this.reloadInfo();
		//this.reloadInfo();
		//this.subview('quoteInfo').render();
		//this.renderInfo();
		//this.subview('quoteInfo').reload();
		//this.render();
	},
	reloadInfo: function() {
		console.log('reload-info');
		//this.model.fetch({wait:true});
		this.subview('quoteInfo').reload();
	},

	_onSave: function(model) {
		console.log('onsave-triggered' + model);
	},
	saveAttr: function(){
		//console.log(val);
		if(!_.isEmpty(this.model.changed)) {
			this.model.save(this.model.changed, {patch: true});
		}
		//console.log(this.model.attributes);
	},

	toggleEdit: function(e) {
		//console.log(e.target);
		//$(e.target).toggleClass('active');

		var $target = $($(e.currentTarget).data('target'));
		$target.prop('disabled', function (i, v) {
			return !v;
		});


	},
	render: function(){
		this.$el.append(this.template(this.model.serialize()));
		console.log(this.model.serialize());
		this._initMainContainers();

		this.renderWindowQuotes();
		//this.renderInfo();
		//this.stickit();
		//this.renderInfo();


		//this.renderItems();

		console.log('render: quote-edit-view');
	}



});

