/**
 * Created by soundview on 3/6/15.
 */
/**
 * Created by soundview on 3/5/15.
 */
var View = require('views/base/view');
var Model = require('models/quote');
var Collection = require('models/quote-collection');


module.exports = View.extend({
	autoRender: true,
	tagName: 'tr',
	container: '#quoteList',
	template: require('./templates/quote-view'),
	events: {
		'click': 'onClick'
	},
	initialize: function (options) {
		console.log(this.model);
		_.bindAll(this, 'render');
	},
	render: function () {
		this.$el.html(this.template(this.getTemplateData()));
	},
	onClick: function (a) {
		this.model.collection.trigger('toView', this.model, this);
	}


});

