/**
 * Created by soundview on 5/11/15.
 */
/**
 * Created by soundview on 3/10/15.
 */
var View = require('views/base/collection-view');
var Model = require('./model');
var Collection = require('./collection');
var ItemView = require('./item-view');
var AttachmentsCollectionView = View.extend({
	autoRender: false,

	container: null,
	autoAttach: true,
	id: "attachmentsView",
	tagName: 'div',
	className: 'modal md-dialog fade',
	selectedImage: null,

	//container: '#clientList',
	//region: 'clientList',

	listen: {
		//'toView collection': '_viewItem',
		//'toViewGroup collection': '_viewGroup',
		'onAdd collection': '_addItem'
		//'filter collection': 'render'
		//'onSave model': '_reload'
		//'toDelete collection': '_deleteItem'
	},
	events: {
		'click #filter_set a': '_filterCollection',
		'click a.toggle_list': '_toggleList',
		'click .item-save-btn': 'close'
	},
	itemView: ItemView,
	doNotFetch: false,
	activeFilter: null,
	listSelector: '#attachments-list',
	template: require('./templates/collection-view'),
	fallbackSelector: '.no-data',
	loadingSelector: '.loading-mask',
	attribute: {
		role: "dialog",
		'aria-labelledby': "widgetTitle",
		'aria-hidden': "true"
	},
	bindings: {
		'#filterTitle': {
			observe: 'activeFilter',
		}
	},
	initialize: function (options) {

		_.bindAll(this, 'render', 'afterRender');
		var _this = this;
		this.render = _.wrap(this.render, function (render) {
			render();
			_this.afterRender();
			return _this;
		});
		this.model = new Chaplin.Model({
			selectedImage: options.selectedImage,
			activeFilter: 'ALL'
		});
		//this.options = options;
		//this.$loadingContainer = $('<div class="loading-mask" />');
		//this.$el.append(this.$loadingContainer);
		this.collection = new Collection();
		this.selectedImage = options.selectedImage;
		this.modelId = options.modelId;
		console.log(options);
		this.options = _.defaults(options || {}, this.options);
		//this.template = this.options.template;

		this.getCollection();
		//this.filterer = this.activeItem()
		this.listenTo(this.collection, 'activeChange', this.activateImage);


		console.log(this.collection);

		console.log(this.collection.length);

		//this.itemView = this.options.itemView;

		AttachmentsCollectionView.__super__.initialize.call(this, options);


	},
	clickOutside: function (e) {
		if (this.outsideElement.hasClass("" + this.prefix + "-wrapper") && this.active) {
			return this.triggerCancel();
		}
	},

	clickOutsideElement: function (e) {
		return this.outsideElement = Backbone.$(e.target);
	},
	_clickItem: function(e,a,o) {
		console.log(e);
		console.log(a);
	},
	_setImage: function(model) {
		var image = model.get('formattedFile');
		this.selectedImage = image;
	},
	getSelectedImage: function() {
		return this.selectedImage ? this.selectedImage : false;
	},
	getImage: function() {
		return this.image ? this.image: false;
	},
	_setActiveImage: function() {
		var _this = this;
		console.log(this.collection);
		var activeImage = _.each(this.collection.models, function(item){
			_this.filterActive(item);
		});
		console.log(activeImage);
	},
	filterActive: function(item){
		//console.log(item);
		return item.get('formattedFile') === this.selectedImage;
	},
	activeCallback: function (view, included) {
		view.$el.toggleClass('active', included);
	},
	_addItem: function (model) {
		this.collection.add(model);
		console.log('added');
	},
	_toggleList: function(e) {
		this.$('#attachments-list').toggleClass('list_view');
		$(e.target).toggleClass('active');

	},
	_filterCollection: function(e){
		console.log(e);
		var $this = $(e.target);
		$this.is('a') || ($this = $this.closest('a'));

		var $active = $this.parent().siblings(".active");
		$active && $active.toggleClass('active');
		$this.parent().toggleClass('active');
		var filter = $this.data('filter');
		if(filter == 'ALL'){
			this.activeFilter = null;
			this.model.set('activeFilter', 'ALL');
		} else {
			this.activeFilter = filter;

			this.model.set('activeFilter', filter);
		}


		this.filter();

	},
	filterer: function(item, index) {
		var filter = this.activeFilter;
		return !this.activeFilter ? true : item.get('attachment_group') === this.activeFilter;
	},
	activeItem: function(item,index) {
		return item.get('formattedFile') === this.selectedImage;
	},
	getCollection: function() {
		this.collection.fetch({
			reset:true,
			success:function(data) {
				this.rowCount = data.length;
			}

		});
		console.log(this.collection.models.length);
	},
	//getTemplateData: function() {
	//	return this.options.model.attributes;
	//
	//},
	initItemView: function (model) {
		var active = model.formattedFile === this.selectedImage;
		//if(model.get('formattedFile') == this.selectedImage){ model.set('active', true); }
		if (this.itemView) {
			return new this.itemView({
				autoRender: false,
				model: model,
				active: active,
				attributes: {
					'data-id': model.attributes.id
				}
			});
		} else {
			AttachmentsCollectionView.__super__.render.apply(this, arguments);
		}
	},
	_getLoadingContainer: function () {
		var loadingContainer = this.loadingContainer;
		if (loadingContainer instanceof $) {
			// fetches loading container from options
			loadingContainer = loadingContainer.get(0);
		}
		if (!loadingContainer) {
			// uses the element as default loading container
			loadingContainer = this.$el.get(0);
		}
		return loadingContainer;
	},
	_showLoading: function () {
		this.subview('loading').show();
		console.log('loading');
	},

	_hideLoading: function () {
		this.subview('loading').hide();
	},
	activateImage: function (image) {
		this.image = image;
		this.selectedImage = image.get('formattedFile');
	},
	close: function () {
		this.trigger('onClose', this.selectedImage, this);

		if (typeof this.onClose === "function") {
			this.onClose();
		}
		this.dismiss();

	},
	show: function() {
		this.$el.modal('show');
	},
	dismiss: function() {
		this.$el.modal('hide');
	},
	afterRender: function() {
		var _this = this;
		this.stickit();
		this.$el.on('hidden.bs.modal', function (e) {
			// do something...
			console.log('hidden modal');
			//_this.dropZone.dropzone().destroy();
			_this.dispose();
		});
		Dropzone.autoDiscover = false;
		this.$('#uploadImageForm').attr('action', '/index.php/upload/w_item/' + _this.modelId);
		this.dropZone = this.$('#uploadImageForm').dropzone({
					url: '/index.php/upload/w_item/'+ _this.modelId,
					uploadMultiple: false,
					success: function(resp){
						console.log(resp);
						//console.log(response);
						//_this.selectedImage = response.xhr.response.fileName;
					},
					complete: function(resp){
						console.log(resp);
						_this.selectedImage = JSON.parse(resp.xhr.response);
					}
				});


	},
	//render: function(){
	//
	//
	//	this.$el.html(this.template(this.getTemplateData()));
	//
	//},

	_reload: function () {
		this._showLoading();
		if (this.options.doNotFetch) {
			this._hideLoading();
			return;
		}
		try {
			this.collection.fetch({
				reset: true,
				data: $.param({quoteId: this.collection.quoteId}),
				success: _.bind(function () {
					console.log(this.collection);
					var self = this;
					_.delay(function () {
						self._hideLoading();
						self.collection.trigger('updated', self.collection);
					}, 800);
				}, this),
				error: _.bind(function (collection, response) {
					//this._showLoadItemsError(response.responseJSON || {});
					console.log(response.responseJSON);
				}, this)
			});
		} catch (err) {
			//this._showLoadItemsError(err);
			console.log(err);
		}
	}
});
module.exports = AttachmentsCollectionView;