/**
 * Created by soundview on 3/9/15.
 */
/**
 * Created by soundview on 3/5/15.
 */
var View = require('views/base/view');
var Model = require('models/quote');
var QuoteInfoView = require('views/quote/quote-info');
var QuoteItem = require('models/quote-item');
var QuoteItemsView = require('views/quote-item/quote-items-view');
var QuoteItemCollection = require('models/quote-item-collection');
var QuoteItemEditView = require('views/quote-item/quote-item-edit');


module.exports = View.extend({
	autoRender: true,
	className: 'box',
	region: 'main',
	transition: true,
	regions: {
		'header': '#header-view',
		'info': '#info-panel'
	},

	id: 'app-content-body',
	template: require('./templates/quote-edit'),
	events: {
		'click .add-item-btn': '_addItem',
		'click .item-pdf-btn': '_toPdf',
		'click .back_btn': '_backPath'
		//'click .toggle-edit': 'toggleEdit'
	},
	listen: {
		//'toView collection': '_viewItem',
		//'toViewGroup collection': '_viewGroup',
		'toEdit items': '_editItem',
		'onSave items': '_onSave'
		//'onSave items': 'refresh'
		//'toDelete collection': '_deleteItem'
	},
	bindings: {
		'#footer': {
			observe: 'footer',
			events: ['blur'],
			onSet: function (val) {
				this.model.set("footer", val);
				console.log(this.model.changed);
				this.saveAttr();
			},
			afterUpdate: function ($el) {
				//$el.prop('disabled', true);
			}
		},
		'#terms': {
			observe: 'terms',
			events: ['blur'],
			onSet: function (val, options) {
					this.model.set("terms", val);
					console.log(this.model.changed);
					this.saveAttr();
				//this.model.trigger('save', this.model);
			},
			afterUpdate: function ($el) {
				//$el.prop('disabled', true);
			}
		}
	},




	initialize: function (options) {
		//this.quote = this.model.fetch();
		//this.quote = new Collection();
		//this.collection.url = '/index.php/api/clients';
		//this.model.fetch();
		//this._initMainContainers();
		this.model = options.model;
		this.items = options.items;
		this.infoTemplate = require('views/quote/templates/quote-info');



		console.log('model:');console.log(this.model);
		console.log(this.items);
		//this.listenTo(this, 'render', this._initMainContainers());

		//this.listenTo(this.getCollection(), 'reset', this.renderList);
		//this.listenTo(this.clientSearch, 'on', this.renderList);
		//this.model.on("change", function (model) {
		//	console.log(model.get('number'));
		//});
		//
		//this.model.fetch();

		//this._initMainContainers();
		//this.$el.append(this.template);
		this.initItems();
		//this._initMainContainers();
		console.log(this.$infoContainer);

		//this.items.on('toEdit', this._editItem);

		this.listenTo(this.items, 'toEdit', this._editItem);
		this.listenTo(Backbone, 'quoteitem:created', this.refresh);

		this.listenTo(this.items, 'updated', this.refresh);
		this.listenTo(this.items, 'destroy', this.refresh);

		//this.listenTo(this.model, 'save', this.reloadInfo);
		this.listenTo(this.items, 'updated', this.reloadInfo);
		//this.listenTo(this.model, 'change:footer', this.saveAttr);

		//this.listenTo(this.model, 'sync', this.reloadInfo);


		//this.listenTo(this.items, 'add', this.refresh);


		//this.items.on('save', this.refresh());


	},

	init: function() {

	},
	_initMainContainers: function () {
		//this.$el.append(this.template);
		this.$infoContainer = this.$el.find('#info-panel');
		this.$itemsContainer = this.$el.find('#items_container');
		//this.$dialogHolder = $('<div id="dialog-holder"/>');
		//this.$el.append(this.$dialogHolder);

		this.$dialogHolder = $('<div id="dialog-holder" />');
		$('body').append(this.$dialogHolder);


	},
	initItems: function() {
		//var items = this.model.get('items');
		//this.items = new QuoteItemCollection({models: this.model.attributes.items});


	},
	_toPdf: function() {
		var url = "/index.php/quotes/"+this.model.get('id')+"/pdf";
		window.open(url, "_blank");
	},
	_backPath: function () {
		window.history.back();
	},
	_addItem: function() {
		var item = new QuoteItem({quote_id: this.model.get('id')});
		////this.items.add(item);
		//this._editItem(item);
		Chaplin.utils.redirectTo('quote#createItem', {model: item})
	},

	_editItem: function (model) {
		this.itemEdit = new QuoteItemEditView({model: model, container: this.$dialogHolder, id: 'w_edit_item-' + model.get('id')});
		//Chaplin.utils.redirectTo('quote#edit', {quoteId: model.get('quoteId'), itemId: model.get('id')});
	},

	renderInfo: function(){
		this.quoteInfo = new QuoteInfoView({autoRender: true, model: this.model, region: 'info'});
		this.subview('quoteInfo', this.quoteInfo);
		//this.$infoContainer.append(this.quoteInfo.render());
		//this.$infoContainer.append(this.infoTemplate(this.model.toJSON()));
	},

	renderItems: function() {
		this.quoteItems = new QuoteItemsView({autoRender: true, collection: this.items, container: this.$itemsContainer});
		//this.quoteItems.on('toEdit', _.bind(this._editItem, this));
		this.subview('quoteItems', this.quoteItems);
	},

	getModel: function() {
		return this.model;
	},

	getItems: function() {
		return this.items;
	},

	refresh: function(){
		//this.getModel().fetch({reset:true});
		//this.getItems().fetch({reset:true});
		//this.quoteItems._reload();
		console.log('refresh');

		this.items.fetch({reset: true, data: $.param({quoteId: this.items.quoteId})});
		//this.model.fetch({wait:true});
		//this.reloadInfo();
		//this.reloadInfo();
		//this.subview('quoteInfo').render();
		//this.renderInfo();
		//this.subview('quoteInfo').reload();
		//this.render();
	},
	reloadInfo: function() {
		console.log('reload-info');
		//this.model.fetch({wait:true});
		this.subview('quoteInfo').reload();
	},

	_onSave: function(model) {
		console.log('onsave-triggered' + model);
	},
	saveAttr: function(){
		//console.log(val);
		if(!_.isEmpty(this.model.changed)) {
			this.model.save(this.model.changed, {patch: true});
		}
		//console.log(this.model.attributes);
	},

	toggleEdit: function(e) {
		//console.log(e.target);
		//$(e.target).toggleClass('active');

		var $target = $($(e.currentTarget).data('target'));
		$target.prop('disabled', function (i, v) {
			return !v;
		});


	},
	render: function(){
		this.$el.append(this.template(this.model.serialize()));

		this._initMainContainers();
		this.renderInfo();
		this.stickit();
		//this.renderInfo();
		this.renderItems();

		console.log('render: quote-edit-view');
	}



});

