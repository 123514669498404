/**
 * Created by soundview on 3/31/15.
 */
var View = require('views/base/view');
var Model = require('../model');
var layout = require('lib/layout');
var options = require('../options-config');
var AttachmentsView = require('attachments/collection-view');
var modal3 = require('./modal3');


var AttrView = require('../attr/collection-view');
var AttrCollection = require('../attr/collection');

var FormView = require('views/templates/bootstrap3');



var select2Focus = function() {
	var select2 = $(this).data('select2');
	setTimeout(function () {
		if (!select2.opened()) {
			select2.open();
		}
	}, 0);
}

module.exports = View.extend({
	autoRender: false,
	region: 'content',
	transition: true,
	id: 'app-content-body',
	className: 'box',
	brands: options.brands,
	optConfig: options,
	template: require('./template'),
	attributes: {
		'layout' : 'column'
	},
	events: {
		'click .item-save-btn': 'submit',
		'click .item-dismiss-btn': 'dismiss',
		'click .modal-launch': '_modal',
		'click .input__image': '_modal',
		'change .input__install_addons input': 'getInstallAddonsPrice'
	},
	services: [{
		id: -1,
		val: '',
		label: '',
		children: []
	}, {
		id: 1,
		val: 'glass',
		label: 'Glass Replacement',
		selected: false,
		children: [
			{
				val: 'g_glass_only',
				label: 'Glass Only'
			}, {
				val: 'g_vinyl',
				label: 'Vinyl'
			}, {
				val: 'g_wood',
				label: 'Wood'
			}, {
				val: 'g_aluminium',
				label: 'Aluminium'
			}
		]
	}, {
		id: 2,
		val: 'skylight',
		label: 'Skylight Replacement',
		selected: false,
		children: [
			{
				val: 'sl_vinyl',
				label: 'Vinyl'
			}, {
				val: 'sl_wood',
				label: 'Wood'
			}, {
				val: 'sl_aluminium',
				label: 'Aluminium'
			}
		]

	}, {
		id: 3,
		val: 'board_up',
		label: 'Board Up',
		selected: false,
		children: [
			{
				val: 'sl_vinyl',
				label: 'Vinyl'
			}, {
				val: 'sl_wood',
				label: 'Wood'
			}, {
				val: 'sl_aluminium',
				label: 'Aluminium'
			}
		]
	}],
	bindings: {
		'.input__install_addons': {
			observe: 'install_addons',
			updateView: false
		},
		'.input__width': {
			observe: 'width'
		},
		'.input__leg_height': {
			observe: 'leg_height'
		},
		'.input__height': {
			observe: 'height'
		},
		'.input__markup': {
			observe: 'mark_up'
		},
		'.input__install_price': {
			observe: 'install_price',
		},
		'.input__msrp_install_price': {
			observe: 'msrp_install_price',
			onGet: function(val) {
				return parseFloat(val)
			}

		},
		'.input__install_addons_price': {
			observe: 'install_addons_price',

		},
		'.input__install_method': {
			observe: 'install_method',
			initialize: function ($el) {
				$el.select2({
					width: "100%", placeholder: "Select Style...",
					allowClear: true
				}).one('select2-focus', select2Focus).on("select2-blur", function () {
					$(this).one('select2-focus', select2Focus)
				});
				if (this.model.get('install_method') != null) {
					$el.select2("val", this.model.get('install_method'));
				}
			},
			selectOptions: {
				collection: function () {
					return this.optConfig.installMethods;
				},
				valuePath: 'id',
				labelPath: 'text',
				defaultOption: {
					label: null,
					value: null
				}
			},
			//onSet: function(val) {
			//	var brandId = val;
			//	var brandList = _.find(this.optConfig.installMethods, function (item) {
			//		return item.id === val;
			//		//console.log(item);
			//	});
			//	//var state = _.find(this.services, {id: 2});
			//
			//	//return brandList ? brandList.price : '';
			//	console.log(this.getInstallPrice(val));
			//	this.model.set('install_method', val);
			//	this.model.set('install_price', this.getInstallPrice(val));
			//	this.$('.input__install_price').val(this.getInstallPrice(val));
			//}
		},
		'.input__image': {
			observe: 'image'
		},
		'#imageView': {
			observe: 'image',
			update: function ($el, val, model, options) {
				$el.attr('src',val);
			}
		},
		'.input__brand': {
			observe: 'brand',
			initialize: function ($el) {
				$el.select2({
					width: "100%",
					placeholder: 'Select Brand...', allowClear: true,
					minimumResultsForSearch: Infinity
				}).one('select2-focus', select2Focus).on("select2-blur", function () {
					$(this).one('select2-focus', select2Focus)
				});
			},
			selectOptions: {
				collection: function () {
					return this.brands;
				},
				valuePath: 'id',
				labelPath: 'text',
				defaultOption: {
					label: null,
					value: null
				}
			},
			onSet: function (val) {
				this.model.set('brand', val);
				this.$('.input__product_line').select2('val', null);
				return val;
			}
		},
		'.input__product_line': {
			observe: ['product_line', 'brand'],
			updateModel: false,
			initialize: function ($el, e, o) {
				console.log(o);
				$el.select2({
					width: "100%",
					placeholder: 'Select Product Line..',
					allowClear: true,
					minimumResultsForSearch: Infinity
				}).one('select2-focus', select2Focus).on("select2-blur", function () {
					$(this).one('select2-focus', select2Focus)
				});
				if (this.model.get('product_line') != null) {
					$el.select2('val', this.model.get('product_line'), true);
				}
			},
			selectOptions: {
				collection: function () {
					var brandId = this.model.get('brand');
					var brandList = _.find(this.brands, function (item) {
						return item.id === brandId;
						//console.log(item);
					});
					//var state = _.find(this.services, {id: 2});

					return brandList ? brandList.PROD_LINES : '';
				},
				valuePath: 'id',
				labelPath: 'text',
				defaultOption: {
					label: null,
					value: null
				}
			},
			onSet: function (val) {
				this.model.set('product_line', val);
				return val;
			}
		},
		'.input__elevation': {
			observe: 'elevation',
			updateModel: false,
			initialize: function ($el) {
				$el.select2({
					width: "100%", placeholder: "Select Floor...", allowClear: true,
					minimumResultsForSearch: Infinity
				}).one('select2-focus', select2Focus).on("select2-blur", function () {
					$(this).one('select2-focus', select2Focus)
				});

				//function select2Focus() {
				//	var select2 = $(this).data('select2');
				//	setTimeout(function () {
				//		if (!select2.opened()) {
				//			select2.open();
				//		}
				//	}, 0);
				//}
				if (this.model.get("elevation") != null) {
					$el.select2("val", this.model.get("elevation"));
				}
			},
			onSet: function (val) {
				this.model.set('elevation', val);
				return val;
			}
		},
		'.input__material': {
			observe: 'material',
			initialize: function ($el) {
				$el.select2({
					width: "100%", placeholder: "Select Material...", allowClear: true,
					data: this.optConfig.materials,
					createSearchChoice: function (term, data) {
						if ($(data).filter(function () {
								return this.text.localeCompare(term) === 0;
							}).length === 0) {
							return {id: term, text: term};
						}
					},
					initSelection: function (element, callback) {
						var id = $(element).val();
						if (id !== "") {
							var data = {id: id, text: id};
							callback(data);
						}
					}
				}).one('select2-focus', select2Focus).on("select2-blur", function () {
					$(this).one('select2-focus', select2Focus)
				});
				if (this.model.get("material") != null) {
					$el.select2("val", this.model.get("material"));
				}
			},
			selectOptions: {
				collection: 'this.optConfig.materials',
				valuePath: 'id',
				labelPath: 'text',
				defaultOption: {
					label: null,
					value: null
				}
			},
			onSet: function (val) {
				this.model.set('material', val);
				return val;
			}
		},
		'.input__style': {
			observe: 'style',
			updateModel: false,
			initialize: function ($el) {
				$el.select2({
					createSearchChoice: function (term, data) {
						if ($(data).filter(function () {
								return this.text.localeCompare(term) === 0;
							}).length === 0) {
							return {id: term, text: term};
						}
					},
					initSelection: function (element, callback) {
						var id = $(element).val();
						if (id !== "") {
							var data = { id: id, text: id};
							callback(data);
						}
					},
					multiple: false,
					width: "100%", placeholder: "Select Style...",
					allowClear: true, data: this.optConfig.styles
				}).one('select2-focus', select2Focus).on("select2-blur", function () {
					$(this).one('select2-focus', select2Focus)
				});
				if (this.model.get("style")) {
					console.log('style: true');
					$el.select2('val', this.model.get('style'), true);
				}
			},
			//selectOptions: {
			//	collection: 'this.optConfig.styles',
			//	valuePath: 'NAME',
			//	labelPath: 'LABEL',
			//	defaultOption: {
			//		label: null,
			//		value: null
			//	}
			//},
			onSet: function (val) {
				this.model.set('style', val);

				return val;
			}
		},
		'.input__operation': {
			observe: ['operation'],
			//updateModel: false,
			//initialize: function ($el) {
			//	var styleId = this.model.get('style');
			//	var styleList = _.find(this.optConfig.styles, function (item) {
			//		return item.id === styleId;
			//		//console.log(item);
			//	});
			//	//var state = _.find(this.services, {id: 2});
			//
			//	var data = styleList ? styleList.CONFIGS : [{id: '', text: ''}];
			//
			//	$el.select2({
			//		createSearchChoice: function (term, data) {
			//			if ($(data).filter(function () {
			//					return this.text.localeCompare(term) === 0;
			//				}).length === 0) {
			//				return {id: term, text: term};
			//			}
			//		},
			//		multiple: false,
			//		width: "100%", placeholder: "Select Operation...",
			//		allowClear: true, data: data
			//	}).one('select2-focus', select2Focus).on("select2-blur", function () {
			//		$(this).one('select2-focus', select2Focus)
			//	});
			//	if (this.model.get("operation") != null) {
			//		$el.select2("val", this.model.get("operation"));
			//	}
			//},
			//selectOptions: {
			//	collection: function() {
			//		var styleId = this.model.get('style');
			//		var styleList = _.find(this.optConfig.styles, function (item) {
			//			return item.id === styleId;
			//			//console.log(item);
			//		});
			//		//var state = _.find(this.services, {id: 2});
			//
			//		return  styleList ? styleList.CONFIGS : '';
			//	},
			//	valuePath: 'NAME',
			//	labelPath: 'LABEL',
			//	defaultOption: {
			//		label: null,
			//		value: null
			//	}
			//},
			//onSet: function (val) {
			//	this.model.set('operation', val);
			//	return val;
			//}
		}

	},
	initialize: function(options){
		this.model = options.model;
		console.log(this.model);

		this.initContainers();

		this.render();

		console.log(this.model.get('install_addons'));
		console.log(this.model.get('install_addons_price'));
		console.log(this.model.get('msrp_install_price'));

		this.$dialogHolder = $('<div id="dialog-holder" />');
		this.$el.append(this.$dialogHolder);

		View.prototype.initialize.apply(this, arguments);


	},
	initContainers: function() {
		this.$formContainer = $('<div class="wrapper" id="item-wrapper"/>');
	},

	render: function() {

		var data = {model: this.model.toJSON()};
		data.installAddons = options.installAddons;
		console.log(data);
		this.$el.html(this.template(data));

		this.form = new Backbone.Form({
			template: require('./form-template'),
			templateData: data,
			model: this.model
		}).render();



		this.$el.find('#item-wrapper').append(this.form.el);
		console.log(this.form.$el);

		this.$attrContainer = this.form.$el.find('#attr-container');
		console.log(this.model);



		this.attrView = this.initAttrView();
		this.attrView.render();
		//this.$attrContainer.append(this.attrView.render());

		//this.subview('attrView', this.attrView);



		this.stickit();


		layout.init(this.el);


		//this.$formContainer.append(this.form.el);
	},
	initAttrView: function() {
		//var collection = new AttrCollection({itemId: this.model.get('id')});
		//collection.fetch();

		var attrView = new AttrView({itemId: this.model.get('id'), el: this.$attrContainer});

		return attrView;
	},
	submit: function () {
		var error = this.form.validate();
		if (!error) this.save();
	},
	dismiss: function() {
		this.redirectToQuote();

	},
	_fileManager: function() {
		var input_id = "image";
		window.open('/esyFileManager/index.php?u=1&cl=' + input_id, '_blank', 'width=800, height=600, scrollbars=no, resizable=yes');
	},
	_modal: function() {
		this.input_id = 'image';
		var _this = this;

		console.log('modal');
		//var Modal = Backbone.Modal.extend({
		//	template: require('./modal'),
		//	cancelEl: '.btn-cancel',
		//	submitEl: '.btn-submit',
		//	submit: function(callback) {
		//		//var has_selected_file = $('input[type=file]').filter(function () {
		//		//		return $.trim(this.value) != ''
		//		//	}).length > 0;
		//		//
		//		//if(has_selected_file){
		//		//	_this.model.set('image', _this.selectedImage);
		//		//
		//		//	console.log(has_selected_file)
		//		//} else {
		//		//	console.log('noform');
		//		//	_this.model.set('image', _this.selectedImage);
		//		//}
		//		_this.model.set('image', _this.selectedImage);
		//	},
		//	onRender: function(input_id){
		//		this.$('#fileTree').fileTree({root: './MEDIA/', script: '/jqueryFileTree.php'}, function (file) {
		//			file = file.substring(1);
		//			_this.selectedImage = file;
		//			this.$('#fileView > img').attr('src', file);
		//		});
		//		this.$('#uploadImageForm').attr('action', '/upload/w_item/' + _this.model.get('id'));
		//		this.$('#uploadImageForm').dropzone({
		//			url: '/upload/w_item/'+ _this.model.get('id'),
		//			uploadMultiple: false,
		//			success: function(resp){
		//				console.log(resp);
		//				//console.log(response);
		//				//_this.selectedImage = response.xhr.response.fileName;
		//			},
		//			complete: function(resp){
		//				console.log(resp);
		//				_this.selectedImage = JSON.parse(resp.xhr.response);
		//			}
		//		});
		//	}
		//});
		//this.attachmentsView = new AttachmentsView({selectedImage: this.model.get('image')});
		//var Modal2 = Backbone.Modal.extend({
		//	template: require('./modal'),
		//	cancelEl: '.btn-cancel',
		//	submitEl: '.btn-submit',
		//	submit: function (callback) {
		//		if(Attachments.getSelectedImage()){
		//			_this.selectedImage = Attachments.getSelectedImage();
		//			_this.model.set('image', _this.selectedImage);
		//		}
		//		if (Attachments.getImage()) {
		//			_this.image = Attachments.getImage();
		//		}
		//		//var has_selected_file = $('input[type=file]').filter(function () {
		//		//		return $.trim(this.value) != ''
		//		//	}).length > 0;
		//		//
		//		//if(has_selected_file){
		//		//	_this.model.set('image', _this.selectedImage);
		//		//
		//		//	console.log(has_selected_file)
		//		//} else {
		//		//	console.log('noform');
		//		//	_this.model.set('image', _this.selectedImage);
		//		//}
		//	},
		//	onShow: function (input_id) {
		//		console.log(this.$el);
		//		Attachments.render();
		//	},
		//	onDestroy: function() {
		//		Attachments.dispose();
		//		console.log('destroy');
		//	},
		//	beforeCancel: function() {
		//		if (Attachments.getSelectedImage()) {
		//			console.log('hasSelectedImage');
		//			_this.selectedImage = Attachments.getSelectedImage();
		//			_this.model.set('image', _this.selectedImage);
		//		}
		//		console.log('beforecancel');
		//	}
		//});
		this.$attachmentsView = new AttachmentsView({
			selectedImage: this.model.get('image'),
			modelId: this.model.get('id')
		});
		this.listenTo(this.$attachmentsView, 'onClose', this.testOnClose);
		//this.$attachmentsView.onClose = function(){
		//	console.log(this.selectedImage);
		//};
		console.log(this.$dialogHolder);
		this.$el.append(this.$attachmentsView.render().show());
		//console.log(this.attachmentsView);
		//modalView.$('#elFinder').elFinder({url: '/elFinder/connector/connector.minimal.php'});

		//modalView.$('#fileTree').fileTree({root: './MEDIA/', script: '/jqueryFileTree.php'}, function (file) {
		//	file = file.substring(1);
		//	_this.selectedImage = file;
		//	this.$('#fileView > img').attr('src', file);
		//});
	},
	testOnClose: function(image) {
			if(image){
				this.selectedImage = image;
				this.model.set('image', image);
			};
		//this.$attachmentsView.dispose();
	},
	getInstallPrice: function(installId) {
		var _this = this;
		var methodList = _.find(this.optConfig.installMethods, function (item) {
			return item.id === installId;
			//console.log(item);
		});
		console.log(methodList);
		var mPrices;
		if(methodList.id == 'IGU') {
			var mater = this.model.attributes.material;
			mPrices = _.find(methodList.prices, function (item) {
				return item.material === _this.model.get('material');
				//console.log(item);
			});
			console.log(mPrices);
			console.log(this.model.squareFt <= 10);
			console.log(this.model.squareFt > 10 <= 25);;
			if(this.model.squareFt <= 10){
				return mPrices.base;
			} else if(this.model.squareFt > 10 <= 25){
				return mPrices.level2;
			} else if (this.model.squareFt > 25) {
				return mPrices.level3;
			}
		}
		//var state = _.find(this.services, {id: 2});

		//return brandList ? brandList.price : '';
	},
	getInstallAddonsPrice: function() {
		var data = this.form.getValue('install_addons');

		console.log(data);
		var _this = this;
		var prices = [];
		var price = 0;


		var installAddons = _.each(data, function (item) {
			var array;
			array = _.find(_this.optConfig.installAddons, function (install) {
				return install.val === item;
			});
			price = price + array.PRICE;
			//console.log(price);
			prices.push(array);
		});

		//console.log(this.model.get('install_addons_price'));

		this.model.set('install_addons_price', price);

		console.log(prices);
		console.log(price);
	},
	save: function() {

		this.getInstallAddonsPrice();
		var data = this.form.getValue();

		console.log(data);
		//this.model.set(data);
		//this.model.on('save', this.onSave());
		//this.model.save(data, {patch: true});
		console.log(data.install_addons);
		var _this = this;
		var prices = [];
		var price = 0;



		//var installAddons = _.each(data.install_addons, function(item){
		//	var array;
		//	array = _.find(_this.optConfig.installAddons, function(install){
		//		return install.val === item;
		//	});
		//	price = price + array.PRICE;
		//	//console.log(price);
		//	prices.push(array);
		//});
		//
		//data.install_addons_price = price;
		//	//console.log(data.msrp_install_price);
		//console.log(data);
		//data.msrp_install_price += price;
		////console.log(data.msrp_install_price);
		this.model.save(data, {
			wait: true,
			patch: true,
			success: _.bind(function () {
				console.log('success');
				this.redirectToQuote();

			}, this)
		});

	},
	redirectToQuote: function() {
		Chaplin.utils.redirectTo('window#show', {quoteId: this.model.get('quote_id')});
	}
});