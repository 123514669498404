/**
 * Created by soundview on 3/6/15.
 */
/**
 * Created by soundview on 3/5/15.
 */
var View = require('views/base/view');
var Model = require('models/client');
var Collection = require('models/client-collection');
var ClientView = require('views/client/client-view');


module.exports = View.extend({
	autoRender: true,
	el: '#clientList',
	className: 'list-group list-group-lg no-radius no-border no-bg m-b-none',
	template: require('./templates/client-list'),
	//events: {
	//	'keyup input#client-search': 'clientSearch'
	//},

	initialize: function (options) {
		this.collection = options.collection || new Collection();
		this.collection.url = '/index.php/api/v1/clients';



		//this._initMainContainers();

		//this.listenTo(this.getCollection(), 'reset', this.renderClientList);

		this.collection.fetch({reset: true});

	},
	initGrid: function() {
		this.grid = new Backgrid.Grid({
			columns: [{
				name: "name",
				label: "Name"
			}],
			collection: this.collection
		});
	},
	render: function() {
		this.$el.append(this.template(this.getTemplateData()));

		this.initGrid();
		this.$el.append(this.grid.render().el);
	}


});

