/**
 * Created by soundview on 8/6/15.
 */
var View = require('views/base/view');
require('lib/view-helper');

module.exports = QuickView = View.extend({
	autoRender: false,
	tagName: 'tr',
	className: 'quick_view',
	template: require('./quick-view-template'),
	events: {
		'click .quick-view-close': 'close'
	},
	initialize: function(options) {
		QuickView.__super__.initialize.apply(this,arguments);
		this.model = options.model;


		//this.template = _.template('<td colspan="11">\
		//<div class="wrapper">\
		//<div class="close">\
		//<i class="mdi-navigation-close"></i>\
		//</div>\
		//{{ name }}\
		//</div>\
		//</td>');

	},
	close: function() {
		this.trigger('close', this);

		this.dispose();
	},
	getTemplateData: function () {
		var data = this.model.serialize();
		data.__proto__.attr = _.pick(this.model.toJSON(), 'elevation', 'brand', 'product_line', 'width', 'height', 'style', 'operation', 'material', 'glass', 'obscure_glass', 'grid_pattern', 'exterior_finish', 'interior_finish', 'hardware_finish', 'interior_trim', 'exterior_trim');

		return data;
	},
	render: function() {
		QuickView.__super__.render.call(this,arguments);

		//this.$el.append(this.template());
	}

});