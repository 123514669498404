// The application object.
var SessionController = require('controllers/session-controller');
var routes = require('routes');
var Session = require('models/session');
var utils = require('lib/utils');
var mediator = require('mediator');
var messenger = require('lib/messenger');
var readyStateTracker = require('ready-state-tracker');
//var accessToken = utils.getCookie('access_token');
//$.ajaxSetup({
//	beforeSend: function (jqXHR, settings) {
//		if (accessToken) {
//			jqXHR.setRequestHeader('Authorization: Bearer ' + accessToken);
//		}
//	}
//});

var stack = [];


var Application = Chaplin.Application.extend({
	//session: new Session(),
	//  start: function() {
	//     // You can fetch some data here and start app
	//     // (by calling `super`) after that.
	//	  var _this = this;
	//	  console.log(this.session);
	//	  console.log(this.session.authenticated());
	//
	//	  //if(!this.session.authenticated()) {
	//		//  Chaplin.utils.redirectTo('session#login');
	//	  //}
	//
	//	  this.constructor.__super__.start.call(this);
	//  },
	////controllerSuffix: '-controller',
	initialize: function(options) {
		this.options = options || {};
		Application.__super__.initialize.apply(this, arguments);
		//Chaplin.Application.prototype.initialize.apply(this, arguments);
		//this.initDispatcher();
		//this.initLayout();
		this.initMediator();



		mediator.once('dispatcher:dispatch', function () {
			readyStateTracker.markReady('app');
			console.log('app');
		});

		//this.initControllers();
		//this.initRouter(options.routes, options);
		messenger.setup({
			container: '#flash-messages .flash-messages-holder',
			template: require('views/templates/message')
		});


		return typeof Object.freeze === "function" ? Object.freeze(this) : void 0;
	},
	////initControllers: function () {
	////	return new SessionController();
	////	//new NavigationController();
	////	//return new SidebarController();
	////},
	initMediator: function () {
		mediator.user = null;
		return mediator.seal();
	}
});

module.exports = Application;
