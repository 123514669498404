var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<div class=\"wrapper\" flex>\n	<form class=\"form\">\n\n				<div class=\"row\">\n					<div data-fields=\"name,email\"></div>\n				</div>\n				<!--<hr class=\"b-4x\"/>-->\n				<div class=\"row\">\n					<div data-fields=\"address\"></div>\n				</div>\n				<div class=\"row\">\n					<div data-fields=\"phone,fax\"></div>\n				</div>\n				<div class=\"row\">\n					<div data-fields=\"mobile,web\"></div>\n				</div>\n				<!--<hr class=\"b-4x\"/>-->\n\n\n\n	</form>\n</div>\n";
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}