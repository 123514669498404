var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n							<br/>"
    + escapeExpression((helper = helpers.nl2br || (depth0 && depth0.nl2br),options={hash:{},data:data},helper ? helper.call(depth0, ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.address), options) : helperMissing.call(depth0, "nl2br", ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.address), options)))
    + "\n						";
  return buffer;
  }

function program3(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n							<br/><abbr title=\"Phone\">P:</abbr> "
    + escapeExpression((helper = helpers.fPhone || (depth0 && depth0.fPhone),options={hash:{},data:data},helper ? helper.call(depth0, ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.phone), options) : helperMissing.call(depth0, "fPhone", ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.phone), options)))
    + "\n						";
  return buffer;
  }

function program5(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n							<br/><abbr title=\"Email\">E:</abbr> <a href=\"mailto:"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.email)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\">"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.email)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</a>\n						";
  return buffer;
  }

function program7(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n					";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.active), {hash:{},inverse:self.program(10, program10, data),fn:self.program(8, program8, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n				";
  return buffer;
  }
function program8(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n						<li><a class=\"delete_tax_rate\" data-tax-rate=\""
    + escapeExpression(((stack1 = (depth0 && depth0.tax_rate_id)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\">Remove "
    + escapeExpression(((stack1 = (depth0 && depth0.name)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</a>\n						</li>\n					";
  return buffer;
  }

function program10(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n						<li><a class=\"add_tax_rate\" data-tax-rate=\""
    + escapeExpression(((stack1 = (depth0 && depth0.tax_rate_id)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\">Add "
    + escapeExpression(((stack1 = (depth0 && depth0.name)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</a></li>\n					";
  return buffer;
  }

function program12(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n\n				<div class=\"clearfix\">\n			<span class=\"pull-left\">\n				<strong>"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.tax_rate)),stack1 == null || stack1 === false ? stack1 : stack1.name)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</strong>\n			</span>\n			<span class=\"pull-right\">\n				"
    + escapeExpression(((stack1 = (depth0 && depth0.tax_total)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\n			</span>\n				</div>\n\n		";
  return buffer;
  }

  buffer += "<div class=\"row-row\" id=\"clientBody\" role=\"tabpanel\">\n	<div class=\"cell card card-underline m-b-none\">\n		<div class=\"cell-inner\">\n			<div class=\"card-head\">\n				<header>CLIENT</header>\n			</div>\n			<div class=\"card-body\">\n				<fieldset>\n					<address>\n						<strong>"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.name)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</strong>\n						";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.address), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n						";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.phone), {hash:{},inverse:self.noop,fn:self.program(3, program3, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n						";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.client)),stack1 == null || stack1 === false ? stack1 : stack1.email), {hash:{},inverse:self.noop,fn:self.program(5, program5, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n					</address>\n				</fieldset>\n				<div class=\"form-group\">\n					<input class=\"form-control\" id=\"created_at\" name=\"created_at\" value=\"";
  if (helper = helpers.formatted_created_at) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.formatted_created_at); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" />\n					<label>Created</label>\n				</div>\n				<div class=\"form-group\">\n					<input class=\"form-control\" id=\"expires_at\" name=\"expires_at\" value=\"";
  if (helper = helpers.formatted_expires_at) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.formatted_expires_at); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" />\n					<label>Expires</label>\n				</div>\n				<div class=\"form-group\">\n					<select class=\"form-control\" id=\"quote_status\" name=\"quote_status_id\"></select>\n					<label>Status</label>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>\n<div class=\"col-separator-h\"></div>\n<div class=\"card m-b-none\">\n	<div class=\"clearfix wrapper-sm\">\n		<div class=\"btn-group pull-right\">\n			<a href=\"#\" class=\"btn md-btn md-flat grey-200 dropdown-toggle\" data-toggle=\"dropdown\">EDIT FEES</a>\n			<ul class=\"dropdown-menu dropdown-menu-scale pull-up pull-right text-left\">\n				";
  stack1 = helpers.each.call(depth0, (depth0 && depth0.taxRates), {hash:{},inverse:self.noop,fn:self.program(7, program7, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n				<!--<li><a href=\"#\" data-tax-rate=\"2\">Add CC Fee</a></li>-->\n				<!--<li><a href=\"#\" data-tax-rate=\"3\">Add FINANCE Fee</a></li>-->\n			</ul>\n		</div>\n	</div>\n	<div class=\"card-body ptn\">\n\n		<div class=\"clearfix\">\n			<span class=\"pull-left\">\n				<strong>Product</strong>\n			</span>\n			<span class=\"pull-right\">\n				"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.amount)),stack1 == null || stack1 === false ? stack1 : stack1.subtotal_product)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\n			</span>\n		</div>\n		<div class=\"clearfix\">\n			<span class=\"pull-left\">\n				<strong>Install</strong>\n			</span>\n			<span class=\"pull-right\">\n				"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.amount)),stack1 == null || stack1 === false ? stack1 : stack1.subtotal_install)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\n			</span>\n		</div>\n		<div class=\"clearfix\">\n			<span class=\"pull-left\">\n				<strong>Subtotal</strong>\n			</span>\n			<span class=\"pull-right\">\n				"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.amount)),stack1 == null || stack1 === false ? stack1 : stack1.item_subtotal)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\n			</span>\n		</div>\n		";
  stack1 = helpers.each.call(depth0, (depth0 && depth0.tax_rates), {hash:{},inverse:self.noop,fn:self.program(12, program12, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n		<hr />\n\n		<div class=\"clearfix\">\n			<span class=\"pull-left\">\n				<strong>Tax Total</strong>\n			</span>\n			<span class=\"pull-right\">\n				"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.amount)),stack1 == null || stack1 === false ? stack1 : stack1.tax_total)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\n			</span>\n		</div>\n		<div class=\"clearfix\">\n			<span class=\"pull-left\">\n				<strong>Total</strong>\n			</span>\n			<span class=\"pull-right\">\n				"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.amount)),stack1 == null || stack1 === false ? stack1 : stack1.total)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\n			</span>\n		</div>\n		</div>\n</div>\n\n";
  return buffer;
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}