/**
 * Created by soundview on 3/6/15.
 */
var View = require('views/base/view');

module.exports = View.extend({
	container: '#content',
	id: 'page',
	regions: {
		main: '#app-content-body'
	},
	template: require('./templates/client')
});
