/**
 * Created by soundview on 3/6/15.
 */
/**
 * Created by soundview on 3/5/15.
 */
var Controller = require('controllers/base/controller');
var HeaderView = require('views/layout/header-view');
var QuoteView = require('views/quote-view');
var QuoteCollection = require('models/quote-collection');
var QuoteItemCollection = require('models/quote-item-collection');
var HomePageView = require('views/home/home-page-view');
var TestPageView = require('views/home/test-page-view');
var AsideLeftView = require('views/layout/sidebar-view');
var QuoteEditView = require('views/quote/quote-edit');
var QuoteModel = require('models/quote');
var QuoteItemModel = require('models/quote-item');
var QuoteItemEditView = require('views/quote-item/quote-item-edit');
var ItemEditView = require('views/quote-item/item-edit-view');
var QuoteIndexView = require('views/quote/quote-index');
var QuoteCreateView = require('views/quote/quote-create');

var LayoutController = require('controllers/layout-controller');


module.exports = LayoutController.extend({
	beforeAction: function () {
		LayoutController.prototype.beforeAction.call(this, arguments);
		//this.reuse('quote', QuoteView, { region: 'content'});
		//this.reuse('header', HeaderView, {region: 'header'});
		//this.reuse('asideLeft', AsideLeftView, {region: 'asideLeft'});

	},

	dashboard: function() {
		window.location.href = '/login';
	},

	index: function () {

		this.view = new QuoteIndexView({region: 'content'});
	},
	create: function (params, route, query) {

		this.clientName = query.query.clientName ? query.query.clientName.replace(/\+/g, " ") : null;
		if(query){

		}
		this.view = new QuoteCreateView({region: 'content', clientName: this.clientName});
	},

	show: function(params) {
		//this.reuse('quote', QuoteView, {className: 'app-content-body app-content-full fade-in-up ng-enter full-cover'});
		this.model = new QuoteModel({id: params.quoteId});
		this.items = new QuoteItemCollection({quoteId: params.quoteId});

		return this.model.fetch().then((function (_this) {
			return function () {
				_this.items.fetch({data: $.param({quoteId: params.quoteId})});
				_this.view = new QuoteEditView({model: _this.model, items: _this.items, region: 'content'});

			};
		})(this));
	},
	edit: function(params) {
		console.log(params);
		//this.reuse('quote', QuoteView, {className: 'app-content-body app-content-full fade-in-up ng-enter full-cover'});
		this.model = new QuoteItemModel({id: params.itemId });

		return this.model.fetch().then((function(_this){
			return function () {
				_this.view = new ItemEditView({model: _this.model});
			}
		})(this));
		//this.view = new ItemEditView({model: this.model});
	},
	createItem: function (params) {
		this.model = params.model;
		//this.model = new QuoteItemModel({'quote_id': params.quoteId});
		return this.view = new ItemEditView({model:this.model});

		//this.view = new ItemEditView({model: this.model});
	},

	test: function () {
		this.view = new TestPageView({region: 'main'});
	}


});