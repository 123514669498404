var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, functionType="function", escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  var stack1;
  return escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.model)),stack1 == null || stack1 === false ? stack1 : stack1.name)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1));
  }

function program3(depth0,data) {
  
  
  return "New Item";
  }

function program5(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "\n											<li class=\"orderline bid-"
    + escapeExpression(((stack1 = (data == null || data === false ? data : data.key)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\" data-key=\""
    + escapeExpression(((stack1 = (data == null || data === false ? data : data.key)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\">\n												<span class=\"product-name\">";
  if (helper = helpers.title) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.title); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n												<ul class=\"info-list\">\n													<li class=\"info\"></li>\n												</ul>\n											</li>\n										";
  return buffer;
  }

  buffer += "<header flex class=\"header bg-white md-whiteframe-z1\">\n	<span class=\"h3 m-n font-thin\">";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.model)),stack1 == null || stack1 === false ? stack1 : stack1.name), {hash:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "</span>\n\n	<div class=\"pull-right btn-group\">\n		<a href=\"#\" class=\"md-btn md-btn-circle md-raised item-dismiss-btn mt10\"><i\n				class=\"mdi-navigation-close\"></i></a>\n	</div>\n</header>\n<!--<div class=\"wrapper\">-->\n<!--<div id=\"steps-wrap\" class=\"sf-sea\">-->\n<!--<div class=\"sf-nav-wrap clearfix sf-nav-top\">-->\n<!--<ul class=\"sf-nav clearfix\">-->\n<!--<li class=\"sf-nav-step sf-nav-step-0 sf-li-number sf-active sf-nav-link\"><span-->\n<!--class=\"sf-nav-subtext\">Basic information</span>-->\n\n<!--<div class=\"sf-nav-number\"><span class=\"sf-nav-number-inner\">1</span></div>-->\n<!--<div></div>-->\n<!--</li>-->\n<!--<li class=\"sf-nav-step sf-nav-step-1 sf-li-number sf-nav-link\"><span class=\"sf-nav-subtext\">Condition</span>-->\n\n<!--<div class=\"sf-nav-number\"><span class=\"sf-nav-number-inner\">2</span></div>-->\n<!--<div></div>-->\n<!--</li>-->\n<!--<li class=\"sf-nav-step sf-nav-step-2 sf-li-number sf-nav-link\"><span class=\"sf-nav-subtext\">Final step</span>-->\n\n<!--<div class=\"sf-nav-number\"><span class=\"sf-nav-number-inner\">3</span></div>-->\n<!--<div></div>-->\n<!--</li>-->\n<!--</ul>-->\n<!--</div>-->\n<!--</div>-->\n<!--</div>-->\n<div flex class=\"box-row bid\">\n	<div class=\"box-cell\">\n		<div class=\"box-inner\">\n			<div class=\"hbox\">\n				<div class=\"col w-400 bg-light\" style=\"width:440px\">\n					<div class=\"scrollable h-full pos-rlt\">\n						<div class=\"order-container\">\n							<div class=\"order-scroller touch-scrollable\">\n								<div class=\"order\">\n									<ul class=\"orderlines\">\n										";
  stack1 = helpers.each.call(depth0, (depth0 && depth0.schema), {hash:{},inverse:self.noop,fn:self.program(5, program5, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n\n									</ul>\n									<div class=\"summary clearfix\">\n										<div class=\"line\">\n											<div class=\"entry total\">\n												<span class=\"label\">Total: </span> <span class=\"value\">$ 36.00</span>\n\n												<div class=\"subentry\">Taxes: <span class=\"value\">$ 0.00</span></div>\n											</div>\n										</div>\n									</div>\n\n								</div>\n							</div>\n						</div>\n					</div>\n\n				</div>\n				<div class=\"col\">\n					<div class=\"vbox\">\n						<div id=\"fs-form-container\" class=\"h-full\" style=\"background-color:#3B3F45\"></div>\n					</div>\n				</div>\n			</div>\n\n\n		</div>\n	</div>\n</div>\n\n<!--<footer flex class=\"footer b-t\">-->\n	<!--<div class=\"pull-right btn-toolbar\">-->\n		<!--<a href=\"#\" class=\"btn btn-x danger item-dismiss-btn mr10\">CANCEL</a>-->\n		<!--<a href=\"#\" data-bypass class=\"btn btn-x primary item-save-btn\">SAVE</a>-->\n	<!--</div>-->\n<!--</footer>-->\n\n";
  return buffer;
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}