/**
 * Created by soundview on 8/12/15.
 */
/**
 * Created by soundview on 3/6/15.
 */

var Controller = require('controllers/base/controller');


var DashboardView = require('modules/dashboard/view');

var WindowQuotes = require('window/collection');



var LayoutController = require('controllers/layout-controller');


module.exports = LayoutController.extend({
	beforeAction: function () {
		//this.constructor.__super__.beforeAction.apply(this, arguments);
		LayoutController.prototype.beforeAction.call(this, arguments);
		//this.reuse('header', HeaderView, {region: 'header'});
		//this.reuse('asideLeft', AsideLeftView, {region: 'asideLeft'});
	},

	index: function () {


		this.view = new DashboardView({region: 'content'});
	},



});