/**
 * Created by soundview on 3/5/15.
 */
var Base = require('models/base/model');
module.exports = Base.extend({
	url: '',
	defaults: {
		id: null,
		item_name: '',
		item_description: '',
		item_price: ''
	}
});