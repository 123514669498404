var __templateData = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  


  return "<ul>\n  <li><a class=\"header-link\" href=\"https://github.com/brunch/brunch/tree/stable/docs\">Brunch docs</a></li>\n  <li><a class=\"header-link\" href=\"https://github.com/brunch/brunch/issues\">Brunch issues</a></li>\n  <li><a class=\"header-link\" href=\"http://docs.chaplinjs.org\">Chaplin docs</a></li>\n  <li><a class=\"header-link\" href=\"https://github.com/chaplinjs/chaplin/issues\">Chaplin issues</a></li>\n  <li><a class=\"header-link\" href=\"https://github.com/paulmillr/ostio\">Ost.io example app</a></li>\n  <li><a class=\"header-link last-link\" href=\"http://twitter.com/brunch\">Twitter</a></li>\n</ul>\n";
  });
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}